import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentReference } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DailyPayment } from '../models/daily-payment';
import { Debt } from '../models/debt';
import { startOfDay, isAfter, addHours, endOfDay } from 'date-fns';
import { take } from 'rxjs/operators';
import { Recharge } from '../models/recharge';

@Injectable({
  providedIn: 'root'
})
export class DailyPaymentService {

  private dailyPaymentsCollection: AngularFirestoreCollection<DailyPayment>;

  constructor( private afs: AngularFirestore) {
    this.dailyPaymentsCollection = this.afs.collection<DailyPayment>('dailyPayments');
  }

  getDailyPayments(): Observable<DailyPayment[]> {
    return this.dailyPaymentsCollection.snapshotChanges().pipe(
      map(versements =>
        versements.map(a => {
          const data = a.payload.doc.data() as DailyPayment;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      )
    );
  }
  getAlldailyPayments(): Promise<DailyPayment[]> {
    return this.afs.collection('dailyPayments', ref => ref.orderBy('date', 'desc'))
    .get()
    .toPromise()
    .then(snapshot => {
      const dailyPayments: DailyPayment[] = [];
      snapshot.forEach(doc => {
        const data = doc.data() as DailyPayment;
        const id = doc.id;
        dailyPayments.push({ id, ...data });
      });
      return dailyPayments;
    });
  }

  getDailyRevenue(date: Date): Promise<number> {
    const startOfDay = new Date(date);
     startOfDay.setHours(0, 0, 0, 0);
    const endOfDay = new Date(date);
     endOfDay.setHours(23, 59, 59, 999);

    return this.afs.collection('dailyPayments', ref =>
      ref.where('date', '>=', startOfDay)
         .where('date', '<=', endOfDay)
    )
    .get()
    .toPromise()
    .then(snapshot => {
      let dailyRevenue = 0;
      snapshot.forEach(doc => {
        const data = doc.data() as DailyPayment;
        dailyRevenue += parseFloat(data.amount.toString());
      });
      return dailyRevenue;
    });
  }
  getDailyRevenueCommission(date: Date): Promise<number> {
    const startOfDay = new Date(date);
     startOfDay.setHours(5, 59, 59, 999);
    const endOfDay = new Date(date);
    // endOfDay.setHours(23, 59, 59, 999);

    return this.afs.collection('dailyPayments', ref =>
      ref.where('date', '>=', startOfDay)
         .where('date', '<=', endOfDay)
    )
    .get()
    .toPromise()
    .then(snapshot => {
      let dailyRevenueCommission = 0;
      snapshot.forEach(doc => {
        const data = doc.data() as DailyPayment;
        dailyRevenueCommission += parseFloat(data.commission.toString());
      });
      return dailyRevenueCommission;
    });
  }
  getDailyRevenueDebt(date: Date): Promise<number> {
    const startOfDay = new Date(date);
     startOfDay.setHours(5, 59, 59, 999);
    const endOfDay = new Date(date);
    // endOfDay.setHours(23, 59, 59, 999);

    return this.afs.collection('dailyPayments', ref =>
      ref.where('date', '>=', startOfDay)
         .where('date', '<=', endOfDay)
    )
    .get()
    .toPromise()
    .then(snapshot => {
      let dailyRevenueDebt = 0;
      snapshot.forEach(doc => {
        const data = doc.data() as DailyPayment;
        dailyRevenueDebt += parseFloat(data.debtAmount.toString());
      });
      return dailyRevenueDebt;
    });
  }

  getMonthlyRevenue(): Promise<number> {
    const today = new Date();
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    startOfMonth.setHours(0, 0, 0, 0);

    const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    endOfMonth.setHours(23, 59, 59, 999);


    return this.afs.collection('dailyPayments', ref =>
      ref.where('date', '>=', startOfMonth)
         .where('date', '<=', endOfMonth)
    )
    .get()
    .toPromise()
    .then(snapshot => {
      let monthlyRevenue = 0;
      snapshot.forEach(doc => {
        const data = doc.data() as DailyPayment;
        monthlyRevenue += parseFloat(data.amount.toString());
      });
      return monthlyRevenue;
    });
  }
  getMonthlyRevenueCommission(): Promise<number> {
    const today = new Date();
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    startOfMonth.setHours(0, 0, 0, 0);

    const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    endOfMonth.setHours(23, 59, 59, 999);


    return this.afs.collection('dailyPayments', ref =>
      ref.where('date', '>=', startOfMonth)
         .where('date', '<=', endOfMonth)
    )
    .get()
    .toPromise()
    .then(snapshot => {
      let monthlyRevenueCommission = 0;
      snapshot.forEach(doc => {
        const data = doc.data() as DailyPayment;
        monthlyRevenueCommission += parseFloat(data.commission.toString());
      });
      return monthlyRevenueCommission;
    });
  }
  getMonthlyRevenueDebt(): Promise<number> {
    const today = new Date();
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    startOfMonth.setHours(0, 0, 0, 0);

    const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    endOfMonth.setHours(23, 59, 59, 999);


    return this.afs.collection('dailyPayments', ref =>
      ref.where('date', '>=', startOfMonth)
         .where('date', '<=', endOfMonth)
    )
    .get()
    .toPromise()
    .then(snapshot => {
      let monthlyRevenueDebt = 0;
      snapshot.forEach(doc => {
        const data = doc.data() as DailyPayment;
        monthlyRevenueDebt += parseFloat(data.debtAmount.toString());
      });
      return monthlyRevenueDebt;
    });
  }

  getWeeklyRevenue(): Promise<number> {
    const today = new Date();
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - today.getDay());
    startOfWeek.setHours(0, 0, 0, 0);

    const endOfWeek = new Date(today);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    endOfWeek.setHours(23, 59, 59, 999);

    return this.afs.collection('dailyPayments', ref =>
      ref.where('date', '>=', startOfWeek)
         .where('date', '<=', endOfWeek)
    )
    .get()
    .toPromise()
    .then(snapshot => {
      let weeklyRevenue = 0;
      snapshot.forEach(doc => {
        const data = doc.data() as DailyPayment;
        weeklyRevenue += parseFloat(data.amount.toString());
      });
      return weeklyRevenue;
    });
  }
  getWeeklyRevenueCommission(): Promise<number> {
    const today = new Date();
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - today.getDay());
    startOfWeek.setHours(0, 0, 0, 0);

    const endOfWeek = new Date(today);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    endOfWeek.setHours(23, 59, 59, 999);

    return this.afs.collection('dailyPayments', ref =>
      ref.where('date', '>=', startOfWeek)
         .where('date', '<=', endOfWeek)
    )
    .get()
    .toPromise()
    .then(snapshot => {
      let weeklyRevenueCommission = 0;
      snapshot.forEach(doc => {
        const data = doc.data() as DailyPayment;
        weeklyRevenueCommission += parseFloat(data.commission.toString());
      });
      return weeklyRevenueCommission;
    });
  }
  getWeeklyRevenueDebt(): Promise<number> {
    const today = new Date();
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - today.getDay());
    startOfWeek.setHours(0, 0, 0, 0);

    const endOfWeek = new Date(today);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    endOfWeek.setHours(23, 59, 59, 999);

    return this.afs.collection('dailyPayments', ref =>
      ref.where('date', '>=', startOfWeek)
         .where('date', '<=', endOfWeek)
    )
    .get()
    .toPromise()
    .then(snapshot => {
      let weeklyRevenueDebt = 0;
      snapshot.forEach(doc => {
        const data = doc.data() as DailyPayment;
        weeklyRevenueDebt += parseFloat(data.debtAmount.toString());
      });
      return weeklyRevenueDebt;
    });
  }

  getAnnualRevenue(): Promise<number> {
    const today = new Date();
    const startOfYear = new Date(today.getFullYear(), 0, 1);
    startOfYear.setHours(0, 0, 0, 0);

    const endOfYear = new Date(today.getFullYear(), 11, 31);
    endOfYear.setHours(23, 59, 59, 999);

    return this.afs.collection('dailyPayments', ref =>
      ref.where('date', '>=', startOfYear)
         .where('date', '<=', endOfYear)
    )
    .get()
    .toPromise()
    .then(snapshot => {
      let annualRevenue = 0;
      snapshot.forEach(doc => {
        const data = doc.data() as DailyPayment;
        annualRevenue += parseFloat(data.amount.toString());
      });
      return annualRevenue;
    });
  }





  getByIdDailyPayment(dailyPaymentId: string): Observable<DailyPayment | undefined> {
    return this.dailyPaymentsCollection.doc<DailyPayment>(dailyPaymentId).valueChanges();
  }

  addDailyPayment(dailyPayment: DailyPayment): Promise<DocumentReference> {
    return this.dailyPaymentsCollection.add(dailyPayment);
  }

  updateDailyPayment(id: string, dailyPayment: DailyPayment): Promise<void> {
    return this.dailyPaymentsCollection.doc(id).update(dailyPayment);
  }
  // Mettre à jour la dette d'un versement de recettes par son ID
  updateDebtAmount(dailyPaymentId: string, newDebtAmount: number): Promise<void> {
    return this.afs.collection('dailyPayments').doc(dailyPaymentId).update({ debtAmount: newDebtAmount });
  }

   // Supprimez un versement
   deleteDailyPayment(dailyPaymentId: string): Promise<void> {
    return this.dailyPaymentsCollection.doc(dailyPaymentId).delete();
  }

   // Obtenir le total des dettes pour un chauffeur donné
   getTotalDebtForDriver(driverId: string): Observable<number> {
    return this.afs.collection<DailyPayment>('dailyPayments', ref =>
        ref.where('driverId', '==', driverId) // Filtre par ID du chauffeur
      )
      .valueChanges()
      .pipe(
        map(dailyPayments => {
          let totalDebt = 0;
          dailyPayments.forEach(dailyPayment => {
            totalDebt += dailyPayment.debtAmount;
          });
          return totalDebt;
        })
      );
  }




  getNumberOfPayments(): Promise<number> {
    // Code pour récupérer le nombre de versements, par exemple, à partir de la base de données.
    // Vous pouvez utiliser Firebase Firestore ou tout autre mécanisme de stockage de données.
    // Retournez la promesse du nombre de versements.
    // Par exemple :
    return this.afs.collection('dailyPayments').valueChanges().pipe(take(1)).toPromise().then(data => data.length);
  }

 // methode de retrait

 async retraitOperator(id: string, newBalance: number, retraitAmount: number): Promise<string> {
  if (id && typeof newBalance === 'number' && typeof retraitAmount === 'number') {
    const retrait: Recharge = {
      amount: retraitAmount,
      date: new Date()
    };

    const operatorRef = this.dailyPaymentsCollection.doc(id).ref;

    try {
      const operatorSnapshot = await operatorRef.get();
      const operatorData = operatorSnapshot.data() as DailyPayment;

      // Assurez-vous que operatorData.retraits est un tableau (s'il n'existe pas, initialisez-le)
      const existingRetraits = Array.isArray(operatorData.retraits) ? operatorData.retraits : [];

      // Ajoutez la nouvelle retrait
      const updatedRetraits = [...existingRetraits, retrait];

      // Mettez à jour les données de l'opérateur
      await operatorRef.update({
        debtAmount: newBalance,
        retraits: updatedRetraits
      });

      return 'Retrait successful';
    } catch (error) {
      console.error('Error retraiting operator:', error);
      throw error;
    }
  } else {
    console.error('Invalid data for retrait');
    throw new Error('Invalid data for retrait');
  }
}




}
