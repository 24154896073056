import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { Transaction } from 'src/app/models/transaction';
import { TransactionService } from 'src/app/services/transaction.service';
import { OperatorModalComponent } from '../../operators/operator-modal/operator-modal.component';
import { Operator } from 'src/app/models/operator';
import { OperatorService } from 'src/app/services/operator.service';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { formatDate } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Caisse } from 'src/app/models/caisse';
import { CaisseService } from 'src/app/services/caisse.service';
import { ExpenseService } from 'src/app/services/expense.service';


@Component({
  selector: 'app-operation-list',
  templateUrl: './operation-list.component.html',
  styleUrls: ['./operation-list.component.css']
})
export class OperationListComponent implements OnInit, OnDestroy{


  transactions: Transaction[] = [] ;
  dailyRevenueWithCommissionByOperator: { operator: string; revenue: number }[] = [];

  caisses: Caisse[] = [];

  weeklyRevenues: { date: string, revenue: number }[];
  dailyRevenues: { date: string, revenue: number }[];
  monthlyRevenues: { date: string, revenue: number }[];
  annualRevenues: { date: string, revenue: number }[];

  dailyMoovTransactions: Transaction[] = [];
  totalDailyMoovRevenue: number = 0;
  numberOfPayments: number = 0;

  transaction : Transaction;
  operators: Operator[] = [];
  operatorsSub: Subscription;
  alert:any;
  totalDeposits: any;
  total: number = 0;
  totalTransaction: number =0;
  searchTransactions:Transaction[] = [];

  totalDepositOperator: any;

  // pages = 1;
  // pageSize = 200;
  // collectionSize = this.transactions.length;

  totalLength: number;
  page : number = 1;

  tokenSub: Subscription;
  isAdminSub: Subscription;
  isAnonymous = false;
  isAdmin : boolean;

  today: Date;
  totalWithdrawals: number;
  dailyRevenue: number;
  cashRevenue: number;
  dailyExpenseRevenue:number;
  weeklyRevenue: number;
  monthlyRevenue: number;
  annualRevenue: number;
  totalDepotByOperator: number =0;
  cashSum: number;

  user: User;
  isAuth = false;
  operatorId:string;

  totalDepositsForSelectedOperator: number;

  constructor(public transactionService: TransactionService,
    private operatorService: OperatorService,
    private caisseService :CaisseService,
    public authService: AuthService,
    public userService: UserService,
    private expenseService: ExpenseService,
    private afa: AngularFireAuth,
    private toastr: ToastrService,
    private modalService: NgbModal
    ) {
    //  this.updateTransactionListing();
  }



  ngOnInit(): void {
    this.getAllOperators();
    this.getAllTransaction();
    this.getAllFondCaisse();
    this.tokenSub = this.authService.getToken().subscribe(
      token => this.isAnonymous = !token
    );
    this.isAdminSub = this.authService.isAdmin.subscribe(
      (isAdmin: boolean) => this.isAdmin = isAdmin
    );
    this.authService.isCurrentUserAdmin();

    this.afa.authState.subscribe(
      (user) =>{
        if (user) {
          this.isAuth = true;
          console.log(this.isAuth);
          this.userService.getUserById(user.uid).subscribe(
            (userData) => {
              this.user = userData;
            })
        }else{
          this.isAuth = false;
         console.log(this.isAuth);

        }
      });


    this.transactionService.getAllTransactions();
    this.operatorService.getAllOperator();
    this.calculateCashBalance();
    this.getIsAdmin();
    this.today = new Date();
    this.calculateCashRevenue();
    this.calculateDailExpenseRevenue();
    this.calculateCashFondCaisse();
    this.calculateCaisse();
    this.calculateSumOfCaisseBalances();
    this.dailyRevenueWithCommissionByOperator = this.operators.map(operator => ({
      operator: operator.name,
      revenue: this.calculateDailyRevenueWithCommissionForOperator(operator.id)
    }));
    this.getNumberVersement();






  }
  calculateDailExpenseRevenue():any {
    this.expenseService.getDailyExpenseRevenue(this.today)
      .then((dailyExpenseRevenue) =>{
        this.dailyExpenseRevenue = dailyExpenseRevenue;
        this.cashRevenue -= this.dailyExpenseRevenue;



      });
  }


  calculateCashRevenue():any {
    this.transactionService.getCashRevenue(this.today)
      .then((cashRevenue) =>{
        this.cashRevenue = cashRevenue;

      });
  }

  calculateCaisse(){
    return this.caisses.reduce((total, caisse) => {
      return total + parseFloat(caisse.balance.toString());
    },0)
  }

  calculateSumOfCaisseBalances(): number {
    const sumOfCaisseBalances = this.caisses.reduce(
      (total, caisse) => total + caisse.balance,
      0
    );
    return sumOfCaisseBalances;
  }

  calculateCashFondCaisse(){
    const totalCashBalance = this.calculateCashRevenue();
    const totalFondCaisse = this.calculateSumOfCaisseBalances();
    this.cashSum = totalCashBalance + totalFondCaisse ;

  }


  // prepareChartData() {
  //   this.lineChartLabels = this.monthlyRevenues.map(item => `Jour ${item.date}`);
  //   this.lineChartData[0].data = this.monthlyRevenues.map(item => item.revenue);
  // }

  getAllTransaction(){
    this.transactionService.getAllTransactions().then(
      (transactions) => {
         this.searchTransactions = this.transactions = transactions;
         this.totalLength = transactions.length;

        this.total = this.getTotalBalanceOperator();
        // this.updateTransactionListing();
       });
  }

  getAllFondCaisse(){
    this.caisseService.getAllCaisse().subscribe(
      (caisses)=>{
        this.caisses = caisses;

      }
    )
  }
  printer(){
    window.print
  }




  getIsAdmin(){
    this.tokenSub = this.authService.getToken().subscribe(
      token => this.isAnonymous = !token
    );
    this.isAdminSub = this.authService.isAdmin.subscribe(
      (isAdmin: boolean) => this.isAdmin = isAdmin
    );
    this.authService.isCurrentUserAdmin();
  }

  onClickDeleteTransaction(transactionId){
    const  confirmModal = this.modalService.open(OperatorModalComponent).result;
    confirmModal.then(()=>{
      this.transactionService.deleteTransaction(transactionId)
    .then(
      () =>{

        console.log('Supprimé');
        this.toastr.success('La transaction à été supprimée avec succès.');

      }
    )
    }).catch(
      (error)=>{

        console.log(error);

      }
    )
  }


  getAllOperators(){
    this.operatorsSub = this.operatorService.getAllOperator().subscribe(
      (operators) => {
        this.operators = operators
      }
    );
  }
  getoperatorPhoto(operatorId){
    if (this.operators) {
      return this.operators.find(operator => operator.id === operatorId).photo;
    }

  }
  getoperatorName(operatorId){
    if (this.operators) {
      return this.operators.find(operator => operator.id === operatorId).name;
    }

  }


  getTotalBalanceOperator(){
    return this.operators.reduce((total, operator) => {
      return total + parseFloat(operator.balance.toString());
    },0)
  }

  search(query: string){
    this.searchTransactions = (query) ? this.transactions.filter(transaction => transaction.numberPhone.toLowerCase().includes(query.toLowerCase())
    || transaction.operatorId.toLowerCase().includes(query.toLowerCase())
    || transaction.status.toLowerCase().includes(query.toLowerCase())
    || transaction.type.toLowerCase().includes(query.toLowerCase())
    ): this.transactions;
  }



  // updateTransactionListing() {
  //   this.searchTransactions = this.searchTransactions
  //     .map((customer, i) => ({id: i + 1, ...customer}))
  //     .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  // }

  getTotalDette(): any {
    return this.transactions.reduce((total, transaction) => {
      return (transaction.type === 'retrait' && transaction.status ==='non payer'
      || transaction.type === 'depot' && transaction.status ==='non payer'
      || transaction.type === 'credit' && transaction.status ==='non payer'
      || transaction.type === 'internet' && transaction.status ==='non payer'
      || transaction.type === 'sodeci' && transaction.status ==='non payer'
      || transaction.type === 'carte UBA' && transaction.status ==='non payer'
      || transaction.type === 'inscription' && transaction.status ==='non payer'
      || transaction.type === 'starTimes' && transaction.status ==='non payer'
      || transaction.type === 'canal' && transaction.status ==='non payer'
      || transaction.type === 'cie' && transaction.status ==='non payer') ? total + parseFloat(transaction.amount.toString()) : total;

    }, 0);
  }
  getTotalPayBill(): any {
    return this.transactions.reduce((total, transaction) => {
      return transaction.type === 'sodeci' || transaction.type === 'cie' || transaction.type === 'canal' || transaction.type === 'starTimes' ? total + parseFloat(transaction.amount.toString()) : total;

    }, 0);
  }

  calculateCashBalance(){
    this.transactionService.getAllTransactions().then(
      (transactions: Transaction[]) =>{
        this.totalTransaction = transactions.reduce(
          (acc, transaction)=>{
            if (transaction.type === 'depot'
                || transaction.type === 'credit'
                || transaction.type === 'internet'
                || transaction.type === 'sodeci'
                || transaction.type === 'cie') {
              acc += parseFloat( transaction.amount.toString());
            }else if (transaction.type === 'retrait'){
              acc += parseFloat( transaction.amount.toString());
            }
            return acc;
          },
       0 )
      }
    )
  }

   // la méthode pour calculer total dépôt par operateur
  calculateTotalDepositsForOperator(operatorId: string): number {
    const operatorTransactions = this.transactions.filter(transaction => transaction.operatorId === operatorId &&
      transaction.type === 'depot'
      || transaction.type === 'credit'
      || transaction.type === 'internet'
      || transaction.type === 'sodeci'
      || transaction.type === 'cie'
      || transaction.type === 'carte UBA'
      || transaction.type === 'inscription'
      || transaction.type === 'starTimes'
      || transaction.type === 'canal');
    return operatorTransactions.reduce((total, transaction) => total + parseFloat(transaction.amount.toString()), 0);
  }

   // la méthode pour calculer total dépôt par operateur
  calculateTotalRetraitsForOperator(operatorId: string): number {
    const operatorTransactions = this.transactions.filter(transaction => transaction.operatorId === operatorId && transaction.type === 'retrait');
    return operatorTransactions.reduce((total, transaction) => total + parseFloat(transaction.amount.toString()), 0);
  }

  // la méthode pour calculer le chiffre d'affaires journalier
calculateDailyRevenueForOperator(operatorId: string): number {
  const today = new Date();
  const operatorTransactions = this.transactions.filter(
    transaction =>
      transaction.operatorId === operatorId &&
      this.isSameDate(((transaction.date) as any).toDate(), today)
  );

  return operatorTransactions.reduce(
    (total, transaction) => total + parseFloat(transaction.amount.toString()),
    0
  );
}

isSameDate(date1: Date, date2: Date): boolean {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}


//  la méthode pour calculer le chiffre d'affaires hebdomadaire  par opérateur
calculateWeeklyRevenueForOperator(operatorId: string): number {
  const today = new Date();
  const startOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());

  const operatorTransactions = this.transactions.filter(
    transaction =>
      transaction.operatorId === operatorId &&
      this.isSameWeek(((transaction.date)as any).toDate(), startOfWeek)
  );

  return operatorTransactions.reduce(
    (total, transaction) => total + parseFloat(transaction.amount.toString()),
    0
  );
}

isSameWeek(date1: Date, startOfWeek: Date): boolean {
  const diff = Math.floor((date1.getTime() - startOfWeek.getTime()) / (1000 * 60 * 60 * 24));
  return diff >= 0 && diff < 7;
}



//  la méthode pour calculer le chiffre d'affaires journalier des dépôts par un opérateur
calculateDailyDepositRevenueForOperator(operatorId: string): number {
  const today = new Date();
  const operatorDepositTransactions = this.transactions.filter(
    transaction =>
      transaction.operatorId === operatorId &&
      (transaction.type === 'depot'
      || transaction.type === 'credit'
                || transaction.type === 'internet'
                || transaction.type === 'sodeci'
                || transaction.type === 'cie'
                || transaction.type === 'carte UBA'
                || transaction.type === 'inscription'
                || transaction.type === 'starTimes'
                || transaction.type === 'canal')&& // Assurez-vous que le type est "depot"
      ((transaction.date) as any).toDate().toDateString() === today.toDateString()
  );

  return operatorDepositTransactions.reduce(
    (total, transaction) => total + parseFloat(transaction.amount.toString()),
    0
  );
}

//  la méthode pour calculer le chiffre d'affaires journalier des retraits par un opérateur
calculateDailyRetraitRevenueForOperator(operatorId: string): number {
  const today = new Date();
  const operatorDepositTransactions = this.transactions.filter(
    transaction =>
      transaction.operatorId === operatorId &&
      transaction.type === 'retrait' && // Assurez-vous que le type est "retait"
      ((transaction.date) as any).toDate().toDateString() === today.toDateString()
  );

  return operatorDepositTransactions.reduce(
    (total, transaction) => total + parseFloat(transaction.amount.toString()),
    0
  );
}

//  la méthode pour calculer le chiffre d'affaires mensuel par un opérateur
calculateMonthlyRevenueForOperator(operatorId: string): number {
  const today = new Date();
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

  const operatorTransactions = this.transactions.filter(
    transaction =>
      transaction.operatorId === operatorId &&
      this.isSameMonth(((transaction.date)as any).toDate(), startOfMonth)
  );

  return operatorTransactions.reduce(
    (total, transaction) => total + parseFloat(transaction.amount.toString()),
    0
  );
}

isSameMonth(date1: Date, startOfMonth: Date): boolean {
  return (
    date1.getFullYear() === startOfMonth.getFullYear() &&
    date1.getMonth() === startOfMonth.getMonth()
  );
}


//  la méthode pour calculer le chiffre d'affaires annuel par un opérateur
calculateAnnualRevenueForOperator(operatorId: string): number {
  const today = new Date();
  const startOfYear = new Date(today.getFullYear(), 0, 1);

  const operatorTransactions = this.transactions.filter(
    transaction =>
      transaction.operatorId === operatorId &&
      this.isSameYear(((transaction.date)as any).toDate(), startOfYear)
  );

  return operatorTransactions.reduce(
    (total, transaction) => total + parseFloat(transaction.amount.toString()),
    0
  );
}

isSameYear(date1: Date, startOfYear: Date): boolean {
  return date1.getFullYear() === startOfYear.getFullYear();
}


// la méthode pour calculer le chiffre d'affaires journalier de toutes les transactions
calculateDailyTotalRevenue(): number {
  const today = new Date();
  const totalTransactions = this.transactions.filter(
    transaction =>
      ((transaction.date)as any).toDate().toDateString() === today.toDateString()
  );

  return totalTransactions.reduce(
    (total, transaction) => total + parseFloat(transaction.amount.toString()),
    0
  );
}

// la méthode pour calculer le chiffre d'affaires hebdomadaire de toutes les transactions
calculateWeeklyTotalRevenue(): number {
  const today = new Date();
  const startOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());

  const totalTransactions = this.transactions.filter(
    transaction =>
      this.isSameWeek(((transaction.date)as any).toDate(), startOfWeek)
  );

  return totalTransactions.reduce(
    (total, transaction) => total + parseFloat(transaction.amount.toString()),
    0
  );
}

// la méthode pour calculer le chiffre d'affaires mensuel de toutes les transactions
calculateMonthlyTotalRevenue(): number {
  const today = new Date();
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

  const totalTransactions = this.transactions.filter(
    transaction =>
      this.isSameMonth(((transaction.date)as any).toDate(), startOfMonth)
  );

  return totalTransactions.reduce(
    (total, transaction) => total + parseFloat(transaction.amount.toString()),
    0
  );
}

// la méthode pour calculer le chiffre d'affaires annuel de toutes les transactions
calculateAnnualTotalRevenue(): number {
  const today = new Date();
  const startOfYear = new Date(today.getFullYear(), 0, 1);

  const totalTransactions = this.transactions.filter(
    transaction =>
      this.isSameYear(((transaction.date)as any).toDate(), startOfYear)
  );

  return totalTransactions.reduce(
    (total, transaction) => total + parseFloat(transaction.amount.toString()),
    0
  );
}

//  la méthode pour calculer les chiffres d'affaires annuels pour chaque année
calculateAnnualTotalRevenueByYear(): { year: number; revenue: number }[] {
  const startYear = 2022; // L'année de début des transactions
  const endYear = new Date().getFullYear(); // L'année en cours

  const annualRevenueByYear: { year: number; revenue: number }[] = [];

  for (let year = startYear; year <= endYear; year++) {
    const startOfYear = new Date(year, 0, 1);
    const endOfYear = new Date(year + 1, 0, 1);

    const yearTransactions = this.transactions.filter(
      transaction =>
        ((transaction.date)as any).toDate() >= startOfYear && ((transaction.date)as any).toDate() < endOfYear
    );

    const yearRevenue = yearTransactions.reduce(
      (total, transaction) => total + parseFloat(transaction.amount.toString()),
      0
    );

    annualRevenueByYear.push({ year, revenue: yearRevenue });
  }

  return annualRevenueByYear;
}


// la méthode pour calculer les chiffres d'affaires mensuels
calculateMonthlyTotalRevenueByMonth(): { month: string; revenue: number }[] {
  const months = [
    'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
    'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
  ];

  const today = new Date();
  const currentYear = today.getFullYear();

  const monthlyRevenueByMonth: { month: string; revenue: number }[] = [];

  for (let monthIndex = 0; monthIndex < months.length; monthIndex++) {
    const startOfMonth = new Date(currentYear, monthIndex, 1);
    const endOfMonth = new Date(currentYear, monthIndex + 1, 1);

    const monthTransactions = this.transactions.filter(
      transaction =>
        ((transaction.date)as any).toDate() >= startOfMonth && ((transaction.date)as any).toDate() < endOfMonth
    );

    const monthRevenue = monthTransactions.reduce(
      (total, transaction) => total + parseFloat(transaction.amount.toString()),
      0
    );

    monthlyRevenueByMonth.push({ month: months[monthIndex], revenue: monthRevenue });
  }

  return monthlyRevenueByMonth;
}


// la méthode pour calculer les chiffres d'affaires hebdomadaires pour chaque semaine
calculateWeeklyTotalRevenueByWeek(): { week: string; revenue: number }[] {
  const today = new Date();
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

  const weeklyRevenueByWeek: { week: string; revenue: number }[] = [];

  let currentWeekStartDate = startOfMonth;
  while (currentWeekStartDate.getMonth() === today.getMonth()) {
    const currentWeekEndDate = new Date(currentWeekStartDate);
    currentWeekEndDate.setDate(currentWeekEndDate.getDate() + 7);

    const weekTransactions = this.transactions.filter(
      transaction =>
        ((transaction.date)as any).toDate() >= currentWeekStartDate && ((transaction.date)as any).toDate() < currentWeekEndDate
    );

    const weekRevenue = weekTransactions.reduce(
      (total, transaction) => total + parseFloat(transaction.amount.toString()),
      0
    );

    weeklyRevenueByWeek.push({
      week: `Semaine du ${currentWeekStartDate.getDate()} ${this.getMonthName(currentWeekStartDate)} au ${currentWeekEndDate.getDate()} ${this.getMonthName(currentWeekEndDate)} ${currentWeekStartDate.getFullYear()}`,
      revenue: weekRevenue
    });

    currentWeekStartDate = currentWeekEndDate;
  }

  return weeklyRevenueByWeek;
}

getMonthName(date: Date): string {
  const months = [
    'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
    'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
  ];
  return months[date.getMonth()];
}


//  la méthode pour calculer les chiffres d'affaires journaliers pour chaque jours
calculateDailyTotalRevenueByDay(): { day: string; revenue: number }[] {
  const today = new Date();

  const dailyRevenueByDay: { day: string; revenue: number }[] = [];

  for (let day = 1; day <= today.getDate(); day++) {
    const currentDate = new Date(today.getFullYear(), today.getMonth(), day);

    const dayTransactions = this.transactions.filter(
      transaction =>
        ((transaction.date)as any).toDate().toDateString() === currentDate.toDateString()
    );

    const dayRevenue = dayTransactions.reduce(
      (total, transaction) => total + parseFloat(transaction.amount.toString()),
      0
    );

    dailyRevenueByDay.push({
      day: `${this.getDayName(currentDate)} ${currentDate.getDate()} ${this.getMonthNames(currentDate)} ${currentDate.getFullYear()}`,
      revenue: dayRevenue
    });
  }

  return dailyRevenueByDay;
}

getDayName(date: Date): string {
  const days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
  return days[date.getDay()];
}

getMonthNames(date: Date): string {
  const months = [
    'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
    'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
  ];
  return months[date.getMonth()];
}


//  la méthode pour calculer le bilan
calculateBalance(): { deposits: number; withdrawals: number; balance: number } {
  const deposits = this.transactions
    .filter(transaction => transaction.type === 'depot')
    .reduce((total, transaction) => total + parseFloat(transaction.amount.toString()), 0);

  const withdrawals = this.transactions
    .filter(transaction => transaction.type === 'retrait')
    .reduce((total, transaction) => total + parseFloat(transaction.amount.toString()), 0);

  const balance = deposits - withdrawals;

  return { deposits, withdrawals, balance };

}


// La méthode pour calculer le total des dépôts
calculateTotalDeposits(): number {
  const totalDeposits = this.transactions
    .filter(transaction => transaction.type === 'depot')
    .reduce((total, transaction) => total + parseFloat(transaction.amount.toString()), 0);

  return totalDeposits;
}

// la méthode pour calculer le total des retraits
calculateTotalWithdrawals(): number {
  const totalWithdrawals = this.transactions
    .filter(transaction => transaction.type === 'retrait')
    .reduce((total, transaction) => total + parseFloat(transaction.amount.toString()), 0);

  return totalWithdrawals;
}


// transaction.component.ts
calculateDailyTotalRevenueWithoutWithdrawals(): { day: string; revenue: number }[] {
  const today = new Date();

  const dailyRevenueByDay: { day: string; revenue: number }[] = [];

  for (let day = 1; day <= today.getDate(); day++) {
    const currentDate = new Date(today.getFullYear(), today.getMonth(), day);

    const dayTransactions = this.transactions.filter(
      transaction =>
        ((transaction.date)as any).toDate().toDateString() === currentDate.toDateString() &&
        transaction.type !== 'retrait'
    );

    const dayRevenue = dayTransactions.reduce(
      (total, transaction) => total + parseFloat(transaction.amount.toString()),
      0
    );

    dailyRevenueByDay.push({
      day: `${this.getDayName(currentDate)} ${currentDate.getDate()} ${this.getMonthName(currentDate)} ${currentDate.getFullYear()}`,
      revenue: dayRevenue
    });
  }

  return dailyRevenueByDay;
}



// transaction.component.ts
calculateDailyTotalRevenueWithoutWithdrawalss(): { day: string; revenue: number }[] {
  const today = new Date();

  const dailyRevenueByDay: { day: string; revenue: number }[] = [];

  for (let day = 1; day <= today.getDate(); day++) {
    const currentDate = new Date(today.getFullYear(), today.getMonth(), day);

    const dayTransactions = this.transactions.filter(
      transaction =>
        ((transaction.date)as any).toDate().toDateString() === currentDate.toDateString() &&
        transaction.type !== 'retrait'
    );

    const dayRevenue = dayTransactions.reduce(
      (total, transaction) => total + parseFloat(transaction.amount.toString()),
      0
    );

    const formattedDay =
      day === today.getDate()
        ? `Aujourd'hui`
        : day === today.getDate() - 1
        ? `Hier`
        : day === today.getDate() - 2
        ? `Avant-hier`
        : `${this.getDayName(currentDate)} ${currentDate.getDate()} ${this.getMonthName(currentDate)} ${currentDate.getFullYear()}`;

    dailyRevenueByDay.push({
      day: formattedDay,
      revenue: dayRevenue
    });
  }

  return dailyRevenueByDay;
}


//calculer et afficher le chiffre d'affaires journalier par opérateur avec la commission appliquée

calculateDailyRevenueWithCommissionForOperator(operatorId: string): number {
  const today = new Date();
  const operatorTransactions = this.transactions.filter(
    transaction =>
      transaction.operatorId === operatorId &&
      this.isSameDate(((transaction.date) as any).toDate(), today)
  );

  const totalRevenue = operatorTransactions.reduce(
    (total, transaction) => total + parseFloat(transaction.amount.toString()),
    0
  );

  // Appliquer la commission aux transactions dans la plage de montants de 500 à 2000 FCFA
  const commission = 27;
  const transactionsWithCommission = operatorTransactions.filter(
    transaction =>
      parseFloat(transaction.amount.toString()) >= 500 &&
      parseFloat(transaction.amount.toString()) <= 2000
  );

  const totalRevenueWithCommission = transactionsWithCommission.reduce(
    (total, transaction) => total + parseFloat(transaction.amount.toString()) - commission,
    totalRevenue
  );

  return totalRevenueWithCommission;
}









  // Méthode pour exporter les transactions vers Excel
  exportToExcel() {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.transactions);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, 'transactions');
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: 'application/octet-stream' });
    const url: string = window.URL.createObjectURL(data);
    const a: HTMLAnchorElement = document.createElement('a');
    a.href = url;
    a.download = fileName + '.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }


  printTransaction(transaction: Transaction): void {
    // Appelez la méthode printReceipt() avec les données de la transaction en cours
    this.printReceipt(transaction);
  }

  private printReceipt(transactionData: Transaction): void {

    const operatorId = transactionData.operatorId;

    // Recherchez l'opérateur correspondant dans la liste d'opérateurs
    const selectedOperator = this.operators.find(operator => operator.id === operatorId);
  if (selectedOperator) {
    const operatorName = (selectedOperator).name;
    const operatorLogo = (selectedOperator).photo;
    // Formatez le montant avec un espace pour les milliers et ajoutez " FCFA"
    const formattedAmount = new Intl.NumberFormat('fr-FR').format(transactionData.amount);
    const amountWithCurrency = `<strong>${formattedAmount} FCFA</strong>`;
    // Obtenez la date et l'heure actuelles formatées
    const currentDateTime = new Date().toLocaleString('fr-FR', { timeZone: 'UTC' });
    // transactionData.receiptNumber = this.receiptNumber++;
     // Convertissez le type de transaction en majuscules
     const transactionTypeUpperCase = transactionData.type.toUpperCase();
     // Utilisez la méthode appropriée pour obtenir le nom de l'utilisateur
      const loggedInUserName = this.user.firstName;
       // Obtenez la date et l'heure actuelles au format souhaité
       const printedDateTime = new Date().toLocaleString('fr-FR', {
        timeZone: 'UTC',
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
      const thankYouMessage = "Merci pour votre transaction!";
      const numberTicket = this.totalLength;
      const receiptHtml = `
      <html>
      <head>
        <style>
        @page {
          size: 80mm 80mm; /* Définissez les dimensions du papier thermique */
          margin: 0;
        }
        body {
          text-align: center;
          font-size: 12px;
          align-items: center;
          justify-content: space-between;
          margin: 0; /* Supprimez les marges du corps pour s'assurer que le contenu est centré sur la page */
          padding: 3px; /* Ajoutez un espace de marge autour du contenu pour éviter les coupures */
          line-height: 1;
        }
        h2 {
          text-align: center; /* Centrez le titre */
        }
          /* Ajoutez ici les styles CSS pour le reçu */
        </style>
      </head>
      <body>

        <div>
        <h6>AL-IKLASS SERVICES</h6>
        <small>Réclamation: <strong>0575212497</strong></small>
        </div>
        <p>Agent:<strong>${loggedInUserName}</strong></p>
        <p><h5>${transactionTypeUpperCase} PAR ${operatorName} </h5><img src="${operatorLogo}" width="30" height="30"></p>
        <p>Date: ${currentDateTime}</p>
        <p>Numéro: ${transactionData.numberPhone}</p>
        <p>Montant: ${amountWithCurrency} </p>
        <p>Imprimé le ${printedDateTime}</p>
        <p><strong>${thankYouMessage}</strong></p>
      </body>
      </html>
      `;

      const printWindow = window.open('', '', 'width=80mm,height=80mm');
      printWindow.document.open();
      printWindow.document.write(receiptHtml);
      printWindow.document.close();
      printWindow.print();
      // printWindow.close();
  }

    }

    // Obtenez le nombre de versements en attendant que la promesse se résolve
    getNumberVersement(){
      // Obtenez le nombre de versements
    this.transactionService.getNumberOfTransactions().then(count => {
      this.numberOfPayments = count;
      console.log(this.numberOfPayments);

    });
     }


  ngOnDestroy(): void {
    this.tokenSub.unsubscribe();
    this.isAdminSub.unsubscribe();
  }



}
