<div class="content-body">
  <div class="container-fluid">

    <div class="authincation h-100">
      <div class="container vh-100">
          <div class="row justify-content-center h-100 align-items-center">
              <div class="col-lg-6 col-md-8">
                  <div class="authincation-content">
                      <div class="row no-gutters">
                          <div class="col-xl-12">
                              <div class="auth-form">
                                  <h4 class="text-center mb-4">Modification d'un opérateur</h4>
                                  <form [formGroup]="operatorEditForm" (ngSubmit)="onSubmitOperatorEditForm()">
                                    <!-- <div class="alert alert-danger"></div> -->
                                      <div class="form-group">
                                        <div class="alert alert-danger"
                                        *ngIf="(operatorEditForm.controls.name.dirty
                                        || operatorEditForm.controls.name.touched)
                                        && operatorEditForm.controls.name.invalid
                                        && operatorEditForm.controls.name.errors.required"
                                        > Le Nom de l'opérateur est obligatoire.</div>
                                          <label class="mb-1"><strong>Nom de l'opérateur</strong></label>
                                          <input type="text" class="form-control" placeholder="Nom de l'opérateur" formControlName="name" >
                                      </div>
                                      <div class="form-group">
                                        <div class="alert alert-danger"
                                        *ngIf="(operatorEditForm.controls.operatorPhone.dirty
                                        || operatorEditForm.controls.operatorPhone.touched)
                                        && operatorEditForm.controls.operatorPhone.invalid
                                        && operatorEditForm.controls.operatorPhone.errors.required"
                                        > Le Numéro de l'opérateur est obligatoire.</div>
                                        <label class="mb-1"><strong>Numéro de l'opérateur</strong></label>
                                        <input type="text" class="form-control" placeholder="Numéro de l'opérateur" formControlName="operatorPhone" >
                                    </div>
                                      <div class="form-group">
                                        <div class="alert alert-danger"
                                        *ngIf="(operatorEditForm.controls.balance.dirty
                                        || operatorEditForm.controls.balance.touched)
                                        && operatorEditForm.controls.balance.invalid
                                        && operatorEditForm.controls.balance.errors.required"
                                        > Le Solde de l'opérateur est obligatoire.</div>
                                        <label class="mb-1"><strong>Solde de l'opéérateur</strong></label>
                                        <input type="text" class="form-control" placeholder="Solde de l'opéérateur" formControlName="balance" readonly>
                                    </div>
                                       <!-- image -->
                                     <div class="form-group col-md-6">
                                      <div class="form-group">
                                        <label for="ciTy"><strong> Choisissez un logo de dimension : 1080 × 1193</strong> </label>
                                        <a  class="btn btn-primary light mr-2 px-3" (click)="open(cameraModal)"><i class="fa fa-camera px-5"></i> </a>

                                        <div class="spinner-grow" role="status" *ngIf="photoUploading">
                                          <span class="sr-only">Loading...</span>
                                        </div>
                                        <!-- Modal -->
                                        <ng-template #cameraModal let-modal>
                                          <div class="modal-header">
                                            <h5 class="modal-title">Télécharger un logo</h5>
                                            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                              <span aria-hidden="true">&times;</span>
                                            </button>
                                          </div>
                                          <div class="modal-body">
                                            <div class="input-group mb-3">
                                              <div class="input-group-prepend">
                                                <span class="input-group-text">Télécharger un logo </span>
                                              </div>
                                              <div class="custom-file">
                                                <input type="file" class="custom-file-input" (change)="onUploadFile($event)" formControlName="photo">
                                                <label class="custom-file-label">Choisissez un logo </label>
                                              </div>
                                            </div>
                                          </div>
                                        </ng-template>
                                      </div>

                                    </div>
                                     <!-- end image -->
                                      <div class="text-center mt-4">
                                          <button type="submit" class="btn btn-primary  btn-sm btn-block" [disabled]="operatorEditForm.invalid" >Enregistrer</button>
                                      </div>
                                  </form>
                                  <div class="new-account mt-3">
                                    <p><a class="text-primary" [routerLink]="['/caisse/operators']">Retour à la liste des opérateurs</a></p>
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>


  </div>
</div>
