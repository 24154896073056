import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Operator } from 'src/app/models/operator';
import { OperatorService } from 'src/app/services/operator.service';
import { OperatorModalComponent } from '../operator-modal/operator-modal.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Recharge } from 'src/app/models/recharge';
import * as $ from 'jquery';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { RechargeModalComponent } from '../recharge-modal/recharge-modal.component';



@Component({
  selector: 'app-operator-list',
  templateUrl: './operator-list.component.html',
  styleUrls: ['./operator-list.component.css']
})
export class OperatorListComponent implements OnInit, OnDestroy {

  selectedOperatorRecharges: Recharge[];

  page = 1;
  pageSize = 4;
  collectionSize = Operator.length;

  operator: Operator;
  operators: Operator[];
  operatorsSub: Subscription;
  alert:any;
  total: number = 0;

  rechargeForm : FormGroup;

  constructor(private operatorService: OperatorService,
    private formBuilder : FormBuilder,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal
    ) {
    // this.refreshCountries();
  }



  ngOnInit(): void {
    this.operatorsSub = this.operatorService.getAllOperator().subscribe(
      (operators) => {
        this.operators = operators
        this.total = this.getTotalBalanceOperator();
      }
    );
    this.operatorService.getAllOperator();
    this.getOperatorById();
  }

  getOperatorById(){
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.operatorService.getByIdOperator(id).subscribe(op => {
        this.operator = op;
        console.log(this.operator);

      });
    }
  }

  onClickDeleteOperator(operatorId){
    const  confirmModal = this.modalService.open(OperatorModalComponent).result;
    confirmModal.then(()=>{
      this.operatorService.delete(operatorId)
    .then(
      () =>{

        console.log('Supprimé');

      }
    )
    }).catch(
      (error)=>{

        console.log(error);

      }
    )
  }
   getTotalBalanceOperator(){
    return this.operators.reduce((total, operator) => {
      return total + parseFloat(operator.balance.toString());
    },0)
  }


  //  refreshCountries() {
  //   this.operators = Operator
  //     .map((operator, i) => ({id: i + 1, ...operator}))
  //     .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  // }

ngOnDestroy(): void {
    this.operatorsSub.unsubscribe();
}



openRechargeModal(): void {
  const modalRef = this.modalService.open(RechargeModalComponent);
  modalRef.componentInstance.operator = this.operator;
  modalRef.result.then((result) => {
    console.log(result); // Handle modal close
  }, (reason) => {
    console.log(reason); // Handle modal dismissal
  });
}


// onSubmitRechargeForm(){


//   $('#exampleModal').modal('hide');

// }


// ...







}
