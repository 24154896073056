<!-- <div *ngIf="user" class="mt-5">
<div class="container">
  <div class="row">
    <p> {{user.firstName}} </p> - <p>{{user.lastName}}</p> - <p>{{user.email}}</p> - <p>{{user.phoneNumber}}</p> - <p>{{user.id}}</p>
  </div>
</div>
</div> -->
<div class="content-body">
  <div class="container-fluid">



  </div>
</div>
