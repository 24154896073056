<div class="authincation h-100" >
  <div class="container vh-100">
      <div class="row justify-content-center h-100 align-items-center">
          <div class="col-lg-6 col-md-8">
              <div class="authincation-content">
                  <div class="row no-gutters">
                      <div class="col-xl-12">
                          <div class="auth-form" *ngIf="user">
                            <div class="text-center mb-3">
                              <!-- <a [routerLink]="['/admin/index']"><img src="assets/images/logo-full.png" alt=""></a> -->
                            </div>
                              <h1 class="text-center mb-4">Merci de votre inscription</h1>
                              <h3> Nous avons envoyé un email de verification sur
                                <strong> {{user.email}}</strong> cliquez sur le lien qui vous a été envoyé
                                dans votre boite mail et votre compte sera approuvé </h3>


                                <!-- <div class="form-group">
                                    <label class="mb-1"><strong>Email</strong></label>

                                </div> -->
                                  <div class="text-center">
                                      <button type="submit" class="btn btn-primary btn-block" (click)="userService.emailVerified()"> <i class="fas fa-redo-alt m-1"></i> Renvoyer l'e-mail de vérification</button>
                                  </div>

                          <div class="new-account mt-3 text-center">
                            <p>Allez a la page de connexion <a class="text-primary" [routerLink]="['/auth/login']">Se connecter</a></p>
                        </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
