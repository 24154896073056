import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-account-url',
  templateUrl: './account-url.component.html',
  styleUrls: ['./account-url.component.css']
})
export class AccountUrlComponent implements OnInit {

  navSidebarClass: boolean = true;
  hamburgerClass: boolean = false;

  constructor(public sharedService: SharedService) { }

  ngOnInit(): void {
  }

}
