import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Caisse } from 'src/app/models/caisse';
import { CaisseService } from 'src/app/services/caisse.service';

@Component({
  selector: 'app-caisse-edit',
  templateUrl: './caisse-edit.component.html',
  styleUrls: ['./caisse-edit.component.css']
})
export class CaisseEditComponent implements OnInit {

  caisseEditForm : FormGroup;
  errorMsg: string;
  caisse: Caisse;
  caisseId: string;

  constructor(private formBuilder : FormBuilder,
              private caisseService:CaisseService,
              private router: Router,
              private route: ActivatedRoute,
              private toastr: ToastrService,
              ) { }

  ngOnInit(): void {
    this.caisseId = this.route.snapshot.params.id;
    this.caisseService.getByIdCaisse(this.caisseId).subscribe(
      (caisse)=> {
        this.caisse = caisse;
        this.caisseEditForm.patchValue(caisse);
      }
    )
    this.initCaisseForm();
  }

  initCaisseForm(){
    this.caisseEditForm = this.formBuilder.group({
      balance: ['', [Validators.required,Validators.pattern(/^[0-9]*$/)]]
    });
  }
  onSubmitcaisseEditBalanceForm(){
    this.errorMsg = null;
    const caisseEditData: Caisse = this.caisseEditForm.value;
    this.caisseService.editCaisse(this.caisseId,caisseEditData)
                      .then(
                        () => {
                          this.router.navigate(['caisse/cash']);
                          console.log('Fond de caisse modifier avec succès');
                          this.toastr.success('Fond de caisse modifier avec succès.');

                        })
                      .catch(
                        (errMsg) => {
                          this.errorMsg = errMsg
                        })

  }

}
