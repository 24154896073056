<div id="main-wrapper" [ngClass]="{'menu-toggle': sharedService.hamburgerClass, '': !sharedService.hamburgerClass}"  class="show">

  <app-account-nav></app-account-nav>
  <app-account-header></app-account-header>
  <app-account-navigate></app-account-navigate>

  <router-outlet></router-outlet>
  <!-- <app-account-footer></app-account-footer> -->

</div>
