import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Operator } from 'src/app/models/operator';
import { Transaction } from 'src/app/models/transaction';
import { OperatorService } from 'src/app/services/operator.service';
import { TransactionService } from 'src/app/services/transaction.service';
import { PrintModalComponent } from '../print-modal/print-modal.component';
import { NgxPrintDirective } from 'ngx-print';
import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { User } from 'src/app/models/user';
import { Caisse } from 'src/app/models/caisse';
import { CaisseService } from 'src/app/services/caisse.service';

@Component({
  selector: 'app-operation-form',
  templateUrl: './operation-form.component.html',
  styleUrls: ['./operation-form.component.css']
})
export class OperationFormComponent implements OnInit {

  @ViewChild('printSection') printSection: NgxPrintDirective;
  transactionForm:FormGroup;
  errorMsg: string;

  operators: Operator[];
  operatorsSub: Subscription;

  transactions$: Transaction[] = [] ;
  transaction : Transaction;
  numberOfPayments: number =0;

  caisse : Caisse;
  caisses: Caisse[];
  currentBalance: number;
  fundId: string;

  receiptNumber: number = 0;
  user : User;
  loggedInUserName: User | null = null;

  constructor(private formBuilder: FormBuilder,
              private transactionService: TransactionService,
              private caisseService: CaisseService,
              private router: Router,
              private dialog: MatDialog,
              private operatorService: OperatorService,
              private toastr: ToastrService,
              private userService:UserService,
              private afa: AngularFireAuth,
              private authService: AuthService,
    ) { }

  ngOnInit(): void {
    this.initTransactionForm();
    this.initOperatorSub()
    this.getCaisse();
    this.operatorService.getAllOperator();
    this.afa.authState.subscribe(
      (user) =>{
        if (user) {
          this.userService.getUserById(user.uid).subscribe(
            (userData) => {
              this.user = userData;
            })
        }
      } );

      this.caisseService.getAllCaisse().subscribe(funds => {
        if (funds && funds.length > 0) {
          this.fundId = funds[0].id;
        }
      });
  }

  initOperatorSub(){
    this.operatorsSub = this.operatorService.getAllOperator().subscribe(
      (operators) => {
        this.operators = operators
      }
    );
  }
  getCaisse(){
    this.caisseService.getAllCaisse().subscribe(
      (caisse: any)=>{
        this.currentBalance =  caisse.amount
      });
  }



  initTransactionForm(){
    this.transactionForm = this.formBuilder.group({
      numberPhone: ['',[Validators.required]],
      type: ['',[Validators.required]],
      operatorId: ['',[Validators.required]],
      status: ['',[Validators.required]],
      amount: ['',[Validators.required, Validators.min(100),Validators.pattern(/^[0-9]*$/)]],
      date: ['']
    })
  }

  onSubmitTransactionForm(){
    this.errorMsg = null;
    const transactionData: Transaction = this.transactionForm.value;

    // transactionData.amount = this.transactionForm.value.amount.replace(/\s/g, '');
    transactionData.date = new Date();
    transactionData.userName = this.user.firstName;
    // transactionData.receiptNumber = this.receiptNumber++;
    //this.updateCaisses(transactionData);
    if (this.fundId) {
      this.caisseService.updateFundBalance(this.fundId, transactionData.amount, transactionData.type);
    }

    this.transactionService.CreateTransaction({ ...transactionData, receiptNumber: this.receiptNumber })
                      .then(
                        () => {

                          this.router.navigate(['caisse/operations']);
                          console.log('Transaction enregistrée avec succès.');
                          this.toastr.success('Transaction enregistrée avec succès.');
                         this.updateBalances(transactionData);
                        //  this.updateCaisses(transactionData);
                          //this.updateCashFund(transactionData);


                          // Ouvrir la boîte de dialogue de confirmation pour l'impression
                          const dialogRef = this.dialog.open(PrintModalComponent,{
                            data: { ...transactionData, receiptNumber: this.receiptNumber }});

                          dialogRef.afterClosed().subscribe((print) => {
                            if (print) {
                             // Imprimer la transaction en utilisant ngx-print
                             this.printReceipt(transactionData);

                            }
                          });
                           this.receiptNumber++;
                        })
                      .catch(
                        (errMsg) => {
                          this.errorMsg = errMsg
                        })

  }

  // Méthode pour imprimer

  printReceipt(transactionData: Transaction): void {

  const operatorId = transactionData.operatorId;

  // Recherchez l'opérateur correspondant dans la liste d'opérateurs
  const selectedOperator = this.operators.find(operator => operator.id === operatorId);
if (selectedOperator) {
  const operatorName = (selectedOperator).name;
  const operatorLogo = (selectedOperator).photo;
  // Formatez le montant avec un espace pour les milliers et ajoutez " FCFA"
  const formattedAmount = new Intl.NumberFormat('fr-FR').format(transactionData.amount);
  const amountWithCurrency = `<strong>${formattedAmount} FCFA</strong>`;
  // Obtenez la date et l'heure actuelles formatées
  const currentDateTime = new Date().toLocaleString('fr-FR', { timeZone: 'UTC' });
  // transactionData.receiptNumber = this.receiptNumber++;
   // Convertissez le type de transaction en majuscules
   const transactionTypeUpperCase = transactionData.type.toUpperCase();
   // Utilisez la méthode appropriée pour obtenir le nom de l'utilisateur
    const loggedInUserName = this.user.firstName;
     // Obtenez la date et l'heure actuelles au format souhaité
     const printedDateTime = new Date().toLocaleString('fr-FR', {
      timeZone: 'UTC',
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
    const thankYouMessage = "Merci pour votre transaction!";
    // const numberTicket = this.numberOfPayments;
  const receiptHtml = `
    <html>
    <head>
      <style>
      @page {
        size: 80mm 80mm; /* Définissez les dimensions du papier thermique */
        margin: 0;
      }
      body {
        text-align: center;
        align-items: center;
        justify-content: space-between;
        font-size: 11px;
        margin: 0; /* Supprimez les marges du corps pour s'assurer que le contenu est centré sur la page */
        padding: 3px; /* Ajoutez un espace de marge autour du contenu pour éviter les coupures */
        line-height: 1;
      }
      h2 {
        text-align: center; /* Centrez le titre */
      }

        /* Ajoutez ici les styles CSS pour le reçu */
      </style>
    </head>
    <body>

      <div>
      <h6>AL-IKLASS SERVICES</h6>
      <small>Réclamation: <strong>0575212497</strong></small>
      </div>
      <p>Agent:<strong> ${loggedInUserName}</strong></p>
      <div class="receipt-content">
      <img src="${operatorLogo}" width="30" height="30">
      </div>
      <p><h5>${transactionTypeUpperCase} PAR ${operatorName} </h5> </p>
      <p>Date: ${currentDateTime}</p>
      <p>Numéro: ${transactionData.numberPhone}</p>
      <p>Montant: ${amountWithCurrency} </p>
      <p>Imprimé le ${printedDateTime}</p>
      <p><strong>${thankYouMessage}</strong></p>
    </body>
    </html>
    `;

    const printWindow = window.open('', '', 'width=80mm,height=80mm');
    printWindow.document.open();
    printWindow.document.write(receiptHtml);
    printWindow.document.close();
    printWindow.print();
    // printWindow.close();
}

  }
  // Mettre à jour les soldes des opérateurs après une transaction
  updateBalances(transaction: Transaction): void {
    const operator = this.operators.find((op) => op.id === transaction.operatorId);

    if (operator && transaction.type === 'retrait') {
      operator.balance += parseFloat(transaction.amount.toString());

    } else if (operator && transaction.type === 'depot'
                || transaction.type === 'credit'
                || transaction.type === 'internet'
                || transaction.type === 'sodeci'
                || transaction.type === 'cie'
                || transaction.type === 'carte UBA'
                || transaction.type === 'inscription'
                || transaction.type === 'starTimes'
                || transaction.type === 'canal'
                ) {
      operator.balance -= parseFloat(transaction.amount.toString());
    }

    this.operatorService.editOperator(transaction.operatorId, operator);

  }

  // Mettre à jour les soldes des opérateurs après une transaction


  updateCashFund(transaction: Transaction): void {
    // Récupérez le fond de caisse depuis le service ou Firestore
    this.caisseService.getAllCaisse().subscribe((caisses: Caisse[]) => {

        const cashFund = caisses[0]; // Prenez le premier (et seul) élément du tableau

        if (transaction.type === 'retrait') {
          // Diminuez le solde du fond de caisse lors d'un retrait
          cashFund.balance  -= parseFloat(transaction.amount.toString());
        } else if (
          transaction.type === 'depot' ||
          transaction.type === 'credit' ||
          transaction.type === 'internet' ||
          transaction.type === 'sodeci' ||
          transaction.type === 'cie' ||
          transaction.type === 'carte UBA' ||
          transaction.type === 'inscription' ||
          transaction.type === 'starTimes' ||
          transaction.type === 'canal'
        ) {
          // Augmentez le solde du fond de caisse pour d'autres types de transactions
          cashFund.balance += parseFloat(transaction.amount.toString());
        }

        // Mettez à jour le solde du fond de caisse dans le service ou Firestore
        //this.cashFundService.updateCashFundAmount(cashFund.amount);
        this.caisseService.editCaisse(cashFund.id,cashFund)

    });
  }
 

  // Obtenez le nombre de versements en attendant que la promesse se résolve
  getNumberVersement(){
    // Obtenez le nombre de versements
  this.transactionService.getNumberOfTransactions().then(count => {
    this.numberOfPayments = count;
   

  });
   }





}
