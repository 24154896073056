import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Driver } from 'src/app/models/driver';
import { DriverService } from 'src/app/services/driver.service';
import { OperatorModalComponent } from '../../operators/operator-modal/operator-modal.component';

@Component({
  selector: 'app-drive-list',
  templateUrl: './drive-list.component.html',
  styleUrls: ['./drive-list.component.css']
})
export class DriveListComponent implements OnInit {

  drivers: Driver[] = [];
  searchDrivers :Driver[]= [];
  totalLength: number;
  page : number = 1;

  constructor(private driverService: DriverService,
     private modalService: NgbModal
    ) {}

  ngOnInit(): void {
    this.getDrivers();
  }

  getDrivers(): void {
    this.driverService.getAllDrivers().then((drivers) => {
     this.searchDrivers = this.drivers = drivers;
    });
  }

  searchDriver(query: string){
    this.searchDrivers = (query) ? this.drivers.filter(driver => driver.driverPhone.toLowerCase().includes(query.toLowerCase())
    || driver.name.toLowerCase().includes(query.toLowerCase())
    ): this.drivers;
  }


  onDeleteDriver(driverId){
    const  confirmModal = this.modalService.open(OperatorModalComponent).result;
    confirmModal.then(()=>{
      this.driverService.deleteDriver(driverId)
    .then(
      () =>{

        console.log('Supprimé');

      }
    )
    }).catch(
      (error)=>{

        console.log(error);

      }
    )
  }

}
