import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';
import { UserService } from 'src/app/services/user.service';
import firebase from 'firebase/app';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-account-nav',
  templateUrl: './account-nav.component.html',
  styleUrls: ['./account-nav.component.css']
})
export class AccountNavComponent implements OnInit {

  toggleChat: boolean = true;
	toggleSingle: boolean = true;
  user: User;
  isAuth = false;

  constructor(private sharedService: SharedService,
    private userService:UserService,
    private authService: AuthService,
    private afa: AngularFireAuth
    ) { }

  ngOnInit(): void {
    // firebase.auth().onAuthStateChanged(
    //   (user) => {
    //     if (user) {
    //       this.isAuth = true;
    //       console.log(this.isAuth);
    //       console.log(this.userService.getUserById());
    //       this.userService.getUserById().subscribe(
    //         (user: User) => {
    //           this.user =user;
    //         }
    //       ).catch(
    //         (error)=> {
    //           console.error(error);
    //         }
    //       );


    //     } else {
    //       this.isAuth = false;
    //       console.log(this.isAuth);

    //     }
    //   }
    // );
      this.afa.authState.subscribe(
        (user) =>{
          if (user) {
            this.isAuth = true;
            console.log(this.isAuth);
            this.userService.getUserById(user.uid).subscribe(
              (userData) => { 
                this.user = userData;
              })
          }else{
            this.isAuth = false;
           console.log(this.isAuth);

          }
        }
      )
  }

  togglechatbar() {
		this.toggleChat = !this.toggleChat;
	}
	singleChatWindow() {
		this.toggleSingle = !this.toggleSingle;
	}
  onLogOut(){
    this.authService.signOutUser();
  }
}
