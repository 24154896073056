<div class="content-body">
  <div class="container-fluid">

    <div class="authincation h-100">
      <div class="container vh-100">
          <div class="row justify-content-center h-100 align-items-center">
              <div class="col-lg-6 col-md-8">
                  <div class="authincation-content">
                      <div class="row no-gutters">
                          <div class="col-xl-12">
                              <div class="auth-form">
                                  <h4 class="text-center mb-4">Enregistrement d'une opération</h4>
                                  <form [formGroup]="transactionForm" (ngSubmit)="onSubmitTransactionForm()">
                                    <!-- <div class="alert alert-danger"></div> -->
                                      <div class="form-group">
                                        <div class="alert alert-danger"
                                        *ngIf="(transactionForm.controls.numberPhone.dirty
                                        || transactionForm.controls.numberPhone.touched)
                                        && transactionForm.controls.numberPhone.invalid
                                        && transactionForm.controls.numberPhone.errors.required"
                                        > Le Numéro du client est obligatoire.</div>
                                          <label class="mb-1"><strong>Numéro du client</strong></label>
                                          <input type="text" class="form-control" placeholder="Numéro du client ou le nom du client" formControlName="numberPhone">
                                      </div>
                                      <div class="form-group">
                                        <div class="alert alert-danger"
                                        *ngIf="(transactionForm.controls.type.dirty
                                        || transactionForm.controls.type.touched)
                                        && transactionForm.controls.type.invalid
                                        && transactionForm.controls.type.errors.required"
                                        > Le Type de opération est obligatoire.</div>
                                        <label class="mb-1"><strong>Type de opération</strong></label>
                                        <select
                                        class="form-control text-primary"
                                        formControlName="type"
                                        >
                                          <option value="" selected>Choisir</option>
                                          <option value="depot" >Dépôt</option>
                                          <option value="retrait" >Retrait</option>
                                          <option value="credit" >Recharger du crédit </option>
                                          <option value="carte UBA" >Rechargement carte UBA </option>
                                          <option value="internet" >Souscription internet</option>
                                          <option value="inscription" >Inscription en ligne</option>
                                          <option value="cie" >Facture CIE</option>
                                          <option value="sodeci" >Facture SODECI</option>
                                          <option value="canal" >Facture Canal</option>
                                          <option value="starTimes" >Facture starTimes</option>
                                        </select>
                                    </div>
                                      <div class="form-group">
                                        <div class="alert alert-danger"
                                        *ngIf="(transactionForm.controls.operatorId.dirty
                                        || transactionForm.controls.operatorId.touched)
                                        && transactionForm.controls.operatorId.invalid
                                        && transactionForm.controls.operatorId.errors.required"
                                        > Le choix de l'opérateur est obligatoire.</div>
                                        <label class="mb-1"><strong>L'opérateur</strong></label>
                                        <select
                                        class="form-control text-primary"
                                        formControlName="operatorId"
                                        >
                                          <option value="" selected>Choisir</option>
                                          <option *ngFor="let operator of operators" [value]="operator.id">{{operator.name}}</option>
                                        </select>
                                    </div>
                                      <div class="form-group">
                                        <div class="alert alert-danger"
                                        *ngIf="(transactionForm.controls.status.dirty
                                        || transactionForm.controls.status.touched)
                                        && transactionForm.controls.status.invalid
                                        && transactionForm.controls.status.errors.required"
                                        > Le choix du status est obligatoire.</div>
                                        <label class="mb-1"><strong>Status</strong></label>
                                        <select
                                        class="form-control text-primary"
                                        formControlName="status"
                                        >
                                          <option value="" selected>Choisir</option>
                                          <option value="payer" >Payé</option>
                                          <option value="non payer" >Non payé</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                      <div class="alert alert-danger"
                                      *ngIf="(transactionForm.controls.amount.dirty
                                      || transactionForm.controls.amount.touched)
                                      && transactionForm.controls.amount.invalid
                                      && transactionForm.controls.amount.errors.required"
                                      > Le montant de la transaction  est obligatoire.</div>
                                        <label class="mb-1"><strong>Le montant</strong></label>
                                        <input type="text" class="form-control" placeholder="Montant" formControlName="amount">
                                    </div>
                                      <div class="text-center mt-4">
                                          <button type="submit" class="btn btn-primary  btn-sm btn-block" [disabled]="transactionForm.invalid" >Enregistrer</button>
                                      </div>
                                  </form>
                                  <div class="new-account mt-3">
                                    <p><a class="text-primary" [routerLink]="['/caisse/operations']">Retour à la liste des opérations</a></p>
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
  </div>
</div>
