import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentReference } from '@angular/fire/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Car } from '../models/car';
import { Driver } from '../models/driver';

@Injectable({
  providedIn: 'root'
})
export class CarService {

  private carsCollection: AngularFirestoreCollection<Car>;

  constructor(private firestore: AngularFirestore) {
    this.carsCollection = firestore.collection<Car>('cars');
  }

  // Récupère tous les véhicules
  getCars(): Observable<Car[]> {
    return this.carsCollection.snapshotChanges().pipe(
      map(cars =>
        cars.map(a => {
          const data = a.payload.doc.data() as Car;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      )
    );
  }
  getAllCars(): Promise<Car[]> {
    return this.firestore.collection('cars', ref => ref.orderBy('date', 'desc'))
    .get()
    .toPromise()
    .then(snapshot => {
      const cars: Car[] = [];
      snapshot.forEach(doc => {
        const data = doc.data() as Car;
        const id = doc.id;
        cars.push({ id, ...data });
      });
      return cars;
    });
  }

  getByIdCar(carId: string): Observable<Car | undefined> {
    return this.carsCollection.doc<Car>(carId).valueChanges();
  }

  // Ajoute un nouveau véhicule
  addCar(car: Car): Promise<any> {
    return this.carsCollection.add(car);
  }

  // Met à jour un véhicule existant
  updateCar(carId: string, car: Car): Promise<void> {
    return this.carsCollection.doc(carId).update(car);
  }

  // Supprime un véhicule
  deleteCar(carId: string): Promise<void> {
    return this.carsCollection.doc(carId).delete();
  }

  // Récupère les chauffeurs disponibles
  getAvailableDrivers(): Observable<Driver[]> {
    return this.firestore.collection<Driver>('drivers').valueChanges();
  }

  



}
