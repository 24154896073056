import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit{
  signUpForm: FormGroup;
  usersSubscription: Subscription;
  users: User[] = [];
  user:User;
  errorMsg: string;
  photoUploading = false;
  photoUploaded = false;
  photoUrl: string;
  

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private authService: AuthService,
    private modalService: NgbModal,
    private router: Router,
    private afa: AngularFireAuth
    ) { }

  ngOnInit(): void {
    this.initUsers();

  }
  initUsers(){
    this.signUpForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.email,Validators.required]],
      password: ['', [Validators.required, Validators.pattern(/[0-9a-zA-Z]{6,}/)]],
      phoneNumber: ['', [Validators.required]]
    });
  }



  open(modelId:any) {
		this.modalService.open(modelId);
	}

  onSubmitSignUpForm(){
    const newUser: User = this.signUpForm.value;
    this.authService.signUpUser(newUser).then(
      () => {

      }
    ).catch(
      (errMsg) => this.errorMsg = errMsg
    )



  }
  resetForm(){
    this.signUpForm.reset();
  }
  // ngOnDestroy(): void {
  //     this.usersSubscription.unsubscribe();
  // }

}
