<!--**********************************
    Sidebar start
***********************************-->
<div class="deznav">
  <div class="deznav-scroll" [perfectScrollbar]>
    <div class="main-profile" >
      <div *ngIf="user">

        <!-- <img [src]="user.photo" alt="{{user.firstName}}" *ngIf="user.photo"> -->
        <h1
          class="profile-text"
          [ngClass]="{ profileTextDark: (isDarkTheme | async) }"
          [style.font-size]="fontSize"
          [style.margin-top]="marginTop"
          [style.color]="textColor"
          *ngIf="!user.photo"
        >
          {{ user.firstName.substring(0, 1)?.toUpperCase() }}{{ user.lastName.substring(0, 1)?.toUpperCase() }}
        </h1>
        <!-- <a routerLink="/user/profile"><i class="fa fa-cog" aria-hidden="true"></i></a> -->
        <h5 class="mb-0 fs-20 text-black mb-3 "><span class="font-w400">Salam,</span> {{user.firstName}} </h5>
      </div>
        <div *ngIf="userService.userData as user">
        <!-- <p class="mb-0 fs-14 font-w400"> <span class="font-w400">ID :</span>  {{user.uid}}</p> -->
        <!-- <p class="mb-0 fs-14 font-w400"><span class="font-w400">Email:</span>  {{user.email}}</p> -->
        <p class="mb-0 fs-14 font-w400">Dernière connexion : {{user.metadata.lastSignInTime | date: 'dd MMM yyy HH:mm':'fr'}}</p>

        </div>
    </div>
      <ul class="metismenu" id="menu" metis-menu>
          <li [ngClass]="{'mm-active': dashboardArray.indexOf(currentHref) > -1}" *ngIf="isAdmin">
              <a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                <!-- <i class="fa fa-money" aria-hidden="true"></i> -->
                <i class="fa fa-tachometer" aria-hidden="true"></i>
                  <span class="nav-text">Dashboard</span>
              </a>
              <ul aria-expanded="false"  [ngClass]="{'mm-show': dashboardArray.indexOf(currentHref) > -1}">
                  <li><a class="{{(currentHref == '/caisse/dashboard' || currentHref == '/caisse') ? 'mm-active' : ''}}" [routerLink]="['/caisse/dashboard']">Dashboard</a></li>
                  <!-- <li><a [routerLink]="['/caisse/mywallet']" class="{{(currentHref == '/caisse/mywallet') ? 'mm-active' : ''}}">Portefeuille</a></li>
                  <li><a [routerLink]="['/caisse/transactions']" class="{{(currentHref == '/caisse/transactions') ? 'mm-active' : ''}}">Transactions</a></li>
                  <li><a [routerLink]="['/caisse/users']" class="{{(currentHref == '/caisse/users') ? 'mm-active' : ''}}">Utilisateurs</a></li> -->
              </ul>

          </li>
          <li [ngClass]="{'mm-active': dashboardArray.indexOf(currentHref) > -1}">
              <a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                <!-- <i class="fa fa-money" aria-hidden="true"></i> -->
                 <i class="fa fa-money" aria-hidden="true"></i>
                  <span class="nav-text">Caisse</span>
              </a>
              <ul aria-expanded="false"  [ngClass]="{'mm-show': dashboardArray.indexOf(currentHref) > -1}">
                  <li><a class="{{(currentHref == '/caisse/cash' || currentHref == '/caisse') ? 'mm-active' : ''}}" [routerLink]="['/caisse/cash']">Caisses</a></li>
                  <li><a class="{{(currentHref == '/caisse/expenses' || currentHref == '/caisse') ? 'mm-active' : ''}}" [routerLink]="['/caisse/expenses']">Dépenses</a></li>
                  <!-- <li><a [routerLink]="['/caisse/mywallet']" class="{{(currentHref == '/caisse/mywallet') ? 'mm-active' : ''}}">Portefeuille</a></li>
                  <li><a [routerLink]="['/caisse/transactions']" class="{{(currentHref == '/caisse/transactions') ? 'mm-active' : ''}}">Transactions</a></li>
                  <li><a [routerLink]="['/caisse/users']" class="{{(currentHref == '/caisse/users') ? 'mm-active' : ''}}">Utilisateurs</a></li> -->
              </ul>

          <!-- </li>
          <li *ngIf="isAdmin">
              <a class="has-arrow ai-icon" routerLink="/caisse/operators" aria-expanded="false">
                  <i class="flaticon-049-copy"></i>
                  <span class="nav-text">Opérateurs</span>
              </a>
              <ul aria-expanded="false">
                  <li><a [routerLink]="['/caisse/operators']">Opérateurs</a></li>

              </ul>
          </li> -->
          <li>
              <a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                <!-- <i class="fa fa-university" aria-hidden="true"></i> -->
                <i class="fa fa-exchange" aria-hidden="true"></i>
                  <span class="nav-text">Transfert mobiles </span>
              </a>
              <ul aria-expanded="false">
                <li><a [routerLink]="['/caisse/operators']">Opérateurs</a></li>
                  <li><a [routerLink]="['/caisse/operations']">Transactions</a></li>
              </ul>
          </li>
          <!-- <li>
              <a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                <i class="fa fa-exchange" aria-hidden="true"></i>
                  <span class="nav-text">Transfert International </span>
              </a>
              <ul aria-expanded="false">
                  <li><a [routerLink]="['/caisse/operations']">Transactions</a></li>
              </ul>
          </li> -->
          <li>
              <a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                <i class="fa fa-taxi" aria-hidden="true"></i>
                  <span class="nav-text">Transports </span>
              </a>
              <ul aria-expanded="false">
                 
                  <li><a [routerLink]="['/caisse/drivers']">Chauffeurs</a></li>
                  <li><a [routerLink]="['/caisse/cars']">Véhicules</a></li>
                  <li><a [routerLink]="['/caisse/cars/versements']">Versements</a></li>
                  <li><a [routerLink]="['/caisse/cars/versements/pointage']">Pointages & Dettes</a></li>
              </ul>
          </li>
          <li *ngIf="isAdmin">
              <a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                <i class="fa fa-users" aria-hidden="true"></i>
                  <span class="nav-text">Utilisateurs</span>
              </a>

              <ul aria-expanded="false">
                  <li><a [routerLink]="['/caisse/users']">Utilisateurs</a></li>
              </ul>
          </li>
          <li>
              <a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                <i class="fa fa-cog" aria-hidden="true"></i>
                  <span class="nav-text">Paramètre</span>
              </a>
              <ul aria-expanded="false">
                  <li><a [routerLink]="['/caisse/profile']">Profile</a></li>
                  <!-- <li><a [routerLink]="['/page-login']">Login</a></li> -->
              </ul>
          </li>
      </ul>
      <div class="copyright">
          <p><strong>Iklass Service</strong> © 2023 All Rights Reserved</p>
          <p class="fs-12">Made with <span [ngClass]="{'': toggleIcon, 'heart-blast': !toggleIcon}" class="heart" (click)="toggleLoveIcon()"></span> by Bouba</p>
      </div>
  </div>
</div>
<!--**********************************
  Sidebar end
***********************************-->
