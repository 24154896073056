import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Car } from 'src/app/models/car';
import { DailyPayment } from 'src/app/models/daily-payment';
import { Driver } from 'src/app/models/driver';
import { CarService } from 'src/app/services/car.service';
import { DailyPaymentService } from 'src/app/services/daily-payment.service';
import { DriverService } from 'src/app/services/driver.service';

@Component({
  selector: 'app-daily-versement-edit',
  templateUrl: './daily-versement-edit.component.html',
  styleUrls: ['./daily-versement-edit.component.css']
})
export class DailyVersementEditComponent implements OnInit {

  versementEditForm: FormGroup;
  dailyPayment: DailyPayment;
  availableCars: Car[] = [];
  availableDrivers: Driver[] = [];
  selectedCar: Car | null = null;
  availableDriversForSelectedCar: Driver[] = [];
  driverDebt: number = 0;
  dailyPayments: DailyPayment[] =[];
  errorMsg: string;
  dailyPaymentId: string;

  constructor(
    private fb: FormBuilder,
    private carService: CarService,
    private driverService: DriverService,
    private router : Router,
    private route: ActivatedRoute,
    private dailyPaymentService: DailyPaymentService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.initVersementEditForm();
    this.loadAvailableCars();
    this.loadAvailableDrivers();
    this.loadPayments();
    this.dailyPaymentId = this.route.snapshot.params.id;
    this.dailyPaymentService.getByIdDailyPayment(this.dailyPaymentId).subscribe(
      (dailyPayment)=>{
        this.dailyPayment = dailyPayment
        this.versementEditForm.patchValue(dailyPayment);
        console.log(dailyPayment);
        

      });

  }

  initVersementEditForm(){
    this.versementEditForm = this.fb.group({
      carId: ['', Validators.required],
      driverId: ['', Validators.required],
      amount: ['', [Validators.required,Validators.max(26000),Validators.pattern(/^[0-9]*$/)]],
      date: [''],
    });
  }

  loadAvailableCars() {
    this.carService.getCars().subscribe((data) => {
      this.availableCars = data;
      console.log(data);


    });
  }

  loadAvailableDrivers() {
    this.driverService.getDrivers().subscribe((data) => {
      this.availableDrivers = data;
    });
  }
  onCarSelectionChange() {
    const selectedCarId = this.versementEditForm.get('carId').value;
    if (selectedCarId) {
      this.selectedCar = this.availableCars.find((car) => car.id === selectedCarId);

      // Débogage
      console.log('Selected Car:', this.selectedCar);

      if (this.selectedCar) {
        // Filtrer les chauffeurs disponibles pour le véhicule sélectionné
        this.availableDriversForSelectedCar = this.availableDrivers.filter((driver) =>
          this.selectedCar.driverIds.includes(driver.id)
        );

        // Débogage
        console.log('Available Drivers for Selected Car:', this.availableDriversForSelectedCar);
      } else {
        this.availableDriversForSelectedCar = [];
      }
    } else {
      this.selectedCar = null;
      this.availableDriversForSelectedCar = [];
    }
  }
  calculateDriverDebt(paymentAmount: number): number {
    const dailyDebt = Math.max(0, 26000 - paymentAmount);
    return dailyDebt;
  }
  loadPayments() {
    this.dailyPaymentService.getDailyPayments().subscribe((data) => {
      this.dailyPayments = data;
    });
  }

  getCarName(carId: string): string{

    const car = this.availableCars.find((car) => car.id === carId);
    return car ? car.brand : 'Inconnu';



}
  getDriverName(driverId: string): string{

    const driver = this.availableDrivers.find((d) => d.id === driverId);
    return driver ? driver.name : 'Inconnu';

}

calculateCommission(paymentAmount: any): number {
  const roundedAmount = Number(paymentAmount); // Convertir en nombre
  if (roundedAmount >= 25000 && roundedAmount <= 25999) {
    return  1000;
  } else if (roundedAmount === 26000) {
    return  2000;
  } else {
    return 0;
  }
}




  onSubmitVersementEditForm() {
    if (this.versementEditForm.valid) {
      const versementEditData = this.versementEditForm.value;

      // Générez automatiquement la date du versement
      // versementEditData.date = new Date();
      const dailyEarnings = versementEditData.amount;
      const dailDebt = Math.max(0, 26000 - dailyEarnings);
      versementEditData.debtAmount = dailDebt;
      versementEditData.commission = this.calculateCommission(dailyEarnings);
      // Appelez le service pour ajouter un versement
      this.dailyPaymentService.updateDailyPayment(this.dailyPaymentId,versementEditData).then(() => {
        console.log('Enregister!!!!!!!!');
        this.toastr.success('Le Versement à été modifier avec succès.');
        this.router.navigate(['caisse/cars/versements']);

      }).catch(
        (errMsg) => {
          this.errorMsg = errMsg
        });
    }
  }


}
