
    <!--**********************************
        Content body start
    ***********************************-->
    <div class="content-body">
      <div class="container-fluid">
          <div class="form-head mb-lg-5 mb-3 d-flex flex-wrap align-items-center">
              <h2 class="font-w600 mb-3 mr-auto ">Tableau de bord</h2>
              <!-- <div class="weather-btn mb-2">
                  <span class="mr-3 fs-22 font-w600 text-black"><i class="fa fa-cloud mr-2"></i>21</span>
                  <mat-form-field   class="dashboard-select">
                    <mat-select value="Medan, IDN">
                      <mat-option value="Medan, IDN">Medan, IDN</mat-option>
                      <mat-option value="Jakarta, IDN">Jakarta, IDN</mat-option>
                      <mat-option value="Surabaya, IDN">Surabaya, IDN</mat-option>
                    </mat-select>
                  </mat-form-field>
              </div> -->
              <a routerLink="/caisse/operatos" class="btn btn-secondary text-white mb-2 m-2">Enregistrer un opérateur</a>
              <a routerLink="/caisse/operations" class="btn btn-secondary text-white mb-2">Éffectuer une transaction</a>
          </div>
          <div class="row sp-sm-15">
              <div class="col-xl-2 col-3 m-t35">
                  <div class="card card-coin">
                      <div class="card-body text-center">
                          <h4>Total des dépôts</h4>
                          <h3 class="text-black mb-0 font-w600">{{ calculateTotalDeposits() | currency:'XOF':'symbol':'1.0-0':'fr' }}</h3>
                      </div>
                  </div>
              </div>
              <div class="col-xl-2 col-3 m-t35">
                  <div class="card card-coin">
                      <div class="card-body text-center">
                          <h4>Total des retraits</h4>
                          <h3 class="text-black mb-1 font-w600">{{ calculateTotalWithdrawals() | currency:'XOF':'symbol':'1.0-0':'fr' }}</h3>

                      </div>
                  </div>
              </div>
              <div class="col-xl-2 col-3 m-t35">
                  <div class="card card-coin">
                      <div class="card-body text-center">
                          <h4>Chiffre d'affaires journalier</h4>
                          <h3 class="text-black mb-1 font-w500">{{ dailyRevenue | currency: 'FCFA': 'symbol': '1.0-0': 'fr' }} </h3>
                      </div>
                  </div>
              </div>
              <div class="col-xl-2 col-3 m-t35">
                  <div class="card card-coin">
                      <div class="card-body text-center">
                          <h4>Chiffre d'affaires hebdomadaire</h4>
                          <h3 class="text-black mb-2 font-w600">{{  weeklyRevenue | currency: 'FCFA': 'symbol': '1.0-0': 'fr'}}</h3>
                      </div>
                  </div>
              </div>
              <div class="col-xl-2 col-3 m-t35">
                  <div class="card card-coin">
                      <div class="card-body text-center">
                          <h4>Chiffre d'affaires mensuel</h4>
                          <h3 class="text-black mb-2 font-w600">{{  monthlyRevenue | currency: 'FCFA': 'symbol': '1.0-0': 'fr'}}</h3>
                      </div>
                  </div>
              </div>
              <div class="col-xl-2 col-3 m-t35">
                  <div class="card card-coin">
                      <div class="card-body text-center">
                          <h4>Chiffre d'affaires annuel</h4>
                          <h3 class="text-black mb-0 font-w700">{{  annualRevenue | currency: 'FCFA': 'symbol': '1.0-0': 'fr'}}</h3>
                      </div>
                  </div>
              </div>

          </div>
          <div class="row">
              <div class="col-xl-6 col-xxl-8">
                  <!-- <app-graph-market-overview></app-graph-market-overview> -->
                  <div class="table-responsive table-hover fs-14">
                    <div class="mb-3">
                      <div class="input-group right d-lg-inline-flex d-none">
                        <h1>Les 5 dernières transactions</h1>
                      </div>
                  </div>
                    <table class="table display mb-4 dataTablesCard dataTable  short-one card-table text-black" id="example5">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Date & Heures</th>
                                <th>Numéros</th>
                                <th>Réseaux</th>
                                <th>Types</th>
                                <th>Montants</th>
                                <th>Status</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let transaction of transactions$; let i = index">
                                <!-- <td class="pr-0">
                                    <img class="ic-icon" src="{{transaction.image}}" />
                                </td> -->
                                <td>{{i +1}}</td>
                                <td>
                                  <span class="text-black font-w600">{{transaction.date.toDate() | date: 'dd MMM yyy HH:mm':'fr' }}</span>
                              </td>
                                <td>{{transaction.numberPhone}}</td>
                                <td>{{getoperatorName(transaction.operatorId)}}</td>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <!-- <img src="{{transaction.user_image}}"  class="rounded-circle mr-2 width36 height36" alt=""> -->
                                        <span>{{transaction.type}}</span>
                                    </div>
                                </td>
                                <td class="wspace-no">
                                    <!-- <img src="{{transaction.coin_image}}" class="mr-1"/> -->
                                    <span class="text-success" *ngIf="transaction.type == 'depot'"> +{{transaction.amount | currency: 'FCFA': 'symbol': '1.0-0': 'fr'}} </span>
                                    <span class="text-danger" *ngIf="transaction.type == 'retrait'"> -{{transaction.amount | currency: 'FCFA': 'symbol': '1.0-0': 'fr'}} </span>
                                    <span class="text-danger" *ngIf="transaction.type == 'credit'"> -{{transaction.amount | currency: 'FCFA': 'symbol': '1.0-0': 'fr'}} </span>
                                    <span class="text-danger" *ngIf="transaction.type == 'internet'"> -{{transaction.amount | currency: 'FCFA': 'symbol': '1.0-0': 'fr'}} </span>
                                    <span class="text-danger" *ngIf="transaction.type == 'cie'"> -{{transaction.amount | currency: 'FCFA': 'symbol': '1.0-0': 'fr'}} </span>
                                    <span class="text-danger" *ngIf="transaction.type == 'sodeci'"> -{{transaction.amount | currency: 'FCFA': 'symbol': '1.0-0': 'fr'}} </span>
                                </td>
                                <td class="wspace-no">
                                  <!-- <img src="{{transaction.coin_image}}" class="mr-1"/> -->
                                  <span class="text-success" *ngIf="transaction.status == 'payer'"> {{transaction.status}} </span>
                                  <span class="text-danger" *ngIf="transaction.status == 'non payer'"> {{transaction.status}} </span>
                              </td>
                                <!-- <td>
                                    <a routerLink="/caisse/operations" class="btn-link {{transaction.status_class}} float-right"></a>
                                </td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
              </div>
              <div class="col-xl-4 col-xxl-4">
                  <!-- <app-graph-current-statistic></app-graph-current-statistic> -->
                  <div class="card">
                    <div class="card-header border-0 pb-0">
                        <h4 class="mb-0 text-black fs-20">Total dépôt & retraits par opérateur</h4>
                    </div>
                    <div class="card-body p-3 pb-0">
                        <div class="table-responsive">
                            <table class="table text-center bg-warning-hover tr-rounded order-tbl">
                                <thead>
                                    <tr>
                                        <th class="text-left">Réseaux</th>
                                        <th class="text-center">Total dépôts</th>
                                        <th class="text-right">Total retraits</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let operator of operators">
                                        <td class="text-left">{{ operator.name }}</td>
                                        <td> {{ calculateTotalDepositsForOperator(operator.id) | currency:'XOF':'symbol':'1.0-0':'fr' }} </td>
                                        <td class="text-right">{{ calculateTotalRetraitsForOperator(operator.id) | currency:'XOF':'symbol':'1.0-0':'fr' }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!-- <div class="card-footer border-0 p-0 caret">
                        <a [routerLink]="['/admin/coin-details']" class="btn-link"><i class="fa fa-caret-down" aria-hidden="true"></i></a>
                    </div> -->
                </div>
              </div>
              <div class="col-xl-2 col-xxl-4">
                  <!-- <app-graph-current-statistic></app-graph-current-statistic> -->
                  <div class="card">
                    <div class="card-header border-0 pb-0">
                        <h4 class="mb-0 text-black fs-20">Chiffre d'affaires annuel</h4>
                    </div>
                    <div class="card-body p-3 pb-0">
                        <div class="table-responsive">
                            <table class="table text-center bg-warning-hover tr-rounded order-tbl">
                                <thead>
                                    <tr>
                                        <th class="text-left">Année</th>
                                        <th class="text-center">C.A (en FCFA)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let annualRevenue of calculateAnnualTotalRevenueByYear()">
                                        <td class="text-left"> Année {{ annualRevenue.year }}</td>
                                        <td> {{ annualRevenue.revenue | currency:'XOF':'symbol':'1.0-0':'fr' }} </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!-- <div class="card-footer border-0 p-0 caret">
                        <a [routerLink]="['/admin/coin-details']" class="btn-link"><i class="fa fa-caret-down" aria-hidden="true"></i></a>
                    </div> -->
                </div>
              </div>
          </div>
          <div class="row">
              <div class="col-xl-4 col-xxl-12">
                  <!-- <app-quick-trade></app-quick-trade> -->
                  <div class="card">
                    <div class="card-header border-0 pb-0">
                        <h4 class="mb-0 text-black fs-20">Chiffre d'affaires journalier par opérateur</h4>
                    </div>
                    <div class="card-body p-3 pb-0">
                        <div class="table-responsive">
                            <table class="table text-center bg-warning-hover tr-rounded order-tbl">
                                <thead>
                                    <tr>
                                        <th class="text-left">Réseaux</th>
                                        <th class="text-center">Chiffre d'affaires (en FCFA)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let operator of operators">
                                        <td class="text-left">{{ operator.name }}</td>
                                          <td> {{ calculateDailyRevenueForOperator(operator.id) | currency:'XOF':'symbol':'1.0-0':'fr' }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!-- <div class="card-footer border-0 p-0 caret">
                        <a [routerLink]="['/admin/coin-details']" class="btn-link"><i class="fa fa-caret-down" aria-hidden="true"></i></a>
                    </div> -->
                </div>
              </div>
              <div class="col-xl-4 col-xxl-12">
                <!-- <app-quick-trade></app-quick-trade> -->
                <div class="card">
                  <div class="card-header border-0 pb-0">
                      <h4 class="mb-0 text-black fs-20">Chiffre d'affaires hebdomadaire par opérateur</h4>
                  </div>
                  <div class="card-body p-3 pb-0">
                      <div class="table-responsive">
                          <table class="table text-center bg-warning-hover tr-rounded order-tbl">
                              <thead>
                                  <tr>
                                      <th class="text-left">Réseaux</th>
                                      <th class="text-center">Chiffre d'affaires (en FCFA)</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr *ngFor="let operator of operators">
                                      <td class="text-left">{{ operator.name }}</td>
                                        <td> {{ calculateWeeklyRevenueForOperator(operator.id) | currency:'XOF':'symbol':'1.0-0':'fr' }}</td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
                  <!-- <div class="card-footer border-0 p-0 caret">
                      <a [routerLink]="['/admin/coin-details']" class="btn-link"><i class="fa fa-caret-down" aria-hidden="true"></i></a>
                  </div> -->
              </div>
            </div>
              <div class="col-xl-4 col-xxl-12">
                  <div class="row">
                    <div class="card">
                      <div class="card-header border-0 pb-0">
                          <h4 class="mb-0 text-black fs-20">Chiffre d'affaires mensuel par opérateur</h4>
                      </div>
                      <div class="card-body p-3 pb-0">
                          <div class="table-responsive">
                              <table class="table text-center bg-warning-hover tr-rounded order-tbl">
                                  <thead>
                                      <tr>
                                          <th class="text-left">Réseaux</th>
                                          <th class="text-center">Chiffre d'affaires (en FCFA)</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr *ngFor="let operator of operators">
                                          <td class="text-left">{{ operator.name }}</td>
                                            <td> {{ calculateMonthlyRevenueForOperator(operator.id) | currency:'XOF':'symbol':'1.0-0':'fr' }}</td>
                                      </tr>
                                  </tbody>
                              </table>
                          </div>
                      </div>
                      <!-- <div class="card-footer border-0 p-0 caret">
                          <a [routerLink]="['/admin/coin-details']" class="btn-link"><i class="fa fa-caret-down" aria-hidden="true"></i></a>
                      </div> -->
                  </div>
                      <div class="col-xl-12">
                         <!-- <app-recent-trading-activities></app-recent-trading-activities> -->
                      </div>
                      <div class="col-xl-12 mt-2">
                          <!-- <app-quick-transfer-crousal [data]="quickTransfer"></app-quick-transfer-crousal> -->
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <!--**********************************
      Content body end
  ***********************************-->
