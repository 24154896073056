import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';
import firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-email-verified',
  templateUrl: './email-verified.component.html',
  styleUrls: ['./email-verified.component.css']
})
export class EmailVerifiedComponent implements OnInit {
  email: string
  isAuth: boolean;
  user: User;
  islogin = false;
  id ='';
  constructor(
    private route: ActivatedRoute,
    public userService: UserService,
    private afa: AngularFireAuth


  ) { }

  ngOnInit(): void {
   
  }

}
