import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Operator } from 'src/app/models/operator';
import { OperatorService } from 'src/app/services/operator.service';

@Component({
  selector: 'app-operator-form',
  templateUrl: './operator-form.component.html',
  styleUrls: ['./operator-form.component.css']
})
export class OperatorFormComponent implements OnInit {

  operatorForm:FormGroup;
  errorMsg: string;
  operator : Operator;
  photoUploading = false;
  photoUploaded = false;
  photoUrl: string;

  constructor(private formBuilder: FormBuilder,
              private operatorService: OperatorService,
              private router: Router,
              private modalService: NgbModal,
              private toastr: ToastrService
    ) {
      //  this.operator = new Operator('','','');
    }

  ngOnInit(): void {
    this.initOperatorForm();
  }

  initOperatorForm(){
    this.operatorForm = this.formBuilder.group({
      name: ['',[Validators.required]],
      balance: ['',[Validators.required, Validators.min(5000),Validators.pattern(/^[0-9]*$/)]],
      operatorPhone: ['',[Validators.required]],
      photo: ['']
    })
  }

  onSubmitOperatorForm(){
    this.errorMsg = null;
    const operatorData: Operator = this.operatorForm.value;
    operatorData.photo = this.photoUrl ? this.photoUrl : '';
      // operatorData.balance = this.operatorForm.value.replace(/\s/g, '');
    this.operatorService.CreateOperator(operatorData)
                      .then(
                        () => {
                          this.router.navigate(['caisse/operators']);
                          console.log('Creation avec Success');
                          this.toastr.success('Operateur enregistrée avec succès.');

                        })
                      .catch(
                        (errMsg) => {
                          this.errorMsg = errMsg
                        })

  }

  onUploadFile(event){
    this.photoUploading = true;

    this.operatorService.uploadFile(event.target.files[0]).then(
      (url: string) => {
        if (this.photoUrl && this.photoUrl !== '') {
          this.operatorService.removeFile(this.photoUrl);
        }
        this.photoUrl =  url;
        this.photoUploading = false;
        this.photoUploaded = true;
        setTimeout(
          () => {
            this.photoUploaded = true;
          }, 5000
        );
      }
    )
    console.log(event);

  }
  
  open(modelId:any) {
		this.modalService.open(modelId);
	}
  resetForm(){
    this.photoUrl = '';
  }


}
