import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { User } from '../models/user';
import { UserService } from '../services/user.service';
import firebase from 'firebase/app';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit, OnDestroy {

  tokenSub: Subscription;
  isAdminSub: Subscription;
  isAnonymous = false;
  isAdmin : boolean;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private authService: AuthService,
    private router: Router
    ) { }

  ngOnInit(): void {
    this.tokenSub = this.authService.getToken().subscribe(
      token => this.isAnonymous = !token
    );
    this.isAdminSub = this.authService.isAdmin.subscribe(
      (isAdmin: boolean) => this.isAdmin = isAdmin
    );
    this.authService.isCurrentUserAdmin();

  }


  onClickLogout(){
    this.authService.signOutUser();
  }


  ngOnDestroy(): void {
      this.tokenSub.unsubscribe();
      this.isAdminSub.unsubscribe();
  }

}
