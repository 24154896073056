import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { User } from '../models/user';
import { UserService } from '../services/user.service';
import firebase from 'firebase/app';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {

  users: User;
  usersSubscription : Subscription;
  user: any;
  isAuth = false;
  constructor( private userService: UserService) { }

  ngOnInit(): void {
    // this.userService.getUser().subscribe(
    //  ( user )=> {
    //     this.user = user;
    //     console.log(user);

    //   }
    // )

    // firebase.auth().onAuthStateChanged(
    //   (user) => {
    //     if (user) {
    //       this.isAuth = true;
    //       console.log(this.isAuth);
    //       console.log(this.userService.getUser());
    //       this.userService.getUser().then(
    //         (user: User) => {
    //           this.user = user;
    //         }
    //       ).catch(
    //         (error)=> {
    //           console.error(error);
    //         }
    //       );


    //     } else {
    //       this.isAuth = false;
    //       console.log(this.isAuth);

    //     }
    //   }
    // );

  //  this.usersSubscription= this.userService.usersSubject.subscribe(
  //     (users: User[]) => {
  //       this.users = users;
  //       console.log(users);

  //     });
    // this.userService.getUsers();
// this.usersService.getUsers()
// this.usersService.emitUsers()
// console.log(this.usersService.getUsers());
// this.userService.usersSubject.subscribe(
//   (data:any) => {
//     this.users = data;
//     console.log(data);

//   }


// );
// this.userService.getUsers();
// this.userService.emitUsers();
// console.log(this.userService.getUsers());

  // this.userService.getUsers().then(
  //   (value) => {
  // console.log(value);
  //  });




  }
  images = [
    'assets/images/big/img1.jpg',
    'assets/images/big/img2.jpg',
    'assets/images/big/img3.jpg',
  ];


  ngOnDestroy(): void {
      this.usersSubscription.unsubscribe();
  }

}
