import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Car } from 'src/app/models/car';
import { Driver } from 'src/app/models/driver';
import { CarService } from 'src/app/services/car.service';
import { DriverService } from 'src/app/services/driver.service';

@Component({
  selector: 'app-car-form',
  templateUrl: './car-form.component.html',
  styleUrls: ['./car-form.component.css']
})
export class CarFormComponent implements OnInit {

  carForm: FormGroup;
  cars: Car[] = [];
  availableDrivers: Driver[] = []; // Tableau des chauffeurs disponibles
  errorMsg: string;

  constructor(
    private fb: FormBuilder,
    private carService: CarService,
    private driverService: DriverService,
    private router: Router,
    private toastr: ToastrService,
  ) {

  }

  ngOnInit(): void {
    this.initCarForm();
    this.getDrivers();
    this.loadCars();
    this.loadAvailableDrivers();


  }

  getDrivers(){
    this.driverService.getDrivers().subscribe((data) => {
      this.availableDrivers = data;
    });
  }
  loadCars() {
    this.carService.getCars().subscribe((data) => {
      this.cars = data;
      this.loadAvailableDrivers();
    });
  }



  initCarForm(){
    this.carForm = this.fb.group({
      brand: ['', Validators.required],
      registerNumber: ['', Validators.required],
      indexCar: ['', Validators.required],
      date: [''],
      driver1: [''],
      driver2: [''],
    });
  }
  loadAvailableDrivers() {
    this.driverService.getDrivers().subscribe((data) => {
      this.availableDrivers = data.filter((driver) => !this.isDriverAssociated(driver.id));
    });
  }

  isDriverAssociated(driverId: string): boolean {
    // Vérifie si le chauffeur est déjà associé à un véhicule
    for (const car of this.cars) {
      if (car.driverIds.includes(driverId)) {
        return true;
      }
    }
    return false;
  }



  onSubmitCarForm() {
    if (this.carForm.valid) {
      const carData = this.carForm.value;
      carData.date = new Date();
      const driverIds: string[] = [carData.driver1, carData.driver2].filter(Boolean);
      carData.driverIds = driverIds;
      this.carService.addCar(carData).then(() => {

        this.toastr.success('Le nom du chauffeur à été enregistrer avec succès.');
        this.router.navigate(['caisse/cars']);
        this.loadCars();
        this.loadAvailableDrivers();

      }).catch(
        (errMsg) => {
          this.errorMsg = errMsg
        });
    }
  }




}
