import { Component, OnInit } from '@angular/core';
import { Car } from 'src/app/models/car';
import { DailyPayment } from 'src/app/models/daily-payment';
import { Driver } from 'src/app/models/driver';
import { DailyPaymentService } from 'src/app/services/daily-payment.service';
import { DriverService } from 'src/app/services/driver.service';

@Component({
  selector: 'app-daily-pointage',
  templateUrl: './daily-pointage.component.html',
  styleUrls: ['./daily-pointage.component.css']
})
export class DailyPointageComponent implements OnInit {
  cars: Car[] = [];
  availableDrivers: Driver[] = [];
  availableCars: Car[] = [];
  searchDailyPayments :DailyPayment[]= [];
  searchDrivers: Driver[]=[];
  dailyPayments: DailyPayment[] = [];
  driverDebtTotal: { [driverId: number]: number } = {};

  totalLength: number;
  page : number = 1;
  test:string;

  dailyRevenueCommission: number =0;
  weeklyRevenueCommission: number =0;
  monthlyRevenueCommission: number =0;

  monthlyRevenueDebt: number =0;
  dailyRevenueDebt: number =0;
  weeklyRevenueDebt: number =0;

  today: Date;

  constructor( private driverService : DriverService,
    private dailyPaymentService: DailyPaymentService) { }

  ngOnInit(): void {
    this.loadAvailableDrivers();
    this.loadDailypayment();
    this.today = new Date();
    this.calculateDailyRevenueCommission();
    this.calculateWeeklyRevenueCommission();
    this.calculateMonthlyRevenueCommission();
    this.calculateDailyRevenueDebt();
    this.calculateWeeklyRevenueDebt();
    this.calculateMonthlyRevenueDebt();

  }


  calculateDailyRevenueCommission():any {
    this.dailyPaymentService.getDailyRevenueCommission(this.today)
      .then((dailyRevenueCommission) =>{
        this.dailyRevenueCommission = dailyRevenueCommission;

      });
  }
  calculateDailyRevenueDebt():any {
    this.dailyPaymentService.getDailyRevenueDebt(this.today)
      .then((dailyRevenueDebt) =>{
        this.dailyRevenueDebt = dailyRevenueDebt;

      });
  }
  calculateWeeklyRevenueCommission():any {
    this.dailyPaymentService.getWeeklyRevenueCommission()
      .then((weeklyRevenueCommission) =>{
        this.weeklyRevenueCommission = weeklyRevenueCommission;

      });
  }
  calculateWeeklyRevenueDebt():any {
    this.dailyPaymentService.getWeeklyRevenueDebt()
      .then((weeklyRevenueDebt) =>{
        this.weeklyRevenueDebt = weeklyRevenueDebt;

      });
  }
  calculateMonthlyRevenueCommission():any {
    this.dailyPaymentService.getWeeklyRevenueCommission()
      .then((monthlyRevenueCommission) =>{
        this.monthlyRevenueCommission = monthlyRevenueCommission;

      });
  }
  calculateMonthlyRevenueDebt():any {
    this.dailyPaymentService.getMonthlyRevenueDebt()
      .then((monthlyRevenueDebt) =>{
        this.monthlyRevenueDebt = monthlyRevenueDebt;

      });
  }
  loadAvailableDrivers(){
    this.driverService.getDrivers().subscribe((data)=>{
      this.searchDrivers =  this.availableDrivers = data;
    })
  }
  loadDailypayment(): void {
    this.dailyPaymentService.getAlldailyPayments().then(data => {
     this.searchDailyPayments = this.searchDailyPayments = this.dailyPayments = data;
       this.calculateDriverDebtTotal();

    });
  }
  calculateDriverDebtTotal() {
    this.driverDebtTotal = {};

    for (const payment of this.dailyPayments) {
      const driverId = payment.driverId;
      const dailyEarnings = payment.amount;
      const dailyDebt = Math.max(0, 26000 - dailyEarnings);

      if (!this.driverDebtTotal[driverId]) {
        this.driverDebtTotal[driverId] = 0;
      }

      this.driverDebtTotal[driverId] += dailyDebt;
    }
  }

  calculateDriverTotalDebt(driverId: string): number {
    let totalDebt = 0;

    for (const payment of this.dailyPayments) {
      if (payment.driverId === driverId) {
        const dailyEarnings = payment.amount;
        const dailyDebt = Math.max(0, 26000 - dailyEarnings);
        totalDebt += dailyDebt;
      }
    }

    return totalDebt;
  }

  searchDailyPayment(query){}

  calculateDriverTotalCommission(driverId: string){
    let totalCommission = 0;

    for (const payment of this.dailyPayments) {
      if (payment.driverId === driverId) {
        totalCommission += this.calculateCommission(payment.amount);
      }
    }

    return totalCommission;
  }


  calculateCommission(paymentAmount: any): number {
    const roundedAmount = Number(paymentAmount); // Convertir en nombre
    if (roundedAmount >= 25000 && roundedAmount <= 25999) {
      return 1000;
    } else if (roundedAmount === 26000) {
      return 2000;
    } else {
      return 0;
    }
  }
  getDriverName(driverId: string): string{

    const driver = this.availableDrivers.find((d) => d.id === driverId);
    return driver ? driver.name : 'Inconnu';
}


calculateTotalCommissionsForDriver(driverId: string): number {
  const driverCommissions = this.dailyPayments.filter(dailyPayment => dailyPayment.driverId === driverId &&
    (dailyPayment.commission));
  return driverCommissions.reduce((total, dailyPayment) => total + parseFloat(dailyPayment.commission.toString()), 0);
}
calculateTotalDebtsForDriver(driverId: string): number {
  const driverDebts = this.dailyPayments.filter(dailyPayment => dailyPayment.driverId === driverId &&
    (dailyPayment.debtAmount));
  return driverDebts.reduce((total, dailyPayment) => total + parseFloat(dailyPayment.debtAmount.toString()), 0);
}

searchDriverCommissionDebt(query: string){
  this.searchDrivers = (query) ? this.availableDrivers.filter(driver => driver.driverPhone.toLowerCase().includes(query.toLowerCase())
  || driver.name.toLowerCase().includes(query.toLowerCase())
  ): this.availableDrivers;
}

}
