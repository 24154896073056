 <!--**********************************
        Content body start
    ***********************************-->
    <div class="content-body">
      <div class="container-fluid"  *ngIf="operator">
          <div class="form-head mb-lg-5 mb-3 d-flex flex-wrap align-items-center">
              <h2 class="font-w600 mb-3 mr-auto ">Approvisionnement d'un operateur</h2>
              <a href="javascript:void(0);" class="btn btn-secondary btn-xxs text-white mb-2 m-2" (click)="openRechargeModal()"> <i class="fa fa-money m-2" aria-hidden="true"></i>Recharger UVE {{operator.name}}</a>
              <a href="javascript:void(0);" class="btn btn-secondary btn-xxs text-white mb-2" (click)="openRetraitModal()"> <i class="fa fa-money m-2" aria-hidden="true"></i>Retrait UVE {{operator.name}}</a>
          </div>
          <div class="row sp-sm-15">
            <div class="card card-coin">
              <div class="card-body text-center">
                <!-- <img src="{{item.image}}" class="mb-sm-3 mb-2 currency-icon"/> -->
                  <h4> {{operator.name}}</h4>
                  <h3 class="text-black mb-2 font-w500">{{operator.balance | currency: 'FCFA': 'symbol': '1.0-0': 'fr'}}</h3>
                  <p class="mb-0 fs-14">
                      <!-- <span class="text-success mr-1"> UVE {{operator.name}}</span> -->
                      <button class="btn btn-secondary btn-xxs" (click)="openRechargeModal()"> <i class="fa fa-money m-2" aria-hidden="true"></i>Recharger UVE {{operator.name}}</button>
                  </p>
              </div>
          </div>

          </div>
          <div class="row">
              <div class="col-xl-4 col-xxl-8">
                  <!-- <app-graph-market-overview></app-graph-market-overview> -->
                  <div class="card">
                    <div class="card-header border-0 pb-0">
                        <h3 class="mb-0 text-danger font-w700 fs-20" >HISTORIQUE DE RECHARGEMENT {{ operator.name}} </h3>
                    </div>
                    <div class="card-body p-3">
                        <div class="table-responsive">
                            <table class="table text-center bg-warning-hover tr-rounded order-tbl">
                                <thead>
                                    <tr>
                                        <th>Date de recharger</th>
                                        <th>Montant</th>
                                        <th>Numéro</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let recharge of operator.recharges">
                                      <td>{{ recharge.date.toDate() | date: 'dd MMM yyy HH:mm':'fr' }}</td>
                                        <td class="text-success"> +{{recharge.amount | currency: 'FCFA': 'symbol': '1.0-0': 'fr'}}</td>
                                        <td>{{operator.operatorPhone}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
              </div>
              <div class="col-xl-4 col-xxl-4">
                  <!-- <app-graph-current-statistic></app-graph-current-statistic> -->
                  <div class="card">
                    <div class="card-header border-0 pb-0">
                        <h3 class="mb-0 text-danger font-w700 fs-20" >HISTORIQUE DE RETRAITS {{ operator.name}} </h3>
                    </div>
                    <div class="card-body p-3">
                        <div class="table-responsive">
                            <table class="table text-center bg-warning-hover tr-rounded order-tbl">
                                <thead>
                                    <tr>
                                        <th>Date de retraits</th>
                                        <th>Montant</th>
                                        <th>Numéro</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let retrait of operator.retraits">
                                      <td>{{ retrait.date.toDate() | date: 'dd MMM yyy HH:mm':'fr' }}</td>
                                        <td class="text-danger"> -{{retrait.amount | currency: 'FCFA': 'symbol': '1.0-0': 'fr'}}</td>
                                        <td>{{operator.operatorPhone}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
              </div>
          </div>
          <div class="row">
              <div class="col-xl-6 col-xxl-12">
                  <!-- <app-quick-trade></app-quick-trade> -->
              </div>
              <div class="col-xl-6 col-xxl-12">
                  <div class="row">
                      <!-- <div class="col-sm-6" *ngFor="let item of cryptoCards">
                          <div class="card-bx stacked card">
                              <img class="bg-img" src="{{item.image}}" alt="">
                              <div class="card-info">
                                  <p class="mb-1 text-white fs-14">Main Balance</p>
                                  <div class="d-flex justify-content-between align-items-start">
                                      <h2 class="num-text text-white mb-5 font-w600">{{item.price}}</h2>
                                      <img src="{{item.icon_image}}" alt="">
                                  </div>
                                  <div class="d-flex">
                                      <div class="mr-4 text-white">
                                          <p class="fs-12 mb-1 op6">VALID THRU</p>
                                          <span>{{item.valid_thrugh}}</span>
                                      </div>
                                      <div class="text-white">
                                          <p class="fs-12 mb-1 op6">CARD HOLDER</p>
                                          <span>{{item.name}}</span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div> -->
                      <div class="col-xl-12">
                         <!-- <app-recent-trading-activities></app-recent-trading-activities> -->
                      </div>
                      <div class="col-xl-12 mt-2">
                          <!-- <app-quick-transfer-crousal [data]="quickTransfer"></app-quick-transfer-crousal> -->
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <!--**********************************
      Content body end
  ***********************************-->
