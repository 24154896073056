<div class="authincation h-100">
  <div class="container vh-100">
      <div class="row justify-content-center h-100 align-items-center">
          <div class="col-lg-6 col-md-8">
              <div class="authincation-content">
                  <div class="row no-gutters">
                      <div class="col-xl-12">
                          <div class="auth-form">
            <!-- <div class="text-center mb-3">
              <a [routerLink]="['/admin/index']"><img src="assets/images/logo-full.png" alt=""></a>
            </div> -->
                              <h4 class="text-center mb-4">Mot de passe oublié</h4>
                              <form [formGroup]="resetPawsswordForm" (ngSubmit)="onSubmitResetpassword()">
                                <div *ngIf="errorMsg" class="alert alert-danger">{{errorMsg}}</div>
                                <div class="form-group">
                                  <div class="alert alert-danger"
                                      *ngIf="(resetPawsswordForm.controls.email.dirty || resetPawsswordForm.controls.email.touched)
                                              && resetPawsswordForm.controls.email.invalid
                                              && resetPawsswordForm.controls.email.errors.required">
                                      L'email est obligatoire.
                                  </div>
                                    <label class="mb-1"><strong>Email</strong></label>
                                    <input type="email" class="form-control" placeholder="hello@example.com"  formControlName="email">
                                </div>
                                  <div class="text-center">
                                      <button type="submit" class="btn btn-primary btn-block" [disabled]="resetPawsswordForm.invalid">Envoyer</button>
                                  </div>
                              </form>
                          </div>
                          <div class="new-account mt-3 text-center">
                            <p>Allez a la page de connexion <a class="text-primary" [routerLink]="['/auth/login']">Se connecter</a></p>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
