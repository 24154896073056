import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Car } from 'src/app/models/car';
import { DailyPayment } from 'src/app/models/daily-payment';
import { Driver } from 'src/app/models/driver';
import { User } from 'src/app/models/user';
import { CarService } from 'src/app/services/car.service';
import { DailyPaymentService } from 'src/app/services/daily-payment.service';
import { DriverService } from 'src/app/services/driver.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-daily-versement',
  templateUrl: './daily-versement.component.html',
  styleUrls: ['./daily-versement.component.css']
})
export class DailyVersementComponent implements OnInit {

  versementForm: FormGroup;
  availableCars: Car[] = [];
  availableDrivers: Driver[] = [];
  selectedCar: Car | null = null;
  availableDriversForSelectedCar: Driver[] = [];
  driverDebt: number = 0;
  dailyPayments: DailyPayment[] =[];
  errorMsg: string;
  commission: number =0;
  isAuth = false;
  user: User;

  constructor(
    private fb: FormBuilder,
    private carService: CarService,
    private driverService: DriverService,
    private router : Router,
    private dailyPaymentService: DailyPaymentService,
    private toastr: ToastrService,
    public userService: UserService,
    private afa: AngularFireAuth
  ) { }

  ngOnInit(): void {
    this.initVersementForm();
    this.loadAvailableCars();
    this.loadAvailableDrivers();
    this.loadPayments();
    this.getUser();

  }
  getUser(){
    this.afa.authState.subscribe(
      (user) =>{
        if (user) {
          this.isAuth = true;
          console.log(this.isAuth);
          this.userService.getUserById(user.uid).subscribe(
            (userData) => {
              this.user = userData;
            })
        }else{
          this.isAuth = false;
         console.log(this.isAuth);

        }
      });
  }

  initVersementForm(){
    this.versementForm = this.fb.group({
      carId: ['', Validators.required],
      driverId: ['', Validators.required],
      amount: ['', [Validators.required,Validators.max(26000),Validators.pattern(/^[0-9]*$/)]],
      date: [''],
    });
  }

  loadAvailableCars() {
    this.carService.getCars().subscribe((data) => {
      this.availableCars = data;
      console.log(data);


    });
  }

  loadAvailableDrivers() {
    this.driverService.getDrivers().subscribe((data) => {
      this.availableDrivers = data;
    });
  }
  onCarSelectionChange() {
    const selectedCarId = this.versementForm.get('carId').value;
    if (selectedCarId) {
      this.selectedCar = this.availableCars.find((car) => car.id === selectedCarId);

      // Débogage
      console.log('Selected Car:', this.selectedCar);

      if (this.selectedCar) {
        // Filtrer les chauffeurs disponibles pour le véhicule sélectionné
        this.availableDriversForSelectedCar = this.availableDrivers.filter((driver) =>
          this.selectedCar.driverIds.includes(driver.id)
        );

        // Débogage
        console.log('Available Drivers for Selected Car:', this.availableDriversForSelectedCar);
      } else {
        this.availableDriversForSelectedCar = [];
      }
    } else {
      this.selectedCar = null;
      this.availableDriversForSelectedCar = [];
    }
  }
  calculateDriverDebt(paymentAmount: number): number {
    const dailyDebt = Math.max(0, 26000 - paymentAmount);
    return dailyDebt;
  }
  loadPayments() {
    this.dailyPaymentService.getDailyPayments().subscribe((data) => {
      this.dailyPayments = data;
    });
  }

  getCarName(carId: string): string{

    const car = this.availableCars.find((car) => car.id === carId);
    return car ? car.brand : 'Inconnu';



}
  getDriverName(driverId: string): string{

    const driver = this.availableDrivers.find((d) => d.id === driverId);
    return driver ? driver.name : 'Inconnu';



}

calculateCommission(paymentAmount: any): number {
  const roundedAmount = Number(paymentAmount); // Convertir en nombre
  if (roundedAmount >= 25000 && roundedAmount <= 25999) {
    return this.commission= 1000;
  } else if (roundedAmount === 26000) {
    return this.commission= 2000;
  } else {
    return this.commission= 0;
  }
}


  onSubmitVersementForm() {
    if (this.versementForm.valid) {
      const versementData = this.versementForm.value;


      // Générez automatiquement la date du versement
      versementData.date = new Date();
      versementData.userName = this.user.firstName;
      const dailyEarnings = versementData.amount;
      const dailDebt = Math.max(0, 26000 - dailyEarnings);
      versementData.debtAmount = dailDebt;

      versementData.commission = this.calculateCommission(dailyEarnings);



      // Appelez le service pour ajouter un versement
      this.dailyPaymentService.addDailyPayment(versementData).then(() => {
        console.log('Enregister!!!!!!!!');
        this.toastr.success('Le Versement à été enregistrer avec succès.');
        this.router.navigate(['caisse/cars/versements']);

      }).catch(
        (errMsg) => {
          this.errorMsg = errMsg
        });
    }
  }


}







