 <!--**********************************
        Content body start
    ***********************************-->
    <div class="content-body">
      <div class="container-fluid">
          <div class="form-head mb-lg-5 mb-3 d-flex flex-wrap align-items-center">
              <h2 class="font-w600 mb-3 mr-auto ">La liste des transactions </h2>
              <a routerLink="/caisse/expenses/new" class="btn btn-secondary text-white btn-sm mb-2 m-2"><i class="fa fa-plus m-2" aria-hidden="true"></i>Enregistrer une dépense </a>
              <a routerLink="/caisse/operations/new" class="btn btn-secondary text-white btn-sm mb-2"><i class="fa fa-plus m-2" aria-hidden="true"></i>Enregistrer une transaction</a>
          </div>
          <div class="row sp-sm-15">
              <div class="col-xl-2 col-3 m-t35">
                  <div class="card ">
                      <div class="card-body text-center">
                        <!-- <img src="{{item.image}}" class="mb-sm-3 mb-2 currency-icon"/> -->
                          <h4>Chiffre d'affaires journalier</h4>
                          <h3 class="text-black mb-1 font-w500" ><span> {{ calculateDailyTotalRevenue() | currency:'XOF':'symbol':'1.0-0':'fr' }} </span> </h3>
                      </div>
                  </div>
              </div>
              <div class="col-xl-2 col-3 m-t35">
                  <div class="card ">
                      <div class="card-body text-center">
                        <!-- <img src="{{item.image}}" class="mb-sm-3 mb-2 currency-icon"/> -->
                          <h4>Chiffre d'affaires hebdomadaire</h4>
                          <h3 class="text-black mb-1 font-w500" ><span> {{ calculateWeeklyTotalRevenue() | currency:'XOF':'symbol':'1.0-0':'fr' }} </span> </h3>
                      </div>
                  </div>
              </div>
              <div class="col-xl-2 col-3 m-t35">
                  <div class="card ">
                      <div class="card-body text-center">
                        <!-- <img src="{{item.image}}" class="mb-sm-3 mb-2 currency-icon"/> -->
                          <h4>Chiffre d'affaires mensuel</h4>
                          <h3 class="text-black mb-1 font-w500" ><span> {{ calculateMonthlyTotalRevenue() | currency:'XOF':'symbol':'1.0-0':'fr' }} </span> </h3>
                      </div>
                  </div>
              </div>
              <div class="col-xl-2 col-3 m-t35">
                  <div class="card ">
                      <div class="card-body text-center">
                        <!-- <img src="{{item.image}}" class="mb-sm-3 mb-2 currency-icon"/> -->
                          <h4>Chiffre d'affaires annuel</h4>
                          <h3 class="text-black mb-1 font-w500" ><span> {{ calculateMonthlyTotalRevenue() | currency:'XOF':'symbol':'1.0-0':'fr' }} </span> </h3>
                      </div>
                  </div>
              </div>
              <div class="col-xl-2 col-3 m-t35">
                  <div class="card ">
                      <div class="card-body text-center">
                        <!-- <img src="{{item.image}}" class="mb-sm-3 mb-2 currency-icon"/> -->
                          <h4>Total des dépôts</h4>
                          <h3 class="text-black mb-1 font-w500" ><span>  {{ calculateTotalDeposits() | currency:'XOF':'symbol':'1.0-0':'fr' }} </span> </h3>
                      </div>
                  </div>
              </div>
              <div class="col-xl-2 col-3 m-t35">
                  <div class="card ">
                      <div class="card-body text-center">
                        <!-- <img src="{{item.image}}" class="mb-sm-3 mb-2 currency-icon"/> -->
                          <h4>Total des retraits</h4>
                          <h3 class="text-black mb-1 font-w500" ><span> {{ calculateTotalWithdrawals() | currency:'XOF':'symbol':'1.0-0':'fr' }} </span> </h3>
                      </div>
                  </div>
              </div>

          </div>
          <div class="row sp-sm-15">
              <div class="col-xl-2 col-3 m-t35"  *ngFor="let operator of operators">
                  <div class="card ">
                      <div class="card-body text-center">
                        <img [src]="operator.photo" width="50" class="mb-sm-3 mb-2 currency-icon"/>
                          <h4 *ngIf="operator.balance >= 0">{{operator.name}}</h4>
                          <h4 *ngIf="operator.balance <0"> <span class="text-danger">{{operator.name}}</span></h4>
                          <h3 class="text-black mb-1 font-w500" *ngIf="operator.balance <0"><span class="text-danger">{{operator.balance | currency: 'FCFA': 'symbol': '1.0-0': 'fr'}} </span> </h3>
                          <h3 class="text-black mb-1 font-w500" *ngIf="operator.balance >= 0"><span >{{operator.balance | currency: 'FCFA': 'symbol': '1.0-0': 'fr'}}</span></h3>
                          <p class="mb-0 fs-14">
                              <!-- <span class="text-success mr-1" *ngIf="operator.balance >= 0"> UVE {{operator.name}}</span> -->
                              <span class="text-success mr-1"> Total dépôts : {{ calculateDailyDepositRevenueForOperator(operator.id) | currency:'XOF':'symbol':'1.0-0':'fr' }}</span>

                          </p>
                          <p class="mb-0 fs-14">
                              <span class="text-success mr-1">Total retraits : {{  calculateDailyRetraitRevenueForOperator(operator.id) | currency:'XOF':'symbol':'1.0-0':'fr' }}</span>

                          </p>
                          <p class="mb-0 fs-14">
                              <span class="text-danger mr-1"*ngIf="operator.balance <0">SOLDE INSUFFISANT</span>
                          </p>
                      </div>
                  </div>
              </div>

          </div>
          <div class="row">
              <div class="col-xl-9 col-xxl-8">
                <div class="table-responsive table-hover fs-14">
                  <div class="mb-3">
                    <div class="input-group right d-lg-inline-flex d-none">
                        <input type="text" #query (keyup)="search(query.value)"  class="form-control" placeholder="Recherche une transaction par Numéro, Status, Dépôt ou Rétrait ">
                        <div class="input-group-append">
                            <span class="input-group-text"><i class="flaticon-381-search-2"></i></span>
                        </div>
                    </div>
                </div>
                <div class="table-responsive table-hover fs-14">
                    <table class="table display mb-4 dataTablesCard dataTable  short-one card-table text-black" id="example5">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Date & Heures</th>
                                <th>Numéros</th>
                                <th>Réseaux</th>
                                <th>Types</th>
                                <th>Montants</th>
                                <th>Status</th>
                                <th>
                                  Actions
                                  <button  class="btn btn-outline-primary btn-xxs" (click)="exportToExcel()">Exporter vers Excel</button>
                                </th>
                                <!-- <th>
                                  <button  class="btn btn-outline-primary btn-xxs" (click)="exportToExcel()">Exporter vers Excel</button>
                                </th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let transaction of searchTransactions | paginate: { itemsPerPage: 15, currentPage: page, totalItems :totalLength } ; let i = index">
                                <!-- <td class="pr-0">
                                    <img class="ic-icon" src="{{transaction.image}}" />
                                </td> -->
                                  <td>{{i +1}}</td>
                                  <td>
                                    <span class="text-black font-w600">{{transaction.date.toDate() | date: 'dd MMM yyy HH:mm':'fr' }}</span>
                                </td>
                                  <td>{{transaction.numberPhone}}</td>
                                  <td>
                                    <div class="d-flex align-items-center">
                                      <img [src]="getoperatorPhoto(transaction.operatorId)" class="rounded-circle mr-2 width20 height20" alt=""/>
                                      <span>{{getoperatorName(transaction.operatorId)}}</span>

                                    </div>
                                    <div *ngIf="transaction.userName" class="header-info">
                                      <small>Éditer par {{transaction.userName}}</small>
                                    </div>

                                  </td>
                                  <td>
                                      <div class="d-flex align-items-center">
                                          <!-- <img src="{{transaction.user_image}}"  class="rounded-circle mr-2 width36 height36" alt=""> -->
                                          <span>{{transaction.type}}</span>
                                      </div>
                                  </td>
                                  <td class="wspace-no">
                                      <!-- <img src="{{transaction.coin_image}}" class="mr-1"/> -->
                                      <span class="text-success" *ngIf="transaction.type == 'depot'"> +{{transaction.amount | currency: 'FCFA': 'symbol': '1.0-0': 'fr'}} </span>
                                      <span class="text-danger" *ngIf="transaction.type == 'retrait'"> -{{transaction.amount | currency: 'FCFA': 'symbol': '1.0-0': 'fr'}} </span>
                                      <span class="text-success" *ngIf="transaction.type == 'credit'"> +{{transaction.amount | currency: 'FCFA': 'symbol': '1.0-0': 'fr'}} </span>
                                      <span class="text-success" *ngIf="transaction.type == 'internet'"> +{{transaction.amount | currency: 'FCFA': 'symbol': '1.0-0': 'fr'}} </span>
                                      <span class="text-success" *ngIf="transaction.type == 'cie'"> +{{transaction.amount | currency: 'FCFA': 'symbol': '1.0-0': 'fr'}} </span>
                                      <span class="text-success" *ngIf="transaction.type == 'sodeci'"> +{{transaction.amount | currency: 'FCFA': 'symbol': '1.0-0': 'fr'}} </span>
                                      <span class="text-success" *ngIf="transaction.type == 'carte UBA'"> +{{transaction.amount | currency: 'FCFA': 'symbol': '1.0-0': 'fr'}} </span>
                                      <span class="text-success" *ngIf="transaction.type == 'inscription'"> +{{transaction.amount | currency: 'FCFA': 'symbol': '1.0-0': 'fr'}} </span>
                                      <span class="text-success" *ngIf="transaction.type == 'starTimes'"> +{{transaction.amount | currency: 'FCFA': 'symbol': '1.0-0': 'fr'}} </span>
                                      <span class="text-success" *ngIf="transaction.type == 'canal'"> +{{transaction.amount | currency: 'FCFA': 'symbol': '1.0-0': 'fr'}} </span>
                                  </td>
                                  <td class="wspace-no">
                                      <!-- <img src="{{transaction.coin_image}}" class="mr-1"/> -->
                                      <span class="text-success" *ngIf="transaction.status == 'payer'"> {{transaction.status}} </span>
                                      <span class="text-danger" *ngIf="transaction.status == 'non payer'"> {{transaction.status}} </span>
                                  </td>
                                <td>
                                  <a routerLink="/caisse/operations/edit/{{transaction.id}}" class="btn btn-outline-primary btn-xxs  m-1"><i class="fa fa-pencil-square-o m-1" aria-hidden="true"></i></a>
                                  <a  class="btn btn-outline-primary btn-xxs  m-1" (click)="printTransaction(transaction)"><i class="fa fa-print" aria-hidden="true"></i></a>
                                  <a (click)=" onClickDeleteTransaction(transaction.id)" class="btn btn-outline-danger btn-xxs  m-1"><i class="fa fa-trash-o m-1" aria-hidden="true"></i></a>
                                </td>
                                <!-- <td>
                                    <a href="javascript:void(0);" class="btn-link {{transaction.status_class}} float-right">{{transaction.status}}</a>
                                </td> -->
                            </tr>
                        </tbody>
                    </table>

                    <div class="d-flex justify-content-between p-2">
                      <pagination-controls class="mr-3" (pageChange)="page = $event"></pagination-controls>
                        <!-- <ngb-pagination class="mr-3" [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" (pageChange)="updateTransactionListing()">
                        </ngb-pagination> -->

                        <!-- <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="updateTransactionListing()">
                            <option [ngValue]="10">10 transactions par page</option>
                            <option [ngValue]="20">20 transactions par page</option>
                            <option [ngValue]="30">30 transactions par page</option>
                            <option [ngValue]="50">50 transactions par page</option>
                            <option [ngValue]="100">100 transactions par page</option>
                        </select>  -->
                    </div>
                </div>
              </div>
                  <!-- <app-graph-market-overview></app-graph-market-overview> -->
              </div>
              <div class="col-xl-3 col-xxl-4">
                <!-- <div class="card card-coin">
                  <div class="card-body text-center">
                    <img src="" class="mb-sm-3 mb-2 currency-icon"/>
                      <h2 class="text-black mb-2 font-w600">{{cashBalance}} FCFA </h2>
                      <p class="mb-0 fs-14">
                          <span class="text-success mr-1">CAISSE</span>
                      </p>
                       <h4 class="text-warning mb-2 font-w600">{{total | currency:'XOF':'':'1.2-2'}} FCFA</h4>
                      <p class="mb-0 fs-14">
                        <span class="text-warning mr-1">TOTAL DES UVE</span>
                    </p>
                  </div>
              </div> -->
              <div class="card">
                <div class="card-header border-0 pb-0">
                    <h1 class="mb-0 text-danger font-w700 fs-20" *ngIf="cashRevenue <0 "><span class="text-danger">CASH : {{cashRevenue | currency: 'FCFA': 'symbol': '1.0-0': 'fr'}} SOLDE INSUFFISANT</span> </h1>
                    <h1 class="mb-0 text-warning font-w700 fs-20" *ngIf="cashRevenue >= 0"><span class="text-success">CASH : {{cashRevenue | currency: 'FCFA': 'symbol': '1.0-0': 'fr'}} </span> </h1>
                </div>
                <div class="card-body p-3">
                    <div class="table-responsive">
                        <table class="table text-center bg-warning-hover tr-rounded order-tbl">
                            <!-- <thead>
                                <tr>
                                    <th class="text-left">Price</th>
                                    <th class="text-center">Amount</th>
                                    <th class="text-right">Total</th>
                                </tr>
                            </thead> -->
                            <tbody>
                                <tr >
                                    <td class="text-left text-warning">TOTAL DES DETTES :</td>
                                    <td></td>
                                    <td class="text-right text-warning">{{getTotalDette() | currency:'FCFA':'symbol':'1.0-0':'fr'}}</td>
                                </tr>
                                <tr >
                                    <td class="text-left text-warning">FOND DE CAISSE :</td>
                                    <td></td>
                                    <td class="text-right text-warning">{{ calculateCaisse() | currency:'FCFA':'symbol':'1.0-0':'fr'}}</td>
                                </tr>
                                <tr >
                                    <td class="text-left">TOTAL DES UVE :</td>
                                    <td></td>
                                    <td class="text-right">{{ total | currency:'FCFA':'symbol':'1.0-0':'fr'}}</td>
                                </tr>
                                <tr >
                                    <td class="text-left">Total des transactions :</td>
                                    <td></td>
                                    <td class="text-right">{{ totalTransaction | currency: 'FCFA': 'symbol': '1.0-0': 'fr'}} </td>
                                </tr>
                                <tr >
                                    <td class="text-left">Total des Factures :</td>
                                    <td></td>
                                    <td class="text-right">{{ getTotalPayBill() | currency: 'FCFA': 'symbol': '1.0-0': 'fr'}}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
                  <!-- <app-graph-current-statistic></app-graph-current-statistic> -->
              </div>
          </div>
          <div class="row">
              <div class="col-xl-6 col-xxl-12">



              <!-- <div *ngFor="let operator of operators">
                Total des dépôts effectués par g {{ operator.name }} :
                {{ calculateTotalDepositsForOperator(operator.id) | currency:'XOF':'symbol':'1.0-0':'fr' }}
              </div>
              <div *ngFor="let operator of operators">
                Total des retraits effectués par {{ operator.name }} :
                {{ calculateTotalRetraitsForOperator(operator.id) | currency:'XOF':'symbol':'1.0-0':'fr' }}
              </div>


              <ul>
                <li *ngFor="let operator of operators">
                  {{ operator.name }} - Chiffre d'affaires quotidien : {{ calculateDailyRevenueForOperator(operator.id) | currency:'XOF':'symbol':'1.0-0':'fr' }}
                </li>
              </ul>


              <ul>
                <li *ngFor="let operator of operators">
                  {{ operator.name }} - Chiffre d'affaires journalier des dépôts : {{ calculateDailyDepositRevenueForOperator(operator.id) | currency:'XOF':'symbol':'1.0-0':'fr' }}
                </li>
              </ul>


              <ul>
                <li *ngFor="let operator of operators">
                  {{ operator.name }} - Chiffre d'affaires hebdomadaire : {{ calculateWeeklyRevenueForOperator(operator.id) | currency:'XOF':'symbol':'1.0-0':'fr' }}
                </li>
              </ul>

              <ul>
                <li *ngFor="let operator of operators">
                  {{ operator.name }} - Chiffre d'affaires mensuel : {{ calculateMonthlyRevenueForOperator(operator.id) | currency:'XOF':'symbol':'1.0-0':'fr' }}
                </li>
              </ul>


            <ul>
              <li *ngFor="let operator of operators">
                {{ operator.name }} - Chiffre d'affaires annuel : {{ calculateAnnualRevenueForOperator(operator.id) | currency:'XOF':'symbol':'1.0-0':'fr' }}
              </li>
            </ul>
<ul>
  <li *ngFor="let annualRevenue of calculateAnnualTotalRevenueByYear()">
    Année {{ annualRevenue.year }} : {{ annualRevenue.revenue | currency:'XOF':'symbol':'1.0-0':'fr' }}
  </li>
</ul>

<ul>
  <li *ngFor="let monthlyRevenue of calculateMonthlyTotalRevenueByMonth()">
    {{ monthlyRevenue.month }} : {{ monthlyRevenue.revenue | currency:'XOF':'symbol':'1.0-0':'fr' }}
  </li>
</ul>

<ul>
  <li *ngFor="let weeklyRevenue of calculateWeeklyTotalRevenueByWeek()">
    {{ weeklyRevenue.week }} : {{ weeklyRevenue.revenue | currency:'XOF':'symbol':'1.0-0':'fr' }}
  </li>
</ul>

<ul>
  <li *ngFor="let dailyRevenue of calculateDailyTotalRevenueByDay()">
    {{ dailyRevenue.day }} : {{ dailyRevenue.revenue | currency:'XOF':'symbol':'1.0-0':'fr' }}
  </li>
</ul>

<div>
  <h2>Bilan des transactions</h2>
  <p>Dépôts totaux : {{ calculateBalance().deposits | currency:'XOF':'symbol':'1.0-0':'fr' }}</p>
  <p>Retraits totaux : {{ calculateBalance().withdrawals | currency:'XOF':'symbol':'1.0-0':'fr' }}</p>
  <p>Solde total : {{ calculateBalance().balance | currency:'XOF':'symbol':'1.0-0':'fr' }}</p>
</div>


<ul>
  <li *ngFor="let dailyRevenue of calculateDailyTotalRevenueWithoutWithdrawalss()">
    {{ dailyRevenue.day }} : {{ dailyRevenue.revenue | currency:'XOF':'symbol':'1.0-0':'fr' }}
  </li>
</ul> -->
              <!-- Display daily revenue for each operator -->

                <!-- ToTal Facture {{ getTotalPayBill()}}
                Total Depot par un operateur: {{totalDepotByOperator | currency: 'FCFA': 'symbol': '1.0-0': 'fr'}} -->
                <!-- <div>
                  <h2>Chiffres d'affaires Semaine :</h2>
                  <table>
                    <tr *ngFor="let weeklyRevenue of weeklyRevenues; let i = index">
                      <td>Semaine {{i + 1}}: {{ weeklyRevenue.date | date: 'dd MMM yyy':'fr' }} : {{ weeklyRevenue.revenue | currency: 'FCFA': 'symbol': '1.0-0': 'fr' }}</td>
                      <td> </td>
                    </tr>
                  </table>

                  <h2>Chiffres d'affaires journaliers :</h2>
                  <table>
                    <tr *ngFor="let dailyRevenue of dailyRevenues ; let i = index">
                      <td> Jour {{i+ 1}} : {{dailyRevenue.date | date: 'dd MMM yyy':'fr' }} : {{ dailyRevenue.revenue | currency: 'FCFA': 'symbol': '1.0-0': 'fr' }}</td>
                      <td> </td>
                    </tr>
                  </table>
                  <h2>Chiffre d'affaires mensuel</h2>
                  <ul>
                    <li *ngFor="let monthlyRevenue of monthlyRevenues; let i = index ">
                      Jour{{i+ 1}} {{ monthlyRevenue.date | date: 'dd MMM yyy':'fr' }} : {{ monthlyRevenue.revenue | currency:'FCFA':'symbol':'1.0-0':'fr' }}
                    </li>
                  </ul>

                  <h2>Chiffre d'affaires annuel</h2>
                  <ul>
                    <li *ngFor="let annualRevenue of annualRevenues; let i = index">
                      Année {{i + 1}} {{ annualRevenue.date | date: 'dd MMM yyy':'fr' }} : {{ annualRevenue.revenue | currency:'FCFA':'symbol':'1.0-0' }}
                    </li>
                  </ul>
                </div> -->


                  <!-- <app-quick-trade></app-quick-trade> -->
              </div>
              <div class="col-xl-6 col-xxl-12">
                  <div class="row">
                      <!-- <div class="col-sm-6" *ngFor="let item of cryptoCards">
                          <div class="card-bx stacked card">
                              <img class="bg-img" src="{{item.image}}" alt="">
                              <div class="card-info">
                                  <p class="mb-1 text-white fs-14">Main Balance</p>
                                  <div class="d-flex justify-content-between align-items-start">
                                      <h2 class="num-text text-white mb-5 font-w600">{{item.price}}</h2>
                                      <img src="{{item.icon_image}}" alt="">
                                  </div>
                                  <div class="d-flex">
                                      <div class="mr-4 text-white">
                                          <p class="fs-12 mb-1 op6">VALID THRU</p>
                                          <span>{{item.valid_thrugh}}</span>
                                      </div>
                                      <div class="text-white">
                                          <p class="fs-12 mb-1 op6">CARD HOLDER</p>
                                          <span>{{item.name}}</span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div> -->
                      <div class="col-xl-12">

                      <div class="col-xl-12 mt-2">
                          <!-- <app-quick-transfer-crousal [data]="quickTransfer"></app-quick-transfer-crousal> -->
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <!--**********************************
      Content body end
  ***********************************-->
