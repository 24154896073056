
<nav class="navbar navbar-expand bd-highlight" >
  <a class="navbar-brand"[routerLink]="['/home']" >Navbar</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item ">
        <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#">Link</a>
      </li>
    </ul>
    <ul class="navbar-nav ml-auto">
      <li class="nav-item " *ngIf="isAdmin">
        <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item" *ngIf="isAnonymous">
        <a class="nav-link" [routerLink]="['/auth/login']"><i class="far fa-user m-1"></i>Se connecter</a>
      </li>
      <li class="nav-item"*ngIf="isAnonymous" >
        <a class=" btn btn-outline-success btn-xs" [routerLink]="['/auth/register']"><i class="fas fa-user-plus m-1"></i>Ouvrir un compte</a>
      </li>
    </ul>
  </div>
</nav>
