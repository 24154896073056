 <!--**********************************
        Content body start
    ***********************************-->
    <div class="content-body">
      <div class="container-fluid">
          <div class="form-head mb-lg-5 mb-3 d-flex flex-wrap align-items-center">
              <h2 class="font-w600 mb-3 mr-auto ">Les utilisateurs</h2>
              <!-- <a routerLink="/caisse/users/new" class="btn btn-outline-primary btn-sm mb-2"><i class="fa fa-plus m-2" aria-hidden="true"></i>Enregistrer un(e) caissier(e)</a> -->
          </div>
          <div class="row">
              <div class="col-xl-9 col-xxl-8">
                <div class="table-responsive">
                  <div class="">
                    <div class="input-group right d-lg-inline-flex d-none mb-5">
                        <input type="text" #query (keyup)="search(query.value)"  class="form-control" placeholder="Recherche un utilisateur par Nom, Prénom , Téléphone, UID ou Email">
                        <div class="input-group-append">
                            <span class="input-group-text"><i class="flaticon-381-search-2"></i></span>
                        </div>
                    </div>
                </div>
                  <table class="table table-striped ">
                    <thead>
                    <tr>
                    <th scope="col">#</th>
                    <th scope="col">Prénom</th>
                    <th scope="col">Nom</th>
                    <th scope="col">Email</th>
                    <th scope="col">Téléphone</th>
                    <th scope="col">Date de création</th>
                     <th scope="col">Status</th>
                     <th scope="col">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let user of searchUsers; let index= id; index as i">
                    <th scope="row">{{i + 1}}</th>
                    <!-- <td>
                      <img [src]="user.photo" class="mr-2" style="width: 20px">
                      {{ user.firstName }}
                    </td> -->
                    <td>{{user.firstName}}</td>
                    <td>{{user.lastName}}</td>
                    <td>{{user.email}}</td>
                    <td>{{user.phoneNumber}}</td>
                    <td>{{user.creationTime | date: 'dd MMM yyy HH:mm':'fr'}}</td>
                    <!-- <td>{{user.metadata.lastSignInTime | date: 'dd MMM yyy HH:mm':'fr'}}</td> -->
                       <td>{{ user.isBlocked ? 'Bloqué' : 'Débloqué' }}</td>
                    <td>
                      <!-- <button type="button" (click)="editDeposit(user,user.id)" class="btn btn-outline-primary btn-xxs  m-1"><i class="far fa-money-bill-alt m-1"></i>Dépôt ou Retrait</button> -->
                      <!-- <button type="button" (click)="editDeposit(user,user.id)" class="btn btn-outline-primary btn-xxs  m-1"><i class="fa fa-pencil-square-o m-1" aria-hidden="true"></i>depot ou retrait</button> -->
                      <!-- <a (click)="ditDeposit(user)" data-toggle="modal" data-target="#exampleModal" class="btn btn-outline-primary btn-xxs mt-2 mb-2"><i class="far fa-money-bill-alt m-1"></i></a> -->
                      <!-- <a routerLink="#" class="btn btn-outline-primary btn-xxs"><i class="fa fa-info-circle m-1" aria-hidden="true"></i>Details</a> -->
                      <button class="btn btn-outline-primary btn-xxs" (click)="toggleUserBlock( user.isBlocked, user.id)">
                        {{ user.isBlocked ? 'Débloquer' : 'Bloquer' }}
                      </button>

                    </td>
                    </tr>

                    </tbody>
                  </table>
                </div>
                <!-- <div class="d-sm-flex d-block justify-content-between p-2">
                  <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" (pageChange)="refreshCountries()">
                  </ngb-pagination>

                  <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="refreshCountries()">
                    <option [ngValue]="2">2 opérateurs page</option>
                    <option [ngValue]="4">4 opérateurs page</option>
                    <option [ngValue]="6">6 opérateurs page</option>
                  </select>
                </div> -->
                  <!-- <app-graph-market-overview></app-graph-market-overview> -->
              </div>
              <div class="col-xl-3 col-xxl-4">
                  <!-- <app-graph-current-statistic></app-graph-current-statistic> -->
              </div>
          </div>
          <div class="row">
              <div class="col-xl-6 col-xxl-12">
                  <!-- <app-quick-trade></app-quick-trade> -->
              </div>
              <div class="col-xl-6 col-xxl-12">
                  <div class="row">

                      <div class="col-xl-12">
                         <!-- <app-recent-trading-activities></app-recent-trading-activities> -->
                      </div>
                      <div class="col-xl-12 mt-2">
                          <!-- <app-quick-transfer-crousal [data]="quickTransfer"></app-quick-transfer-crousal> -->
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <!--**********************************
      Content body end
  ***********************************-->

