import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DailyPaymentService } from 'src/app/services/daily-payment.service';
import { DailyPayment } from 'src/app/models/daily-payment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalDetteComponent } from '../modal-dette/modal-dette.component';
import { Driver } from 'src/app/models/driver';
import { Car } from 'src/app/models/car';
import { CarService } from 'src/app/services/car.service';
import { DriverService } from 'src/app/services/driver.service';

@Component({
  selector: 'app-daily-payment-detail',
  templateUrl: './daily-payment-detail.component.html',
  styleUrls: ['./daily-payment-detail.component.css']
})
export class DailyPaymentDetailComponent implements OnInit {

  dailyPaymentId : string;
  totalDebt:number=0;
  dailyPayment: DailyPayment;
  availableCars: Car[] = [];
  availableDrivers: Driver[] = [];

  constructor( private router : Router,
                private route: ActivatedRoute,
                private dailyPaymentService: DailyPaymentService,
                private modalService: NgbModal,
                private carService: CarService,
                private driverService : DriverService,
    ) { }

  ngOnInit(): void {
    this.loadAvailableCars();
      this.loadAvailableDrivers();
      const driverId = this.route.snapshot.paramMap.get('driverId');
    this.dailyPaymentId = this.route.snapshot.params.id;
    this.dailyPaymentService.getByIdDailyPayment(this.dailyPaymentId).subscribe(
      (dailyPayment)=>{
        this.dailyPayment = dailyPayment
        console.log(dailyPayment);
        
      });



  }

  cancelDebt(): void {

    // Annuler la dette d'un chauffeur
  if (this.dailyPayment) {
    // Mettre à jour la dette en passant le nouvel montant
    this.dailyPaymentService.updateDebtAmount(this.dailyPayment.id, 0)
      .then(() => {
        // Gérer la réussite de l'annulation de la dette ici
      })
      .catch(error => {
        // Gérer les erreurs de mise à jour ici
      });
  }


  }

  loadAvailableCars() {
    this.carService.getAllCars().then((data) => {
      this.availableCars = data;
     });
  }

  loadAvailableDrivers(){
    this.driverService.getDrivers().subscribe((data)=>{
      this.availableDrivers = data;
    })
  }



  getCarIndexCar(carId: string): string{

    const car = this.availableCars.find((car) => car.id === carId);
    return car ? car.indexCar : 'Inconnu';
}

  getCarBrand(carId: string): string{

    const car = this.availableCars.find((car) => car.id === carId);
    return car ? car.brand : 'Inconnu';
}
  getCarRegisterNumber(carId: string): string{

    const car = this.availableCars.find((car) => car.id === carId);
    return car ? car.registerNumber : 'Inconnu';
}
  getDriverName(driverId: string): string{

    const driver = this.availableDrivers.find((d) => d.id === driverId);
    return driver ? driver.name : 'Inconnu';
}
  getDriverPhone(driverId: string): string{

    const driver = this.availableDrivers.find((d) => d.id === driverId);
    return driver ? driver.driverPhone : 'Inconnu';
}

calculateDriverDebt(paymentAmount: number): number {
  const dailyDebt = Math.max(0, 26000 - paymentAmount);
  return dailyDebt;
}

calculateCommission(paymentAmount: any): number {
  const roundedAmount = Number(paymentAmount); // Convertir en nombre
  if (roundedAmount >= 25000 && roundedAmount <= 25999) {
    return 1000;
  } else if (roundedAmount === 26000) {
    return 2000;
  } else {
    return 0;
  }
}

  async openDebtModal() {
    const modalRef = this.modalService.open(ModalDetteComponent);
    modalRef.componentInstance.dailyPayment = this.dailyPayment;
    modalRef.result.then((result) => {
      if (result === 'Recharge successful') {
        this.dailyPaymentService.getByIdDailyPayment(this.dailyPayment.id).subscribe(updatedDailyPayment => {
          this.dailyPayment = updatedDailyPayment;
        });
      }
    }).catch((reason) => {
      console.log('Modal dismissed:', reason);
    });
  }

}
