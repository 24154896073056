 <!--**********************************
        Content body start
    ***********************************-->
    <div class="content-body">
      <div class="container-fluid">
          <div class="form-head mb-lg-5 mb-3 d-flex flex-wrap align-items-center">
              <h2 class="font-w600 mb-3 mr-auto ">La liste des versements </h2>
              <!-- <a routerLink="/caisse/operators/new" class="btn btn-outline-primary btn-sm mb-2 m-2"><i class="fa fa-plus m-2" aria-hidden="true"></i>Approvisionner</a> -->
              <!-- <a routerLink="/caisse/drivers/new" class="btn btn-secondary text-white btn-sm mb-2 m-2"><i class="fa fa-plus m-2" aria-hidden="true"></i>Enregistrer un chauffeur</a> -->
              <a routerLink="/caisse/cars/versements/new" class="btn btn-secondary text-white btn-sm mb-2 m-2"><i class="fa fa-plus m-2" aria-hidden="true"></i>Enregistrer un versement </a>
          </div>
          <div class="row sp-sm-15">
            <div class="col-xl-2 col-3 m-t35">
              <div class="card ">
                  <div class="card-body text-center">
                    <!-- <img src="{{item.image}}" class="mb-sm-3 mb-2 currency-icon"/> -->
                      <h4>Versements journalier</h4>
                      <h3 class="text-black mb-1 font-w500" > <span> {{ dailyRevenue | currency:'XOF':'symbol':'1.0-0':'fr' }} </span> </h3>
                  </div>
              </div>
          </div>
            <div class="col-xl-2 col-3 m-t35">
              <div class="card ">
                  <div class="card-body text-center">
                    <!-- <img src="{{item.image}}" class="mb-sm-3 mb-2 currency-icon"/> -->
                      <h4>Versements Hebdomadaire</h4>
                      <h3 class="text-black mb-1 font-w500" > <span> {{ weeklyRevenue | currency:'XOF':'symbol':'1.0-0':'fr' }} </span> </h3>
                  </div>
              </div>
          </div>
            <div class="col-xl-2 col-3 m-t35">
              <div class="card ">
                  <div class="card-body text-center">
                    <!-- <img src="{{item.image}}" class="mb-sm-3 mb-2 currency-icon"/> -->
                      <h4>Versements Mensuel</h4>
                      <h3 class="text-black mb-1 font-w500" > <span> {{ monthlyRevenue | currency:'XOF':'symbol':'1.0-0':'fr' }} </span> </h3>
                  </div>
              </div>
          </div>
            <div class="col-xl-2 col-3 m-t35">
              <div class="card ">
                  <div class="card-body text-center">
                    <!-- <img src="{{item.image}}" class="mb-sm-3 mb-2 currency-icon"/> -->
                      <h4>Versements Annuel </h4>
                      <h3 class="text-black mb-1 font-w500" > <span> {{ annualRevenue | currency:'XOF':'symbol':'1.0-0':'fr' }} </span> </h3>
                  </div>
              </div>
          </div>
            <div class="col-xl-2 col-3 m-t35">
              <div class="card ">
                  <div class="card-body text-center">
                    <!-- <img src="{{item.image}}" class="mb-sm-3 mb-2 currency-icon"/> -->
                      <h4>Nombre de versements </h4>
                      <h3 class="text-black mb-1 font-w500" > <span> {{ numberOfPayments }} </span> </h3>
                  </div>
              </div>
          </div>
            <div class="col-xl-2 col-3 m-t35">
              <div class="card ">
                  <div class="card-body text-center">
                    <!-- <img src="{{item.image}}" class="mb-sm-3 mb-2 currency-icon"/> -->
                      <h4>Total des versements </h4>
                      <h3 class="text-black mb-1 font-w500" > <span> {{ calculateTotalPayments()| currency: 'FCFA': 'symbol': '1.0-0': 'fr' }} </span> </h3>
                  </div>
              </div>
          </div>

        </div>
          <div class="row">
              <div class="col-xl-12 col-xxl-12">
                <div class="mb-3">
                  <div class="input-group right d-lg-inline-flex d-none">
                      <input type="text" #query (keyup)=" searchDailyPayment(query.value)"  class="form-control" placeholder="Recherche un véhicule par sa Marque, ou son Numéro d'immatriculation ">
                      <div class="input-group-append">
                          <span class="input-group-text"><i class="flaticon-381-search-2"></i></span>
                      </div>
                  </div>
              </div>
                <div class="table-responsive">
                  <table class="table table-striped">
                    <thead>
                    <tr>
                    <th scope="col">#</th>
                    <th scope="col">Date versements</th>
                    <th scope="col">Index (V1)</th>
                    <th scope="col">Véhicule </th>
                    <th scope="col">Immatriculation </th>
                    <th scope="col">Chauffeur</th>
                    <th scope="col">Montant (FCFA)</th>
                    <th scope="col">Pointage (FCFA)</th>
                    <th scope="col">Dette (FCFA)</th>
                    <th scope="col">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let dailPayment of searchDailyPayments | paginate: { itemsPerPage: 30, currentPage: page, totalItems :totalLength } ; index as i">
                    <th scope="row"> {{i + 1}}</th>
                    <td>
                      {{dailPayment.date.toDate() | date: 'dd MMM yyy HH:mm':'fr' }}
                    </td>
                    <td>
                      {{ getCarIndexCar(dailPayment.carId) }}
                    </td>
                    <td>
                      {{ getCarBrand(dailPayment.carId) }}
                    </td>
                    <td>
                      {{ getCarRegisterNumber(dailPayment.carId) }}
                      <div *ngIf="dailPayment.userName " class="header-info">
                        <small>Éditer par {{dailPayment.userName}}</small>
                      </div>
                    </td>
                    <td>
                      {{ getDriverName(dailPayment.driverId) }}

                    </td>
                    <td>
                      {{ dailPayment.amount | currency: 'FCFA': 'symbol': '1.0-0': 'fr'}}
                    </td>
                    <td>
                      <span class="text-success">
                        {{dailPayment.commission| currency: 'FCFA': 'symbol': '1.0-0': 'fr' }}
                      </span>

                    </td>
                    <td>
                      <span class="text-danger">{{ dailPayment.debtAmount| currency: 'FCFA': 'symbol': '1.0-0': 'fr' }}</span>

                    </td>

                    <td>
                      <a routerLink="/caisse/cars/versements/edit/{{dailPayment.id}}" class="btn btn-outline-primary btn-xxs  m-1"><i class="fa fa-pencil-square-o m-1" aria-hidden="true"></i>Modifier</a>
                      <!-- <a *ngIf="calculateDriverDebt(dailPayment.amount) > 0 " routerLink="/caisse/cars/details/versement/{{dailPayment.id}}" class="btn btn-outline-primary btn-xxs  m-1"> <i class="fa fa-money m-2" aria-hidden="true"></i> Payer {{ dailPayment.debtAmount| currency: 'FCFA': 'symbol': '1.0-0': 'fr' }} </a> -->
                      <a *ngIf="calculateDriverDebt(dailPayment.amount) == 0 " routerLink="/caisse/cars/details/versement/{{dailPayment.id}}" class="btn btn-outline-primary btn-xxs  m-1"> <i class="fa fa-info-circle m-2" aria-hidden="true"></i> details </a>
                      <a routerLink="/caisse/cars/cancel/debt/{{dailPayment.id}}" class="btn btn-outline-warning btn-xxs  m-1"><i class="fa fa-pencil-square-o m-1" aria-hidden="true"></i> Annuler la dette ou Commission</a>
                      <a (click)="onDeleteDailyPayment(dailPayment.id)" class="btn btn-outline-danger btn-xxs  m-1"><i class="fa fa-trash-o m-1" aria-hidden="true"></i>Supprimer</a>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        <span class="font-weight-bold">
                          TOTAL :
                        </span>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td><span class="font-weight-bold">
                        {{ calculateTotalPayments()| currency: 'FCFA': 'symbol': '1.0-0': 'fr' }}
                      </span></td>
                      <td>
                        <span class="font-weight-bold">
                           {{ calculateTotalCommission() | currency: 'FCFA': 'symbol': '1.0-0': 'fr'}}
                        </span>

                      </td>
                      <td>
                        <span class="font-weight-bold">
                           {{ calculateTotalDebt() | currency: 'FCFA': 'symbol': '1.0-0': 'fr' }}
                        </span>

                      </td>
                    </tr>

                    </tbody>
                    <!-- <tfoot>
                      <tr *ngFor="let driver of availableDrivers">
                        <td colspan="4">Dette totale pour{{ getDriverName(driver.id) }}</td>
                        <td>{{ calculateDriverTotalDebt(driver.id) }}</td>
                        <td>{{ calculateDriverTotalCommission(driver.id) }}</td>
                      </tr>
                    </tfoot> -->

                  </table>
                  <div>
                    <div class="d-flex justify-content-between p-2">
                      <pagination-controls class="mr-3" (pageChange)="page = $event" ></pagination-controls>
                        <!-- <ngb-pagination class="mr-3" [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" (pageChange)="updateTransactionListing()">
                        </ngb-pagination> -->

                        <!-- <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="updateTransactionListing()">
                            <option [ngValue]="10">10 transactions par page</option>
                            <option [ngValue]="20">20 transactions par page</option>
                            <option [ngValue]="30">30 transactions par page</option>
                            <option [ngValue]="50">50 transactions par page</option>
                            <option [ngValue]="100">100 transactions par page</option>
                        </select>  -->
                    </div>
                   </div>
                </div>


                <!-- <div class="d-sm-flex d-block justify-content-between p-2">
                  <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" (pageChange)="refreshCountries()">
                  </ngb-pagination>

                  <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="refreshCountries()">
                    <option [ngValue]="2">2 opérateurs page</option>
                    <option [ngValue]="4">4 opérateurs page</option>
                    <option [ngValue]="6">6 opérateurs page</option>
                  </select>
                </div> -->
                  <!-- <app-graph-market-overview></app-graph-market-overview> -->
              </div>
              <div class="col-xl-3 col-xxl-4">
                  <!-- <app-graph-current-statistic></app-graph-current-statistic> -->


              </div>
          </div>
          <div class="row">
              <div class="col-xl-6 col-xxl-12">
                  <!-- <app-quick-trade></app-quick-trade> -->

              </div>
              <div class="col-xl-6 col-xxl-12">
                  <div class="row">
                      <!-- <div class="col-sm-6" *ngFor="let item of cryptoCards">
                          <div class="card-bx stacked card">
                              <img class="bg-img" src="{{item.image}}" alt="">
                              <div class="card-info">
                                  <p class="mb-1 text-white fs-14">Main Balance</p>
                                  <div class="d-flex justify-content-between align-items-start">
                                      <h2 class="num-text text-white mb-5 font-w600">{{item.price}}</h2>
                                      <img src="{{item.icon_image}}" alt="">
                                  </div>
                                  <div class="d-flex">
                                      <div class="mr-4 text-white">
                                          <p class="fs-12 mb-1 op6">VALID THRU</p>
                                          <span>{{item.valid_thrugh}}</span>
                                      </div>
                                      <div class="text-white">
                                          <p class="fs-12 mb-1 op6">CARD HOLDER</p>
                                          <span>{{item.name}}</span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div> -->
                      <div class="col-xl-12">
                         <!-- <app-recent-trading-activities></app-recent-trading-activities> -->
                      </div>
                      <div class="col-xl-12 mt-2">
                          <!-- <app-quick-transfer-crousal [data]="quickTransfer"></app-quick-transfer-crousal> -->

                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <!--**********************************
      Content body end
  ***********************************-->



