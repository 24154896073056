<div class="content-body">
  <div class="container-fluid">

    <div class="authincation h-100">
      <div class="container vh-100">
          <div class="row justify-content-center h-100 align-items-center">
              <div class="col-lg-6 col-md-8">
                  <div class="authincation-content">
                      <div class="row no-gutters">
                          <div class="col-xl-12">
                              <div class="auth-form">
                                  <h4 *ngIf="dailyPayment" class="text-center mb-4">Annulation de dette ou de Commission de M. {{getDriverName(dailyPayment.driverId)}}</h4>

                                  <form [formGroup]="versementEditForm" (ngSubmit)="onSubmitVersementEditForm()">
                                    <!-- <div class="alert alert-danger"></div> -->
                                    <div class="form-group">
                                      <div class="alert alert-danger"
                                      *ngIf="(versementEditForm.controls.debtAmount.dirty
                                      || versementEditForm.controls.debtAmount.touched)
                                      && versementEditForm.controls.debtAmount.invalid
                                      && versementEditForm.controls.debtAmount.errors.required"
                                      > Le Montant de la dette est obligatoire.</div>
                                        <label class="mb-1"><strong>Dette (FCFA)</strong></label>
                                        <input type="text" class="form-control" placeholder="Ex: 10 000" formControlName="debtAmount">
                                    </div>
                                    <div class="form-group">
                                      <div class="alert alert-danger"
                                      *ngIf="(versementEditForm.controls.commission.dirty
                                      || versementEditForm.controls.commission.touched)
                                      && versementEditForm.controls.commission.invalid
                                      && versementEditForm.controls.commission.errors.required"
                                      > Le Montant de la Commission est obligatoire.</div>
                                        <label class="mb-1"><strong>Commission (FCFA)</strong></label>
                                        <input type="text" class="form-control" placeholder="Ex: 2 000" formControlName="commission">
                                    </div>
                                      <div class="text-center mt-4">
                                          <button type="submit" class="btn btn-primary  btn-sm btn-block" [disabled]="versementEditForm.invalid" >Enregistrer</button>
                                      </div>
                                  </form>
                                  <div class="new-account mt-3">
                                    <p><a class="text-primary" [routerLink]="['/caisse/cars/versements']">Retour à la liste des versements</a></p>
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
  </div>
</div>
