 <!--**********************************
        Content body start
    ***********************************-->
    <div class="content-body">
      <div class="container-fluid">
          <div class="form-head mb-lg-5 mb-3 d-flex flex-wrap align-items-center">
              <h2 class="font-w600 mb-3 mr-auto ">La liste des totales de Dette & Pointage par chauffeur </h2>
              <!-- <a href="javascript:void(0);" class="btn btn-secondary btn-xxs text-white mb-2 m-2"> <i class="fa fa-money m-2" aria-hidden="true"></i>Recharger UVE </a> -->
              <a  routerLink="/caisse/cars/versements/new" class="btn btn-secondary btn-xxs text-white mb-2" > <i class="fa fa-money m-2" aria-hidden="true"></i>Enregistrer un versement</a>
          </div>
          <div class="row sp-sm-15">
            <div class="col-xl-2 col-3 m-t35">
              <div class="card card-coin">
                <div class="card-body text-center">
                  <!-- <img src="{{item.image}}" class="mb-sm-3 mb-2 currency-icon"/> -->
                      <h4>Pointage Journalier</h4>
                    
                    <h3 class=" mb-2 font-w500">{{dailyRevenueCommission | currency: 'FCFA': 'symbol': '1.0-0': 'fr' }}</h3>
                    <p class="mb-0 fs-14">
                        <!-- <span class="text-success mr-1"> UVE {{operator.name}}</span> -->
                        <!-- <button class="btn btn-secondary btn-xxs" (click)="openDebtModal()"> <i class="fa fa-money m-2" aria-hidden="true"></i> Payer ma dette</button> -->
                    </p>
                </div>
            </div>
            </div>
            <div class="col-xl-2 col-3 m-t35">
              <div class="card card-coin">
                <div class="card-body text-center">
                  <!-- <img src="{{item.image}}" class="mb-sm-3 mb-2 currency-icon"/> -->
                      <h4>Pointage Hebdomadaire</h4>
                    <h3 class="mb-2 font-w500"> {{ weeklyRevenueCommission|currency: 'FCFA': 'symbol': '1.0-0': 'fr' }} </h3>
                    <p class="mb-0 fs-14">
                        <!-- <span class="text-success mr-1"> UVE {{operator.name}}</span> -->
                        <!-- <button class="btn btn-secondary btn-xs" (click)="openRechargeModal()"> <i class="fa fa-money m-2" aria-hidden="true"></i>Paye ma dette {{ calculateDriverDebt(dailyPayment.amount)| currency: 'FCFA': 'symbol': '1.0-0': 'fr' }}</button> -->
                    </p>
                </div>
            </div>
            </div>
            <div class="col-xl-2 col-3 m-t35">
              <div class="card card-coin">
                <div class="card-body text-center">
                  <!-- <img src="{{item.image}}" class="mb-sm-3 mb-2 currency-icon"/> -->
                      <h4>Pointage Mensuel</h4>
                    
                    <h3 class=" mb-2 font-w500">{{monthlyRevenueCommission | currency: 'FCFA': 'symbol': '1.0-0': 'fr' }}</h3>
                    <p class="mb-0 fs-14">
                        <!-- <span class="text-success mr-1"> UVE {{operator.name}}</span> -->
                        <!-- <button class="btn btn-secondary btn-xxs" (click)="openDebtModal()"> <i class="fa fa-money m-2" aria-hidden="true"></i> Payer ma dette</button> -->
                    </p>
                </div>
            </div>
            </div>
            <div class="col-xl-2 col-3 m-t35">
              <div class="card card-coin">
                <div class="card-body text-center">
                  <!-- <img src="{{item.image}}" class="mb-sm-3 mb-2 currency-icon"/> -->
                      <h4>Dette Journalière</h4>
                    
                    <h3 class=" mb-2 font-w500">{{dailyRevenueDebt | currency: 'FCFA': 'symbol': '1.0-0': 'fr' }}</h3>
                    <p class="mb-0 fs-14">
                        <!-- <span class="text-success mr-1"> UVE {{operator.name}}</span> -->
                        <!-- <button class="btn btn-secondary btn-xxs" (click)="openDebtModal()"> <i class="fa fa-money m-2" aria-hidden="true"></i> Payer ma dette</button> -->
                    </p>
                </div>
            </div>
            </div>
            <div class="col-xl-2 col-3 m-t35">
              <div class="card card-coin">
                <div class="card-body text-center">
                  <!-- <img src="{{item.image}}" class="mb-sm-3 mb-2 currency-icon"/> -->
                      <h4>Dette Hebdomadaire</h4>
                    
                    <h3 class=" mb-2 font-w500">{{weeklyRevenueDebt | currency: 'FCFA': 'symbol': '1.0-0': 'fr' }}</h3>
                    <p class="mb-0 fs-14">
                        <!-- <span class="text-success mr-1"> UVE {{operator.name}}</span> -->
                        <!-- <button class="btn btn-secondary btn-xxs" (click)="openDebtModal()"> <i class="fa fa-money m-2" aria-hidden="true"></i> Payer ma dette</button> -->
                    </p>
                </div>
            </div>
            </div>
            <div class="col-xl-2 col-3 m-t35">
              <div class="card card-coin">
                <div class="card-body text-center">
                  <!-- <img src="{{item.image}}" class="mb-sm-3 mb-2 currency-icon"/> -->
                      <h4>Dette Mensuelle</h4>
                    
                    <h3 class=" mb-2 font-w500">{{monthlyRevenueDebt | currency: 'FCFA': 'symbol': '1.0-0': 'fr' }}</h3>
                    <p class="mb-0 fs-14">
                        <!-- <span class="text-success mr-1"> UVE {{operator.name}}</span> -->
                        <!-- <button class="btn btn-secondary btn-xxs" (click)="openDebtModal()"> <i class="fa fa-money m-2" aria-hidden="true"></i> Payer ma dette</button> -->
                    </p>
                </div>
            </div>
            </div>
            
          </div>
          <div class="row">
              <div class="col-xl-6 col-xxl-6">
                  <!-- <app-graph-market-overview></app-graph-market-overview> -->
                  <div class="mb-3">
                    <div class="input-group right d-lg-inline-flex d-none">
                        <input type="text" #query (keyup)=" searchDriverCommissionDebt(query.value)"  class="form-control" placeholder="Recherche un chauffeur par son Nom ">
                        <div class="input-group-append">
                            <span class="input-group-text"><i class="flaticon-381-search-2"></i></span>
                        </div>
                    </div>
                </div>
                  <div class="card">
                   
                    <div class="card-header border-0 pb-0">
                        <h3 class="mb-0 font-w700 fs-20" > Totales des Dettes & Pointages par chauffeur. </h3>
                    </div>
                    <div class="card-body p-3">
                      <div class="table-responsive">
                          <table class="table text-center bg-warning-hover tr-rounded order-tbl">
                              <thead>
                                  <tr>
                                      <th >Chauffeur</th>
                                      <th >Pointage (FCFA)</th>
                                      <th>Dette (FCFA)</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr *ngFor="let driver of searchDrivers | paginate: { itemsPerPage: 30, currentPage: page, totalItems :totalLength } ; index as i">
                                      <td >{{driver.name }}</td>
                                      <td class="text-success">{{calculateTotalCommissionsForDriver(driver.id)| currency: 'FCFA': 'symbol': '1.0-0': 'fr' }}</td>
                                      <td class="text-danger">{{ calculateTotalDebtsForDriver(driver.id)| currency: 'FCFA': 'symbol': '1.0-0': 'fr' }}</td>
                                  </tr>
                              </tbody>
                          </table>
                           <div>
                    <div class="d-flex justify-content-between p-2">
                      <pagination-controls class="mr-3" (pageChange)="page = $event" ></pagination-controls>
                        
                    </div>
                   </div>
                      </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-xxl-4">
                  <!-- <app-graph-current-statistic></app-graph-current-statistic> -->
                  <!-- <div class="card">
                    <div class="card-header border-0 pb-0">
                        <h3 class="mb-0 text-danger font-w700 fs-20" >HISTORIQUE DE RETRAITS {{ operator.name}} </h3>
                    </div>
                    <div class="card-body p-3">
                        <div class="table-responsive">
                            <table class="table text-center bg-warning-hover tr-rounded order-tbl">
                                <thead>
                                    <tr>
                                        <th class="text-left">Date de retraits</th>
                                        <th class="text-center">Montant</th>
                                        <th class="text-right">Numéro</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let retrait of operator.retraits">
                                      <td class="text-right">{{ retrait.date.toDate() | date: 'dd MMM yyy HH:mm':'fr' }}</td>
                                        <td class="text-danger"> -{{retrait.amount | currency: 'FCFA': 'symbol': '1.0-0': 'fr'}}</td>
                                        <td class="text-left">{{operator.operatorPhone}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div> -->
              </div>
          </div>
          <div class="row">
              <div class="col-xl-4 col-xxl-8">
                  <!-- <app-graph-market-overview></app-graph-market-overview> -->
                  <!-- <div class="card">
                    <div class="card-header border-0 pb-0">
                        <h3 class="mb-0 text-danger font-w700 fs-20" >HISTORIQUE DE RECHARGEMENT {{ operator.name}} </h3>
                    </div>
                    <div class="card-body p-3">
                        <div class="table-responsive">
                            <table class="table text-center bg-warning-hover tr-rounded order-tbl">
                                <thead>
                                    <tr>
                                        <th class="text-left">Date de recharger</th>
                                        <th class="text-center">Montant</th>
                                        <th class="text-right">Numéro</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let recharge of operator.recharges">
                                      <td class="text-right">{{ recharge.date.toDate() | date: 'dd MMM yyy HH:mm':'fr' }}</td>
                                        <td class="text-success"> +{{recharge.amount | currency: 'FCFA': 'symbol': '1.0-0': 'fr'}}</td>
                                        <td class="text-left">{{operator.operatorPhone}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div> -->
              </div>
              <div class="col-xl-4 col-xxl-4">
                  <!-- <app-graph-current-statistic></app-graph-current-statistic> -->
                  <!-- <div class="card">
                    <div class="card-header border-0 pb-0">
                        <h3 class="mb-0 text-danger font-w700 fs-20" >HISTORIQUE DE RETRAITS {{ operator.name}} </h3>
                    </div>
                    <div class="card-body p-3">
                        <div class="table-responsive">
                            <table class="table text-center bg-warning-hover tr-rounded order-tbl">
                                <thead>
                                    <tr>
                                        <th class="text-left">Date de retraits</th>
                                        <th class="text-center">Montant</th>
                                        <th class="text-right">Numéro</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let retrait of operator.retraits">
                                      <td class="text-right">{{ retrait.date.toDate() | date: 'dd MMM yyy HH:mm':'fr' }}</td>
                                        <td class="text-danger"> -{{retrait.amount | currency: 'FCFA': 'symbol': '1.0-0': 'fr'}}</td>
                                        <td class="text-left">{{operator.operatorPhone}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div> -->
              </div>
          </div>
          <div class="row">
              <div class="col-xl-6 col-xxl-12">
                  <!-- <app-quick-trade></app-quick-trade> -->
              </div>
              <div class="col-xl-6 col-xxl-12">
                  <div class="row">
                      <!-- <div class="col-sm-6" *ngFor="let item of cryptoCards">
                          <div class="card-bx stacked card">
                              <img class="bg-img" src="{{item.image}}" alt="">
                              <div class="card-info">
                                  <p class="mb-1 text-white fs-14">Main Balance</p>
                                  <div class="d-flex justify-content-between align-items-start">
                                      <h2 class="num-text text-white mb-5 font-w600">{{item.price}}</h2>
                                      <img src="{{item.icon_image}}" alt="">
                                  </div>
                                  <div class="d-flex">
                                      <div class="mr-4 text-white">
                                          <p class="fs-12 mb-1 op6">VALID THRU</p>
                                          <span>{{item.valid_thrugh}}</span>
                                      </div>
                                      <div class="text-white">
                                          <p class="fs-12 mb-1 op6">CARD HOLDER</p>
                                          <span>{{item.name}}</span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div> -->
                      <div class="col-xl-12">
                         <!-- <app-recent-trading-activities></app-recent-trading-activities> -->
                      </div>
                      <div class="col-xl-12 mt-2">
                          <!-- <app-quick-transfer-crousal [data]="quickTransfer"></app-quick-transfer-crousal> -->
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <!--**********************************
      Content body end
  ***********************************-->


  