 <!--**********************************
        Content body start
    ***********************************-->
    <div class="content-body">
      <div class="container-fluid">
        <div class="authincation h-100">
          <div class="container vh-100">
              <div class="row justify-content-center h-100 align-items-center">
                  <div class="col-lg-6 col-md-8">
                      <div class="authincation-content">
                          <div class="row no-gutters">
                              <div class="col-xl-12">
                                  <div class="auth-form">
                    <!-- <div class="text-center mb-3">
                      <a [routerLink]="['/admin/index']"><img src="assets/images/logo-full.png" alt=""></a>
                    </div> -->
                                      <h4 class="text-center mb-4">Création d'un(e) caissièr(e)</h4>
                                      <form [formGroup]="signUpForm" (ngSubmit)="onSubmitSignUpForm()">
      
                                        <div *ngIf="errorMsg" class="alert alert-danger">{{errorMsg}}</div>
                                          <div class="form-group">
                                            <div class="alert alert-danger"
                                                *ngIf="(signUpForm.controls.firstName.dirty || signUpForm.controls.firstName.touched)
                                                        && signUpForm.controls.firstName.invalid
                                                        && signUpForm.controls.firstName.errors.required">
                                                Le prénom est obligatoire.
                                            </div>
                                              <label class="mb-1"><strong>Prénom</strong></label>
                                              <input type="text" class="form-control" placeholder="Votre prénom"  formControlName="firstName">
                                          </div>
                                          <div class="form-group">
                                            <div class="alert alert-danger"
                                                  *ngIf="(signUpForm.controls.lastName.dirty || signUpForm.controls.lastName.touched)
                                                          && signUpForm.controls.lastName.invalid
                                                          && signUpForm.controls.lastName.errors.required">
                                                  Le nom est obligatoire.
                                              </div>
                                            <label class="mb-1"><strong>Nom</strong></label>
                                            <input type="text" class="form-control" placeholder="Votre nom"  formControlName="lastName">
                                        </div>
                                          <div class="form-group">
                                            <div class="alert alert-danger"
                                                *ngIf="(signUpForm.controls.phoneNumber.dirty || signUpForm.controls.phoneNumber.touched)
                                                        && signUpForm.controls.phoneNumber.invalid
                                                        && signUpForm.controls.phoneNumber.errors.required">
                                                        Le numéro de téléphone  est obligatoire.
                                            </div>
                                            <label class="mb-1"><strong>Téléphone</strong></label>
                                            <input type="tel" class="form-control" placeholder="01 02 03 04 05" formControlName="phoneNumber">
                                        </div>
                                          <div class="form-group">
                                            <div class="alert alert-danger"
                                                *ngIf="(signUpForm.controls.email.dirty || signUpForm.controls.email.touched)
                                                        && signUpForm.controls.email.invalid
                                                        && signUpForm.controls.email.errors.required">
                                                L'email est obligatoire.
                                            </div>
                                              <label class="mb-1"><strong>Email</strong></label>
                                              <input type="email" class="form-control" placeholder="hello@example.com"  formControlName="email">
                                          </div>
                                          <div class="form-group">
                                            <div class="alert alert-danger"
                                                *ngIf="(signUpForm.controls.password.dirty || signUpForm.controls.password.touched)
                                                        && signUpForm.controls.password.invalid
                                                        && signUpForm.controls.password.errors.required">
                                                Le mot de passe est obligatoire.
                                            </div>
                                              <label class="mb-1"><strong>Mot de passe</strong></label>
                                              <input type="password" class="form-control" value="Password"  placeholder="*****" formControlName="password">
                                          </div>
                                          <div class="text-center mt-4">
                                              <button type="submit" class="btn btn-primary  btn-sm btn-block" [disabled]="signUpForm.invalid">Créer un compte</button>
                                          </div>
                                      </form>
                                      <div class="new-account mt-3">
                                          <p>Vous avez déjà un compte? <a class="text-primary" [routerLink]="['/auth/login']">Se connecter</a></p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
      </div>
  </div>
  <!--**********************************
      Content body end
  ***********************************-->




