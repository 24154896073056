import { NgModule } from '@angular/core';
import { AngularFireAuthGuard, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { AccountUrlComponent } from './client/account-url/account-url.component';
import { DashboardComponent } from './client/dashboard/dashboard.component';
import { MyWalletComponent } from './client/my-wallet/my-wallet.component';
import { ProfileComponent } from './client/profile/profile.component';
import { TransactionsComponent } from './client/transactions/transactions.component';
import { HomeComponent } from './home/home.component';
import { EmailVerifiedComponent } from './mail/email-verified/email-verified.component';
import { NavComponent } from './nav/nav.component';
import { AuthGuardService } from './services/auth-guard.service';
import { UsersListeComponent } from './users-liste/users-liste.component';
import { map } from 'rxjs/operators';
import { UsersEditComponent } from './client/users-edit/users-edit.component';
import { OperatorListComponent } from './client/operators/operator-list/operator-list.component';
import { OperatorFormComponent } from './client/operators/operator-form/operator-form.component';
import { OperationFormComponent } from './client/operations/operation-form/operation-form.component';
import { OperationListComponent } from './client/operations/operation-list/operation-list.component';
import { OperatorEditComponent } from './client/operators/operator-edit/operator-edit.component';
import { OperationEditComponent } from './client/operations/operation-edit/operation-edit.component';
import { UserFormComponent } from './client/users/user-form/user-form.component';
import { UserListComponent } from './client/users/user-list/user-list.component';
import { OperatorDetailComponent } from './client/operators/operator-detail/operator-detail.component';
import { CaisseComponent } from './client/caisses/caisse/caisse.component';
import { CaisseListComponent } from './client/caisses/caisse-list/caisse-list.component';
import { CaisseEditComponent } from './client/caisses/caisse-edit/caisse-edit.component';
import { DepensesListComponent } from './client/depenses/depenses-list/depenses-list.component';
import { DepensesEditComponent } from './client/depenses/depenses-edit/depenses-edit.component';
import { DepensesFormComponent } from './client/depenses/depenses-form/depenses-form.component';
import { CarListComponent } from './client/cars/car-list/car-list.component';
import { CarFormComponent } from './client/cars/car-form/car-form.component';
import { CarEditComponent } from './client/cars/car-edit/car-edit.component';
import { DailyVersementComponent } from './client/cars/daily-versement/daily-versement.component';
import { DriveFormComponent } from './client/drivers/drive-form/drive-form.component';
import { DriveListComponent } from './client/drivers/drive-list/drive-list.component';
import { DriveEditComponent } from './client/drivers/drive-edit/drive-edit.component';
import { DailyVersementListComponent } from './client/cars/daily-versement-list/daily-versement-list.component';
import { DailyVersementEditComponent } from './client/cars/daily-versement-edit/daily-versement-edit.component';
import { DailyPointageComponent } from './client/cars/daily-pointage/daily-pointage.component';
import { DailyPaymentDetailComponent } from './client/cars/daily-payment-detail/daily-payment-detail.component';
import { CancelDebtComponent } from './client/cars/cancel-debt/cancel-debt.component';

const redirectLoggedInToDashboard = () => redirectLoggedInTo(['/caisse/operations']);
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['/auth/login']);
const redirectUnauthorizedOrNotAdminToDash = () =>
      map(
        (user:any) => {
          if (!user) {
            return ['/auth/login'];
          } else if(['admin@bouba.com','kady@iklassgestion.com','direction@iklassgestion.com','bureau1@iklassgestion.com'].includes(user.email)) {
            return true;
          }
          return ['/caisse/operations'];
        }
      );

const routes: Routes = [
  {
    path: 'caisse', component: AccountUrlComponent,canActivate: [AngularFireAuthGuard],
    data: {authGuardPipe: redirectUnauthorizedToLogin},  children: [
                   {
                     path: 'dashboard', component: DashboardComponent,
                     canActivate: [AngularFireAuthGuard],
                     data: {authGuardPipe: redirectUnauthorizedOrNotAdminToDash}


                    },
                   {
                     path: 'cash', component: CaisseListComponent


                    },
                   {
                     path: 'cash/new', component: CaisseComponent


                    },
                   {
                     path: 'cash/edit/:id', component: CaisseEditComponent


                    },


                   {
                     path: 'operators', component: OperatorListComponent,
                     canActivate: [AngularFireAuthGuard],
                      data: {authGuardPipe: redirectUnauthorizedOrNotAdminToDash}

                    },
                   {
                     path: 'operators/recharge/:id', component: OperatorDetailComponent,
                     canActivate: [AngularFireAuthGuard],
                      data: {authGuardPipe: redirectUnauthorizedOrNotAdminToDash}

                    },
                   {
                     path: 'operators/new', component: OperatorFormComponent,
                     canActivate: [AngularFireAuthGuard],
                      data: {authGuardPipe: redirectUnauthorizedOrNotAdminToDash}
                    },
                   {
                     path: 'operators/edit/:id', component: OperatorEditComponent,
                     canActivate: [AngularFireAuthGuard],
                      data: {authGuardPipe: redirectUnauthorizedOrNotAdminToDash}
                    },
                   {
                     path: 'operations', component: OperationListComponent
                    },
                   {
                     path: 'operations/edit/:id', component: OperationEditComponent
                    },
                   {
                     path: 'operations/new', component: OperationFormComponent
                    },
                   {
                     path: 'expenses', component: DepensesListComponent
                    },
                   {
                     path: 'expenses/new', component: DepensesFormComponent
                    },
                   {
                     path: 'expenses/edit/:id', component: DepensesEditComponent
                    },
                   {
                     path: 'drivers/new', component: DriveFormComponent
                    },
                   {
                     path: 'drivers/edit/:id', component: DriveEditComponent
                    },
                   {
                     path: 'drivers', component: DriveListComponent
                    },
                   {
                     path: 'cars', component: CarListComponent
                    },
                   {
                     path: 'cars/new', component: CarFormComponent
                    },
                   {
                     path: 'cars/edit/:id', component: CarEditComponent
                    },
                   {
                     path: 'cars/versements/new', component: DailyVersementComponent
                    },
                   {
                     path: 'cars/versements', component: DailyVersementListComponent
                    },
                   {
                     path: 'cars/details/versement/:id', component: DailyPaymentDetailComponent
                    },
                   {
                     path: 'cars/cancel/debt/:id', component:CancelDebtComponent
                    },
                   {
                     path: 'cars/versements/pointage', component: DailyPointageComponent
                    },
                   {
                     path: 'cars/versements/edit/:id', component: DailyVersementEditComponent
                    },

                   {
                     path: 'profile', component: ProfileComponent
                    },

                   {
                     path: 'users', component: UserListComponent,
                     canActivate: [AngularFireAuthGuard],
                      data: {authGuardPipe: redirectUnauthorizedOrNotAdminToDash}
                    },
                   {
                     path: 'users/new', component: UserFormComponent,
                     canActivate: [AngularFireAuthGuard],
                      data: {authGuardPipe: redirectUnauthorizedOrNotAdminToDash}
                    },
                   {
                     path: 'users/:id', component: UsersEditComponent
                    },
               ]
 },


  {
    path: 'auth/login', component: LoginComponent,
    canActivate: [AngularFireAuthGuard],
    data: {authGuardPipe: redirectLoggedInToDashboard}
  },

  {
    path: 'auth/register', component: RegisterComponent,
    canActivate: [AngularFireAuthGuard],
    data: {authGuardPipe: redirectUnauthorizedOrNotAdminToDash}

  },

  {
    path: 'reset/password', component: ResetPasswordComponent
  },

  {
    path: 'home', component: HomeComponent
  },

  {
    path:'', redirectTo: 'auth/login', pathMatch:'full'
  },

  {
    path:'**', redirectTo: 'auth/login'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
