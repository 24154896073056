<div class="content-body">
  <div class="container-fluid">

    <div class="authincation h-100">
      <div class="container vh-100">
          <div class="row justify-content-center h-100 align-items-center">
              <div class="col-lg-6 col-md-8">
                  <div class="authincation-content">
                      <div class="row no-gutters">
                          <div class="col-xl-12">
                              <div class="auth-form">
                                  <h4 class="text-center mb-4">Enregistrement d'un chauffeur</h4>
                                  <form [formGroup]="driverEditForm" (ngSubmit)="onSubmitDriverEditForm()">
                                    <!-- <div class="alert alert-danger"></div> -->
                                      <div class="form-group">
                                        <div class="alert alert-danger"
                                        *ngIf="(driverEditForm.controls.name.dirty
                                        || driverEditForm.controls.name.touched)
                                        && driverEditForm.controls.name.invalid
                                        && driverEditForm.controls.name.errors.required"
                                        > Le Nom du chauffeur est obligatoire.</div>
                                          <label class="mb-1"><strong>Nom du chauffeur</strong></label>
                                          <input type="text" class="form-control" placeholder="Nom du chauffeur" formControlName="name" >
                                      </div>
                                      <div class="form-group">
                                        <div class="alert alert-danger"
                                        *ngIf="(driverEditForm.controls.driverPhone.dirty
                                        || driverEditForm.controls.driverPhone.touched)
                                        && driverEditForm.controls.driverPhone.invalid
                                        && driverEditForm.controls.driverPhone.errors.required"
                                        > Le Numéro du chauffeur est obligatoire.</div>
                                          <label class="mb-1"><strong>Numéro du chauffeur</strong></label>
                                          <input type="text" class="form-control" placeholder="Numéro du chauffeur" formControlName="driverPhone" >
                                      </div>
                                      <div class="text-center mt-4">
                                          <button type="submit" class="btn btn-primary  btn-sm btn-block" [disabled]="driverEditForm.invalid" >Enregistrer</button>
                                      </div>
                                  </form>
                                  <div class="new-account mt-3">
                                    <p><a class="text-primary" [routerLink]="['/caisse/drivers']">Retour à la liste des chauffeurs</a></p>
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>


  </div>
</div>
