import { Recharge } from "./recharge";

export class Operator {
  id: string;
  name: string;
  balance: number;
  operatorPhone: string;
  recharges?: Recharge[];
  retraits?: Recharge[];
  photo?: string | null;
}
