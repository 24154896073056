<div class="content-body">
  <div class="container-fluid">

    <div class="authincation h-100">
      <div class="container vh-100">
          <div class="row justify-content-center h-100 align-items-center">
              <div class="col-lg-6 col-md-8">
                  <div class="authincation-content">
                      <div class="row no-gutters">
                          <div class="col-xl-12">
                              <div class="auth-form">
                                  <h4 class="text-center mb-4">Modification d'un véhicule </h4>
                                  <form [formGroup]="carEditForm" (ngSubmit)=" onSubmitCarEditForm()">
                                    <!-- <div class="alert alert-danger"></div> -->
                                      <div class="form-group">
                                        <div class="alert alert-danger"
                                        *ngIf="(carEditForm.controls.indexCar.dirty
                                        || carEditForm.controls.indexCar.touched)
                                        && carEditForm.controls.indexCar.invalid
                                        && carEditForm.controls.indexCar.errors.required"
                                        > L'Index du véhicule est obligatoire.</div>
                                          <label class="mb-1"><strong>Index du véhicule</strong></label>
                                          <input type="text" class="form-control" placeholder="Ex: V1" formControlName="indexCar">
                                      </div>
                                      <div class="form-group">
                                        <div class="alert alert-danger"
                                        *ngIf="(carEditForm.controls.brand.dirty
                                        || carEditForm.controls.brand.touched)
                                        && carEditForm.controls.brand.invalid
                                        && carEditForm.controls.brand.errors.required"
                                        > La Marque du véhicule est obligatoire.</div>
                                          <label class="mb-1"><strong>Marque du véhicule</strong></label>
                                          <select
                                          class="form-control text-primary"
                                          formControlName="brand"
                                          >
                                            <option value="" selected>Choisir</option>
                                            <option value="SUZIKI" >SUZIKI</option>
                                            <option value="TOYOTA" >TOYOTA </option>

                                          </select>
                                      </div>
                                      <div class="form-group">
                                        <div class="alert alert-danger"
                                        *ngIf="(carEditForm.controls.registerNumber.dirty
                                        || carEditForm.controls.registerNumber.touched)
                                        && carEditForm.controls.registerNumber.invalid
                                        && carEditForm.controls.registerNumber.errors.required"
                                        > Le Numéro d'immatriculation est obligatoire.</div>
                                          <label class="mb-1"><strong>Numéro d'immatriculation du véhicule</strong></label>
                                          <input type="text" class="form-control" placeholder="Ex: 0102AA01" formControlName="registerNumber">
                                      </div>
                                      <div class="form-group">
                                        <div class="alert alert-danger"
                                        *ngIf="(carEditForm.controls.driver1.dirty
                                        || carEditForm.controls.driver1.touched)
                                        && carEditForm.controls.driver1.invalid
                                        && carEditForm.controls.driver1.errors.required"
                                        > Le choix du premier chauffeur est obligatoire.</div>
                                        <label class="mb-1"><strong>Chauffeur 1</strong></label>
                                        <select
                                        class="form-control text-primary"
                                        formControlName="driver1"
                                        >
                                          <option value="" selected>Sélectionnez un chauffeur </option>
                                          <option *ngFor="let driver of availableDrivers" [value]="driver.id">{{ driver.name }}</option>
                                        </select>
                                    </div>
                                      <div class="form-group">
                                        <div class="alert alert-danger"
                                        *ngIf="(carEditForm.controls.driver2.dirty
                                        || carEditForm.controls.driver2.touched)
                                        && carEditForm.controls.driver2.invalid
                                        && carEditForm.controls.driver2.errors.required"
                                        > Le choix du deuxième chauffeur est obligatoire.</div>
                                        <label class="mb-1"><strong>Chauffeur 2</strong></label>
                                        <select
                                        class="form-control text-primary"
                                        formControlName="driver2"
                                        >
                                          <option value="" selected>Sélectionnez un chauffeur </option>
                                          <option *ngFor="let driver of availableDrivers" [value]="driver.id">{{ driver.name }}</option>
                                        </select>
                                    </div>
                                      <div class="text-center mt-4">
                                          <button type="submit" class="btn btn-primary  btn-sm btn-block" [disabled]="carEditForm.invalid" >Enregistrer</button>
                                      </div>
                                  </form>
                                  <div class="new-account mt-3">
                                    <p><a class="text-primary" [routerLink]="['/caisse/cars']">Retour à la liste des véhicules</a></p>
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
  </div>
</div>
