import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as $ from 'jquery';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';
import firebase from 'firebase/app';
import { TransactionService } from 'src/app/services/transaction.service';
import { Transaction } from 'src/app/models/transaction';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-my-wallet',
  templateUrl: './my-wallet.component.html',
  styleUrls: ['./my-wallet.component.css']
})
export class MyWalletComponent implements OnInit {
  cryptoCards = [
    {
      image: "assets/images/card/card4.jpg",
      icon_image: "assets/images/svg/icon5.svg",
      price: "$673,412.66",
      valid_thrugh: "01/31",
      name: "Marquezz Silalahi",

    },
    {
      image: "assets/images/card/card3.jpg",
      icon_image: "assets/images/svg/icon6.svg",
      price: "$673,412.66",
      valid_thrugh: "01/31",
      name: "Marquezz Silalahi",
    },

];



quickTransfer = [
    {
      name: "Samuel",
      username: "@sam224",
      image: "assets/images/contacts/Untitled-1.jpg",

    },
    {
      name: "Cindy",
      username: "@cindyss",
      image: "assets/images/contacts/Untitled-2.jpg",
    },
    {
      name: "David",
      username: "@davidxc",
      image: "assets/images/contacts/Untitled-3.jpg",
    },
    {
      name: "Martha",
      username: "@marthaa",
      image: "assets/images/contacts/Untitled-4.jpg",
    },
    {
      name: "Olivia",
      username: "@oliv62",
      image: "assets/images/contacts/Untitled-5.jpg",
    },
];
  transactionsSubscription: Subscription;
  transactions : any[] = [];
  depositForm: FormGroup;
  user: User;
  isAuth = false;


  constructor(
    private formBuilder : FormBuilder,
    private userService: UserService,
     private transactionService: TransactionService,
     private afa: AngularFireAuth
     ) { }

  ngOnInit(): void {
   

  }







}
