import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Car } from 'src/app/models/car';
import { Driver } from 'src/app/models/driver';
import { CarService } from 'src/app/services/car.service';
import { DriverService } from 'src/app/services/driver.service';
import { OperatorModalComponent } from '../../operators/operator-modal/operator-modal.component';

@Component({
  selector: 'app-car-list',
  templateUrl: './car-list.component.html',
  styleUrls: ['./car-list.component.css']
})
export class CarListComponent implements OnInit {

  cars: Car[] = [];
  availableDrivers: Driver[] = [];
  searchCars :Car[]= [];
  totalLength: number;
  page : number = 1;

  constructor(private carService: CarService,
     private driverService: DriverService,
     private modalService: NgbModal
     ) { }

  ngOnInit(): void {
    this.loadCars();
    this.loadAvailableDrivers();
  }

  loadCars(): void {
    this.carService.getAllCars().then((data) => {
      this.searchCars = this.cars = data;
      console.log(this.cars);

    });
  }
  loadAvailableDrivers(){
    this.driverService.getDrivers().subscribe((data)=>{
      this.availableDrivers = data;
    })
  }
  getDriverName(driverId: string): string{

      const driver = this.availableDrivers.find((d) => d.id === driverId);
      return driver ? driver.name : 'Inconnu';
    }
  getDriverPhone(driverId: string): string{

      const driver = this.availableDrivers.find((d) => d.id === driverId);
      return driver ? driver.driverPhone : 'Inconnu';
    }
    searchCar(query: string){
      this.searchCars = (query) ? this.cars.filter(car => car.brand.toLowerCase().includes(query.toLowerCase())
      || car.registerNumber.toLowerCase().includes(query.toLowerCase())
      || car.indexCar.toLowerCase().includes(query.toLowerCase())
      ): this.cars;
    }
    onDeleteCar(carId){
      const  confirmModal = this.modalService.open(OperatorModalComponent).result;
      confirmModal.then(()=>{
        this.carService.deleteCar(carId)
      .then(
        () =>{

          console.log('Supprimé');

        }
      )
      }).catch(
        (error)=>{

          console.log(error);

        }
      )
    }






  }






