import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Driver } from 'src/app/models/driver';
import { CarService } from 'src/app/services/car.service';
import { DriverService } from 'src/app/services/driver.service';

@Component({
  selector: 'app-drive-edit',
  templateUrl: './drive-edit.component.html',
  styleUrls: ['./drive-edit.component.css']
})
export class DriveEditComponent implements OnInit {

  driverEditForm: FormGroup;
  driverId: string;
  driver: Driver;
  errorMsg: string;

  constructor(
    private fb: FormBuilder,
    private driverService: DriverService,
    private carService: CarService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
  ) {

  }
  ngOnInit(): void {
    this.initDriveEditForm();
    this.driverId = this.route.snapshot.params.id;
    this.driverService.getByIdDriver(this.driverId).subscribe(
      (driver)=>{
        this.driver = driver
        this.driverEditForm.patchValue(driver);
        console.log(driver);
        

      });

    

   

  }



  initDriveEditForm(){
    this.driverEditForm = this.fb.group({
      name: ['', [Validators.required]],
      driverPhone: ['', [Validators.required]]
    });
  }


  onSubmitDriverEditForm(): void {
    if (this.driverEditForm.valid) {
      const driverEditData: Driver = this.driverEditForm.value;
      this.driverService.updateDriver(this.driverId,driverEditData).then(() => {
        console.log('Modifier !!!!!!!!');
        this.toastr.success('Le nom du chauffeur à été modifier avec succès.');
        this.router.navigate(['caisse/drivers']);
      }).catch(
        (errMsg) => {
          this.errorMsg = errMsg
        });
    }
  }

}
