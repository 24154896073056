<div class="modal-header">
  <h5 class="modal-title">
    SUPPRESSION
  </h5>
  <button type="button" class="close" 
  aria-label="Close button"
   aria-describedby="modal-title"
   (click)="modal.dismiss()"
   >
<span aria-hidden=""true>&times;</span>
  </button>
</div>
<div class="modal-body">
  <p> <strong>Êtes-vous sûr de bien vouloir effectuer cette action ?</strong></p>
  <p>
    <span class="text-danger"> Attention, cette action est irréversible</span>
  </p>
</div>
<div class="modal-footer">
    <button type="button" 
    (click)="modal.dismiss()"
    class="btn btn-outline-secondary btn-sm text-dark m-2"
    >Annuler</button>

    <button type="button"
    ngbAutofocus
     (click)="modal.close()"
     class="btn btn-danger btn-sm m-2"
     >Supprimer</button>
</div>