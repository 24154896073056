 <!--**********************************
        Content body start
    ***********************************-->
    <div class="content-body">
      <div class="container-fluid">
          <div class="form-head mb-lg-5 mb-3 d-flex flex-wrap align-items-center">
              <h2 class="font-w600 mb-3 mr-auto ">La caisse </h2>
              <!-- <a routerLink="/caisse/operators/new" class="btn btn-outline-primary btn-sm mb-2 m-2"><i class="fa fa-plus m-2" aria-hidden="true"></i>Approvisionner</a> -->
              <div>
                <a routerLink="/caisse/expenses/new" class="btn btn-secondary text-white btn-sm mb-2 m-2" ><i class="fa fa-plus m-2" aria-hidden="true"></i> Enregistrer une dépense</a>
                <a routerLink="/caisse/cash/new" class="btn btn-secondary text-white btn-sm mb-2 m-2"><i class="fa fa-plus m-2" aria-hidden="true"></i> Enregistrer le fond de caisse</a>
              </div>

          </div>
          <div class="row sp-sm-15">
            <div class="col-xl-2 col-3 m-t35">
                <div class="card card-coin">
                    <div class="card-body text-center">
                      <!-- <img src="{{item.image}}" class="mb-sm-3 mb-2 currency-icon"/> -->
                        <h4 *ngIf="cashRevenue >= 0" class="text-success"><span class=" text-success">Cash du jour</span></h4>
                        <h3 *ngIf="cashRevenue >= 0" class="text-black mb-2 font-w500" ><span class=" text-success">{{cashRevenue | currency:'FCFA':'symbol':'1.0-0':'fr'}} </span></h3>
                        <h4 *ngIf="cashRevenue <0" class="text-danger"><span class=" text-danger">Cash du jour</span></h4>
                        <h3 *ngIf="cashRevenue <0" class="text-black mb-2 font-w500" ><span class=" text-danger">{{cashRevenue | currency:'FCFA':'symbol':'1.0-0':'fr'}} </span></h3>
                        <p class="mb-0 fs-14">
                            <!-- <span class="text-success mr-1"> UVE</span> -->
                            <!-- <span class="text-warning mr-1"*ngIf="operator.balance == 100000"> <marquee>PENSEZ À RECHARGER</marquee> </span> -->
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-3 m-t35">
                <div class="card card-coin">
                    <div class="card-body text-center">
                      <!-- <img src="{{item.image}}" class="mb-sm-3 mb-2 currency-icon"/> -->
                        <h4> <span  class=" text-warning">Dette du jour</span></h4>
                        <h3 class=" mb-2 font-w500" ><span class=" text-warning">{{getTotalDette() | currency:'FCFA':'symbol':'1.0-0':'fr'}}</span></h3>
                        <p class="mb-0 fs-14">
                            <!-- <span class="text-success mr-1"> UVE</span> -->
                            <!-- <span class="text-warning mr-1"*ngIf="operator.balance == 100000"> <marquee>PENSEZ À RECHARGER</marquee> </span> -->
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-3 m-t35">
                <div class="card card-coin">
                    <div class="card-body text-center">
                      <!-- <img src="{{item.image}}" class="mb-sm-3 mb-2 currency-icon"/> -->
                        <h4> <span  class=" text-warning">dépenses du jour</span></h4>
                        <h3 class=" mb-2 font-w500" ><span class=" text-warning">{{dailyExpenseRevenue | currency:'FCFA':'symbol':'1.0-0':'fr'}}</span></h3>
                        <p class="mb-0 fs-14">
                            <!-- <span class="text-success mr-1"> UVE</span> -->
                            <!-- <span class="text-warning mr-1"*ngIf="operator.balance == 100000"> <marquee>PENSEZ À RECHARGER</marquee> </span> -->
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-3 m-t35">
                <div class="card card-coin" *ngFor="let caisse of caisses" >
                    <div class="card-body text-center" *ngIf="caisse.balance">
                      <!-- <img src="{{item.image}}" class="mb-sm-3 mb-2 currency-icon"/> -->
                        <h4 >Fond de caisse du jour</h4>
                        <h3 class="text-black mb-2 font-w500" ><span >{{ caisse.balance | currency: 'FCFA': 'symbol': '1.0-0': 'fr'}} </span></h3>
                        <p class="mb-0 fs-14">
                            <!-- <span class="text-success mr-1"> UVE</span> -->
                            <!-- <span class="text-warning mr-1"*ngIf="operator.balance == 100000"> <marquee>PENSEZ À RECHARGER</marquee> </span> -->
                        </p>
                    </div>
                </div>
            </div>

        </div>
          <div class="row">
              <div class="col-xl-9 col-xxl-8">

                <div class="table-responsive">
                  <table class="table table-striped">
                    <thead>
                    <tr>
                    <th scope="col">#</th>
                    <th scope="col">Fond de caisse en (FCFA)</th>
                    <th scope="col">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let caisse of caisses; index as i">
                    <th scope="row">{{i + 1}}</th>
                    <td>{{ caisse.balance | currency: 'FCFA': 'symbol': '1.0-0': 'fr'}} </td>
                    <td>
                      <a routerLink="/caisse/cash/edit/{{caisse.id}}" class="btn btn-outline-primary btn-xxs  m-1"><i class="fa fa-pencil-square-o m-1" aria-hidden="true"></i>Modifier</a>
                      <a (click)="onClickDeleteCaisse(caisse.id)" class="btn btn-outline-danger btn-xxs  m-1"><i class="fa fa-trash-o m-1" aria-hidden="true"></i>Supprimer</a>
                    </td>
                    </tr>
                    </tbody>
                  </table>
                  <!-- <div class="float-left font-weight-bold mt-3">
                    TOTAL DES UVE : {{ total | currency: 'FCFA': 'symbol': '1.0-0': 'fr' }}
                   </div> -->
                </div>

                <!-- <div class="d-sm-flex d-block justify-content-between p-2">
                  <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" (pageChange)="refreshCountries()">
                  </ngb-pagination>

                  <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="refreshCountries()">
                    <option [ngValue]="2">2 opérateurs page</option>
                    <option [ngValue]="4">4 opérateurs page</option>
                    <option [ngValue]="6">6 opérateurs page</option>
                  </select>
                </div> -->
                  <!-- <app-graph-market-overview></app-graph-market-overview> -->
              </div>
              <div class="col-xl-3 col-xxl-4">
                  <!-- <app-graph-current-statistic></app-graph-current-statistic> -->
                  <!-- <div class="card">
                    <div class="card-header border-0 pb-0">
                        <h4 class="mb-0 text-black fs-20">La liste des cash du jour</h4>
                    </div>
                    <div class="card-body p-3 pb-0">
                        <div class="table-responsive">
                            <table class="table text-center bg-warning-hover tr-rounded order-tbl">
                                <thead>
                                    <tr>
                                        <th>Jours</th>
                                        <th >Montant (FCFA)</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let dailyRevenue of calculateDailyTotalRevenueWithoutWithdrawalss() | paginate: { itemsPerPage: 3, currentPage: page, totalItems :totalLength } ; index as i">
                                        <td >{{ dailyRevenue.day }} </td>
                                        <td>{{ dailyRevenue.revenue | currency:'XOF':'symbol':'1.0-0':'fr' }} </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div>
                              <div class="d-flex justify-content-between p-2">
                                <pagination-controls class="mr-3" (pageChange)="page = $event" ></pagination-controls>

                              </div>
                             </div>
                        </div>
                    </div>
                    <div class="card-footer border-0 p-0 caret">
                        <a [routerLink]="['/admin/coin-details']" class="btn-link"><i class="fa fa-caret-down" aria-hidden="true"></i></a>
                    </div>
                </div> -->
              </div>
          </div>
          <div class="row">
              <div class="col-xl-6 col-xxl-12">
                  <!-- <app-quick-trade></app-quick-trade> -->
              </div>
              <div class="col-xl-6 col-xxl-12">
                  <div class="row">
                      <!-- <div class="col-sm-6" *ngFor="let item of cryptoCards">
                          <div class="card-bx stacked card">
                              <img class="bg-img" src="{{item.image}}" alt="">
                              <div class="card-info">
                                  <p class="mb-1 text-white fs-14">Main Balance</p>
                                  <div class="d-flex justify-content-between align-items-start">
                                      <h2 class="num-text text-white mb-5 font-w600">{{item.price}}</h2>
                                      <img src="{{item.icon_image}}" alt="">
                                  </div>
                                  <div class="d-flex">
                                      <div class="mr-4 text-white">
                                          <p class="fs-12 mb-1 op6">VALID THRU</p>
                                          <span>{{item.valid_thrugh}}</span>
                                      </div>
                                      <div class="text-white">
                                          <p class="fs-12 mb-1 op6">CARD HOLDER</p>
                                          <span>{{item.name}}</span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div> -->
                      <div class="col-xl-12">
                         <!-- <app-recent-trading-activities></app-recent-trading-activities> -->
                      </div>
                      <div class="col-xl-12 mt-2">
                          <!-- <app-quick-transfer-crousal [data]="quickTransfer"></app-quick-transfer-crousal> -->

                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <!--**********************************
      Content body end
  ***********************************-->


