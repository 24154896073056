import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { User } from '../models/user';
import firebase from 'firebase/app';
import { ToastrService } from 'ngx-toastr';
import DataSnapshot = firebase.database.DataSnapshot;
import { HttpClient } from '@angular/common/http';
import { catchError, map, retry } from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import {AngularFireAuth} from "@angular/fire/auth";


const apiUrl = 'https://gestion-784b3-default-rtdb.europe-west1.firebasedatabase.app/users.json';
const apiUrlId = 'https://gestion-784b3-default-rtdb.europe-west1.firebasedatabase.app/users/${id}.json';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  users: User[] = [];
  usersSubject = new Subject<User[]>();
  userData: any;
  isAuth = false;

  private usersCollection: AngularFirestoreCollection<User>;
  users$ : Observable<User[]>;


  constructor(private toastr: ToastrService,
    private http: HttpClient,
    private afs: AngularFirestore,
              private afAuth: AngularFireAuth
     ) {
    firebase.auth().onAuthStateChanged(
      (user) => {
      if (user) {
       this.userData = user;
       localStorage.setItem('user', JSON.stringify(this.userData));
       JSON.parse(localStorage.getItem('user'));
      } else {
        localStorage.setItem('user', null);
        JSON.parse(localStorage.getItem('user'));
      }
    });
    this.usersCollection = this.afs.collection<User>('users');
    this.users$ = this.usersCollection.valueChanges();

  }
//  emettre les données d'utilisateur


  // Sauvegarder les données d'utilisateur dans la base donée

  createUser(user:User){
    // firebase.database().ref('users/'+ firebase.auth().currentUser.uid).set(this.users);
    return this.usersCollection.add(user);

  }

  //  Obtenir tout les utilisateurs
  //  getUsers(): any{
  //   return this.http.get<{[id: string]:User }>(apiUrl)
  //                   .pipe(
  //                     map(users => {
  //                       let userDa: User[] = [];
  //                       for(let id in users) {
  //                         userDa.push({... users[id], id});
  //                       }
  //                       return userDa;
  //                     })
  //                   );
  //  }
   getAllUsers(): Observable<User[]>{
    return this.users$;
  }

  //  Obtenir un utilisateur
  // getUser() {
  //   if (this.isAuth = true) {
  //     return new Promise (
  //       (resolve, reject) => {
  //         firebase.database().ref('/users/' + firebase.auth().currentUser.uid).once('value')
  //         .then(
  //           (data: DataSnapshot) =>{
  //             resolve(data.val());
  //           }
  //         ).catch(
  //           (error)=> {
  //             reject(error)
  //           }
  //         )
  //       }
  //     );
  //   }
  //   }
  getUserById(userId: string): Observable<User>{
    return this.usersCollection.doc<User>(userId).valueChanges();
  }

    //  Obtenir un utilisateur par ID
    // getUserById(id): Observable<User> {
    //   return this.http.get<User>(`https://gestion-784b3-default-rtdb.europe-west1.firebasedatabase.app/users/${id}.json`);

    //   }

//  creation d'un utilisateur

  // createUser(user: User){
  //   this.users.push(user);
  //   this.saveUsers();
  //   this.emailVerified();
  //   this.emitUsers();
  // }

  // Met à jour Profil  d'utilisateur
  // updateUser(user:User){
  //   firebase.database().ref('/users/' + firebase.auth().currentUser.uid ).update(user).catch(
  //     (error) => {
  //       console.error(error);
  //     }
  //   )
  // }

  updateUser(user:User){
    const id = user.id;
    delete user.id;
    return this.usersCollection.doc(id).update(user);

  }
  editUser(userId: string,user:User): Promise<void> {
    return this.usersCollection.doc(userId).update(user);
  }

   // Met à jour les donnée d'utilisateur
  updatedepot(id,user:User){
  firebase.database().ref('/users/' +id).update(user).catch(
      (error) => {
        console.error(error);
      }
    )



  }

  // Envoie de mail de verification

  emailVerified(){
  firebase.auth().currentUser.sendEmailVerification()
  .then(
    () => {
    // Email verification sent!
    // ...
    this.toastr.info('Vérification par e-mail envoyée !')
  });
}

// Uploaded une image

uploadFile(file:File){
   return new Promise (
     (resolve, reject) => {
       const uniqueId = Date.now().toString();
       const fileName = uniqueId + file.name;
       const upload = firebase.storage().ref().child('img/profile/' + fileName).put(file);
       upload.on(firebase.storage.TaskEvent.STATE_CHANGED,
          () => {
            console.log('Chargement...!');

          },
          (error) => {
            console.error(error);
            reject(error);
          },
          () => {
            upload.snapshot.ref.getDownloadURL().then(
              (downLoadUrl) => {
                resolve(downLoadUrl);
              }
            );
          }
        );
     }
   );
}
// Suppression d'une image
removeFile(fileLink: string){
  if (fileLink) {
    const storageRef = firebase.storage().refFromURL(fileLink);
    storageRef.delete().then(
      () => {
        console.log('ficher supprimer');

      }
    ).catch(
      (error) => {
        console.error(error);
      }
    )

  }

}


blockUser(userId: string): Promise<void> {
  return this.usersCollection.doc(userId).update({ isBlocked: true });
}

unblockUser(userId: string) {
  return this.usersCollection.doc(userId).update({ isBlocked: false });
}









}

