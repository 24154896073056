<div class="wrapper">
    <div class="container">
          <div class="row">
              <div class="col-md-1">

              </div>
              <div class="col-md-11">
                <div class="table-responsive mt-5">
                  <table class="table table-striped">
                    <thead>
                    <tr>
                    <th scope="col">#</th>
                    <th scope="col">UID</th>
                    <th scope="col">Prénom</th>
                    <th scope="col">Nom</th>
                    <th scope="col">Email</th>
                    <th scope="col">Téléphone</th>
                    <th scope="col">Solde</th>
                    <th scope="col">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let user of users; index as i">
                    <th scope="row">{{i + 1}}</th>
                    <!-- <td>
                      <img [src]="'https://upload.wikimedia.org/wikipedia/commons/' + country.flag" class="mr-2" style="width: 20px">
                      {{ country.name }}
                    </td> -->
                    <td>{{user.id}}</td>
                    <td>{{user.firstName}}</td>
                    <td>{{user.lastName}}</td>
                    <td>{{user.email}}</td>
                    <td>{{user.phoneNumber}}</td>
                    <td>{{user.balance}} FCFA </td>
                    <td>
                      <a routerLink="/users/{{user.id}}" data-toggle="modal" data-target="#exampleModal" class="btn btn-outline-primary btn-xxs  m-1"><i class="fa fa-pencil-square-o m-1" aria-hidden="true"></i>Modifier</a>
                      <a routerLink="#" class="btn btn-outline-primary btn-xxs mt-2 mb-2">Dépot ou Retrait</a>
                      <!-- <a routerLink="#" class="btn btn-outline-primary btn-xxs"><i class="fa fa-info-circle m-1" aria-hidden="true"></i>Details</a> -->

                    </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
          </div>
    </div>
</div>


<!-- Button trigger modal -->
<button type="button" class="btn btn-primary btn-xs"  data-toggle="modal" data-target="#exampleModal">
  Launch demo modal
</button>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        ...
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-xs" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary btn-xs">Save changes</button>
      </div>
    </div>
  </div>
</div>
