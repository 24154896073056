<div class="modal-header">
  <h4 class="modal-title">Réchargemement UVE - {{operator.name}}</h4>
  <button type="button" class="close" (click)="activeModal.dismiss()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="rechargeForm" (ngSubmit)=" rechargeBalance()">
    <div class="form-group">
      <label for="amount">Montant à récharger</label>
      <input type="number" id="amount" formControlName="amount" class="form-control" placeholder="Entrer le montant à récharger" >
    </div>
    <button type="submit" class="btn btn-primary btn-sm" [disabled]="rechargeForm.invalid">Éffectuer le rechargement</button>
  </form>
</div>
