import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Caisse } from 'src/app/models/caisse';
import { Expense } from 'src/app/models/expense';
import { User } from 'src/app/models/user';
import { CaisseService } from 'src/app/services/caisse.service';
import { ExpenseService } from 'src/app/services/expense.service';
import { TransactionService } from 'src/app/services/transaction.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-depenses-form',
  templateUrl: './depenses-form.component.html',
  styleUrls: ['./depenses-form.component.css']
})
export class DepensesFormComponent implements OnInit {

  expenseForm : FormGroup;
  errorMsg: string;
  expenseId: string;
  fundId: string;
  isAuth = false;
  user: User;
  cashRevenue: number=0;
  today:Date;

  constructor(private formBuilder : FormBuilder,
              private caisseService:CaisseService,
              private expenseService: ExpenseService,
              private transactionService : TransactionService,
              private router: Router,
              private toastr: ToastrService,
              public userService: UserService,
              private afa: AngularFireAuth // Injectez AngularFireAuth
              ) { }

  ngOnInit(): void {
    this.initExpenseForm();
    this.getUser();
    this.today = new Date();
    this.calculateCashRevenue();
    this.expenseService.getAllExpense().subscribe((funds:Expense[]) => {
      if (funds && funds.length > 0) {
        this.expenseId = funds[0].id;
      }
    });

    this.caisseService.getAllCaisse().subscribe(funds => {
      if (funds && funds.length > 0) {
        this.fundId = funds[0].id;
      }
    });
   
  }




  getUser(){
    this.afa.authState.subscribe(
      (user) =>{
        if (user) {
          this.isAuth = true;
          console.log(this.isAuth);
          this.userService.getUserById(user.uid).subscribe(
            (userData) => {
              this.user = userData;
            })
        }else{
          this.isAuth = false;
         console.log(this.isAuth);

        }
      });
  }

  initExpenseForm(){
    this.expenseForm = this.formBuilder.group({
      label: ['', [Validators.required]],
      amount: ['', [Validators.required,Validators.pattern(/^[0-9]*$/)]]
    });
  }
  calculateCashRevenue():any {
   
    this.transactionService.getCashRevenue(this.today)
      .then((cashRevenue) =>{
        this.cashRevenue = cashRevenue;
        
        console.log(cashRevenue);
        

      });
  }

  onSubmitExpenseForm(){
    this.errorMsg = null;
    const expenseData: Expense = this.expenseForm.value;
        // caisseData.amount = this.caisseForm.value.amount.replace(/\s/g, '');
        expenseData.date = new Date();
        expenseData.userName = this.user.firstName;
        const amount = expenseData.amount;
        // if (this.fundId) {
        //   this.caisseService.updateExpenseBalance(this.fundId,amount);
        // }
       // this.calculateCashRevenue()
       
       
        
      

    this.expenseService.CreateExpense(expenseData)
                      .then(
                        () => {
                          this.caisseService.updateExpenseBalance(this.fundId,amount);
                          this.router.navigate(['caisse/expenses']);
                          console.log('Dépense enregistrée avec succès');
                          this.toastr.success('Dépense enregistrée avec succès.');

                        })
                      .catch(
                        (errMsg) => {
                          this.errorMsg = errMsg
                        })

  }

  

}
