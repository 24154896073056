import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Test } from 'src/app/models/test';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-users-edit',
  templateUrl: './users-edit.component.html',
  styleUrls: ['./users-edit.component.css']
})
export class UsersEditComponent implements OnInit {
  nameForm: FormGroup;
  names: Test[] = [];
  selectedName: Test | null = null;

  constructor(private fb: FormBuilder) {
    this.nameForm = this.fb.group({
      name: ['', Validators.required]
    });
  }

  ngOnInit(): void {
   
  }


}
