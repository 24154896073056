import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  signInForm: FormGroup;
  errorMsg: string;

  constructor(private formBuilder: FormBuilder,
    private userService: UserService,
    private authService: AuthService,
    private router : Router,
    private toastr: ToastrService
    ) { }

  ngOnInit(): void {
    this.initUsers();
  }
  
  initUsers(){
    this.signInForm = this.formBuilder.group({
      email: ['', [Validators.email,Validators.required]],
      password: ['', [Validators.required, Validators.pattern(/[0-9a-zA-Z]{6,}/)]],

    });
  }

  onSubmitSignInForm(){
    const email = this.signInForm.get('email').value;
    const password = this.signInForm.get('password').value;
    this.authService.signInUser(email, password).then(
      (data: User[]) => {
        this.toastr.success('Vous êtes connecté !!!');

        console.log('Vous êtes connecté !!!');

        this.router.navigate(['/caisse/operations']);


      }
    ).catch(
      (errMsg) => this.errorMsg = errMsg
    )



  }


}
