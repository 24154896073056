<div class="modal-header">
  <h4 class="modal-title">Remboursement de la dette - {{ calculateDriverDebt(dailyPayment.amount)| currency: 'FCFA': 'symbol': '1.0-0': 'fr' }} </h4>
  <button type="button" class="close" (click)="activeModal.dismiss()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="debtForm" (ngSubmit)=" payDebtForm()">
    <div class="form-group">
      <label for="amount">Montant de la dette</label>
      <input type="number" id="amount" formControlName="debtAmount"  class="form-control" placeholder="Entrer le montant de la dette à payer" >
    </div>
    <button type="submit" class="btn btn-primary btn-sm" [disabled]="debtForm.invalid">Payer {{ calculateDriverDebt(dailyPayment.amount)| currency: 'FCFA': 'symbol': '1.0-0': 'fr' }} </button>
  </form>
</div>
