<div class="content-body">
  <div class="container-fluid">

    <div class="authincation h-100">
      <div class="container vh-100">
          <div class="row justify-content-center h-100 align-items-center">
              <div class="col-lg-6 col-md-8">
                  <div class="authincation-content">
                      <div class="row no-gutters">
                          <div class="col-xl-12">
                              <div class="auth-form">
                                  <h4 class="text-center mb-4">Enregistrer un versement journalier </h4>
                                  <form [formGroup]="versementForm " (ngSubmit)="onSubmitVersementForm()">
                                    <!-- <div class="alert alert-danger"></div> -->
                                      <div class="form-group">
                                        <div class="alert alert-danger"
                                        *ngIf="(versementForm.controls.carId.dirty
                                        || versementForm.controls.carId.touched)
                                        && versementForm.controls.carId.invalid
                                        && versementForm.controls.carId.errors.required"
                                        > Le choix du véhicule est obligatoire.</div>
                                        <label class="mb-1"><strong>Véhicule</strong></label>
                                        <select
                                        class="form-control text-primary"
                                        formControlName="carId"
                                        (change)="onCarSelectionChange()"
                                        >
                                          <option value="" selected>Sélectionnez un véhicule  </option>
                                          <option *ngFor="let car of availableCars" [value]="car.id">{{ car.indexCar}}-{{ car.registerNumber }}</option>
                                        </select>
                                    </div>
                                      <div class="form-group">
                                        <div class="alert alert-danger"
                                        *ngIf="(versementForm.controls.driverId.dirty
                                        || versementForm.controls.driverId.touched)
                                        && versementForm.controls.driverId.invalid
                                        && versementForm.controls.driverId.errors.required"
                                        > Le choix d'un chauffeur est obligatoire.</div>
                                        <label class="mb-1"><strong>Chauffeur </strong></label>
                                        <select
                                        class="form-control text-primary"
                                        formControlName="driverId"
                                        >
                                          <option value="" disabled>Sélectionnez un chauffeur </option>
                                          <option *ngFor="let driver of availableDriversForSelectedCar" [value]="driver.id">{{ driver.name }}</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                      <div class="alert alert-danger"
                                      *ngIf="(versementForm.controls.amount.dirty
                                      || versementForm.controls.amount.touched)
                                      && versementForm.controls.amount.invalid
                                      && versementForm.controls.amount.errors.required"
                                      > Le Montant du versement est obligatoire.</div>
                                        <label class="mb-1"><strong>Montant du versement (FCFA)</strong></label>
                                        <input type="text" class="form-control" placeholder="Ex: 26 000" formControlName="amount">
                                    </div>
                                      <div class="text-center mt-4">
                                          <button type="submit" class="btn btn-primary  btn-sm btn-block" [disabled]="versementForm.invalid" >Enregistrer</button>
                                      </div>
                                  </form>
                                  <div class="new-account mt-3">
                                    <p><a class="text-primary" [routerLink]="['/caisse/cars/versements']">Retour à la liste des versements</a></p>
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
  </div>
</div>
