import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Operator } from 'src/app/models/operator';
import { OperatorService } from 'src/app/services/operator.service';

@Component({
  selector: 'app-operator-edit',
  templateUrl: './operator-edit.component.html',
  styleUrls: ['./operator-edit.component.css']
})
export class OperatorEditComponent implements OnInit {
  operatorEditForm:FormGroup;
  errorMsg: string;
  operator : Operator;
  operatorId: string;
  photoUploading = false;
  photoUploaded = false;
  photoUrl: string;

  constructor(private formBuilder: FormBuilder,
    private operatorService: OperatorService,
    private router: Router,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private route: ActivatedRoute)
     {
      // this.operator = new Operator('','','');
     }

  ngOnInit(): void {

    this.operatorId = this.route.snapshot.params.id;
    this.operatorService.getByIdOperator(this.operatorId).subscribe(
      (operator)=> {
        this.operator = operator
        this.operatorEditForm.patchValue(operator);
      }
    )
    this.initOperatorEditForm();
  }

  initOperatorEditForm(){
    this.operatorEditForm = this.formBuilder.group({
      name: ['',[Validators.required]],
      balance: ['',[Validators.required, Validators.min(0),Validators.pattern(/^[0-9]*$/)]],
      operatorPhone: ['',[Validators.required]],
      photo: ['']
    })
  }

  onSubmitOperatorEditForm(){
    this.errorMsg = null;
    const operatorEditData: Operator = this.operatorEditForm.value;
        // operatorEditData.balance = this.operatorEditForm.value.replace(/\s/g, '');
        operatorEditData.photo = this.photoUrl ? this.photoUrl : '';
    this.operatorService.editOperator(this.operatorId,operatorEditData)
                      .then(
                        () => {
                          this.router.navigate(['caisse/operators']);
                          console.log('Modifier avec Success');
                          this.toastr.success('l\'Operateur à été modifier avec succès.');


                        })
                      .catch(
                        (errMsg) => {
                          this.errorMsg = errMsg
                        })

  }

  onUploadFile(event){
    this.photoUploading = true;

    this.operatorService.uploadFile(event.target.files[0]).then(
      (url: string) => {
        if (this.photoUrl && this.photoUrl !== '') {
          this.operatorService.removeFile(this.photoUrl);
        }
        this.photoUrl =  url;
        this.photoUploading = false;
        this.photoUploaded = true;
        setTimeout(
          () => {
            this.photoUploaded = true;
          }, 5000
        );
      }
    )
    console.log(event);

  }
  open(modelId:any) {
		this.modalService.open(modelId);
	}
  resetForm(){
    this.photoUrl = '';
  }

}
