<div class="content-body">
  <div class="container-fluid">
    <div class="authincation h-100">
      <div class="container vh-100">
          <div class="row justify-content-center h-100 align-items-center">
              <div class="col-lg-6 col-md-8">
                  <div class="authincation-content">
                      <div class="row no-gutters">
                          <div class="col-xl-12">
                              <div class="auth-form">
                                  <h4 class="text-center mb-4">Enregistrement de fond de caisse</h4>
                                  <form [formGroup]="caisseForm" (ngSubmit)="onSubmitcaisseBalanceForm()">
                                      <div class="form-group">
                                        <div class="alert alert-danger"
                                        *ngIf="(caisseForm.controls.balance.dirty
                                        || caisseForm.controls.balance.touched)
                                        && caisseForm.controls.balance.invalid
                                        && caisseForm.controls.balance.errors.required"
                                        > Le Fond de caisse est obligatoire.</div>
                                        <label class="mb-1"><strong>Fond de caisse</strong></label>
                                        <input type="text" class="form-control" placeholder="Fond de caisse" formControlName="balance">
                                    </div>
                                      <div class="text-center mt-4">
                                          <button type="submit" class="btn btn-primary  btn-sm btn-block" [disabled]="caisseForm.invalid" >Enregistrer</button>
                                      </div>
                                  </form>
                                  <div class="new-account mt-3">
                                    <p><a class="text-primary" [routerLink]="['/caisse/cash']">Retour à la liste des fonds de caisse</a></p>
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>


  </div>
</div>
