import { Injectable } from '@angular/core';
import { Operator } from '../models/operator';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { Observable, ReplaySubject } from 'rxjs';
import { TransactionService } from './transaction.service';
import firebase from 'firebase/app';
import { Recharge } from '../models/recharge';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class OperatorService {

  private operatorsCollection: AngularFirestoreCollection<Operator>;

  constructor(private afs: AngularFirestore,
      private transactionService : TransactionService,
      private toastr: ToastrService
    ) {
    this.operatorsCollection = this.afs.collection<Operator>('operators');
  }

  getAllOperator(): Observable<Operator[]> {
    return this.operatorsCollection.snapshotChanges().pipe(
      map(operators =>
        operators.map(a => {
          const data = a.payload.doc.data() as Operator;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      )
    );
  }
  getByIdOperator(operatorId: string): Observable<Operator | undefined> {
    return this.operatorsCollection.doc<Operator>(operatorId).valueChanges();
  }

  CreateOperator(operator: Operator): Promise<any> {
    return this.operatorsCollection.add(operator);
  }

  editOperator(operatorId: string, operator: Operator): Promise<void> {
    return this.operatorsCollection.doc(operatorId).update(operator);
  }

  deleteTransaction(operatorId: string): Promise<void> {
    return this.operatorsCollection.doc(operatorId).delete();
  }

  delete(operatorId){
    return new Promise (
      (resolve,reject) => {
        this.transactionService.deleteWhereTransaction('operatorId', '==', operatorId)
                          .then(
                            ()=>{
        this.afs.collection('operators')
                    .doc(operatorId)
                    .delete()
                    .then(
                      (operator) => {
                        resolve(operator);
                        console.log('supprimer avec success');

                      }).catch(
                      (error) => {
                        reject(error)
                      })
                            })

      });
  }


  // Uploaded une image

uploadFile(file:File){
  return new Promise (
    (resolve, reject) => {
      const uniqueId = Date.now().toString();
      const fileName = uniqueId + file.name;
      const upload = firebase.storage().ref().child('img/operators/' + fileName).put(file);
      upload.on(firebase.storage.TaskEvent.STATE_CHANGED,
         () => {
           console.log('Chargement...!');
           this.toastr.success('Image charger  avec succès !!!!');

         },
         (error) => {
           console.error(error);
           reject(error);
         },
         () => {
           upload.snapshot.ref.getDownloadURL().then(
             (downLoadUrl) => {
               resolve(downLoadUrl);
             }
           );
         }
       );
    }
  );
}
// Suppression d'une image
removeFile(fileLink: string){
 if (fileLink) {
   const storageRef = firebase.storage().refFromURL(fileLink);
   storageRef.delete().then(
     () => {
       console.log('ficher supprimer');

     }
   ).catch(
     (error) => {
       console.error(error);
     }
   )

 }

}

  async rechargeOperator(id: string, newBalance: number, rechargeAmount: number): Promise<string> {
    if (id && typeof newBalance === 'number' && typeof rechargeAmount === 'number') {
      const recharge: Recharge = {
        amount: rechargeAmount,
        date: new Date()
      };

      const operatorRef = this.afs.collection<Operator>('operators').doc(id).ref;

      try {
        const operatorSnapshot = await operatorRef.get();
        const operatorData = operatorSnapshot.data() as Operator;

        // Assurez-vous que operatorData.recharges est un tableau (s'il n'existe pas, initialisez-le)
        const existingRecharges = Array.isArray(operatorData.recharges) ? operatorData.recharges : [];

        // Ajoutez la nouvelle recharge
        const updatedRecharges = [...existingRecharges, recharge];

        // Mettez à jour les données de l'opérateur
        await operatorRef.update({
          balance: newBalance,
          recharges: updatedRecharges
        });

        return 'Recharge successful';
      } catch (error) {
        console.error('Error recharging operator:', error);
        throw error;
      }
    } else {
      console.error('Invalid data for recharge');
      throw new Error('Invalid data for recharge');
    }
  }

  // methode de retrait

  async retraitOperator(id: string, newBalance: number, retraitAmount: number): Promise<string> {
    if (id && typeof newBalance === 'number' && typeof retraitAmount === 'number') {
      const retrait: Recharge = {
        amount: retraitAmount,
        date: new Date()
      };

      const operatorRef = this.operatorsCollection.doc(id).ref;

      try {
        const operatorSnapshot = await operatorRef.get();
        const operatorData = operatorSnapshot.data() as Operator;

        // Assurez-vous que operatorData.retraits est un tableau (s'il n'existe pas, initialisez-le)
        const existingRetraits = Array.isArray(operatorData.retraits) ? operatorData.retraits : [];

        // Ajoutez la nouvelle retrait
        const updatedRetraits = [...existingRetraits, retrait];

        // Mettez à jour les données de l'opérateur
        await operatorRef.update({
          balance: newBalance,
          retraits: updatedRetraits
        });

        return 'Retrait successful';
      } catch (error) {
        console.error('Error retraiting operator:', error);
        throw error;
      }
    } else {
      console.error('Invalid data for retrait');
      throw new Error('Invalid data for retrait');
    }
  }








}
