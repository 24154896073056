
<div class="authincation h-100">
  <div class="container vh-100">
      <div class="row justify-content-center h-100 align-items-center">
          <div class="col-lg-6 col-md-8">
              <div class="authincation-content">
                  <div class="row no-gutters">
                      <div class="col-xl-12">
                          <div class="auth-form">
                               <div class="text-center mb-3">
                                  <!-- <a [routerLink]="['/admin/index']"><img src="assets/images/LOGO DDF.jpg" alt=""></a> -->
                                  <h3>PORTAIL IKLASS GESTION</h3>
                              </div>
                              <h4 class="text-center mb-4">Connectez-vous à votre compte</h4>
                              <form [formGroup]="signInForm" (ngSubmit)=" onSubmitSignInForm()">

                                <div *ngIf="errorMsg" class="alert alert-danger">{{errorMsg}}</div>
                                  <div class="form-group">
                                    <div class="alert alert-danger"
                                        *ngIf="(signInForm.controls.email.dirty || signInForm.controls.email.touched)
                                                && signInForm.controls.email.invalid
                                                && signInForm.controls.email.errors.required">
                                        L'email est obligatoire.
                                    </div>
                                      <label class="mb-1"><strong>Email</strong></label>
                                      <input type="email" class="form-control" placeholder="hello@iklassgestion.com"  formControlName="email">
                                  </div>
                                  <div class="form-group">
                                    <div class="alert alert-danger"
                                        *ngIf="(signInForm.controls.password.dirty || signInForm.controls.password.touched)
                                                && signInForm.controls.password.invalid
                                                && signInForm.controls.password.errors.required">
                                        Le mot de passe est obligatoire.
                                    </div>
                                      <label class="mb-1"><strong>Mot de passe</strong></label>
                                      <input type="password" class="form-control" placeholder="*****"  formControlName="password">
                                  </div>
                                  <div class="form-row d-flex justify-content-between mt-4 mb-2">
                                      <div class="form-group">
                                         <div class="custom-control custom-checkbox ml-1">
                                              <input type="checkbox" class="custom-control-input" id="basic_checkbox_1">
                                              <label class="custom-control-label" for="basic_checkbox_1">Resté connecter</label>
                                          </div>
                                      </div>
                                      <div class="form-group">
                                          <a [routerLink]="['/reset/password']">Mot de passe oublié?</a>
                                      </div>
                                  </div>
                                  <div class="text-center">
                                      <button type="submit" class="btn btn-primary btn-block" [disabled]="signInForm.invalid">Se connecter</button>
                                  </div>
                              </form>
                              <!-- <div class="new-account mt-3">
                                  <p>Vous n'avez pas de compte ? <a class="text-primary" [routerLink]="['/auth/register']">Ouvrir un compte</a></p>
                              </div> -->
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
