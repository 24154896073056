import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { Transaction } from '../models/transaction';
import firebase from 'firebase/app';
import { AngularFirestore, AngularFirestoreCollection, CollectionReference, DocumentSnapshot, QuerySnapshot } from '@angular/fire/firestore';
import { map, take } from 'rxjs/operators';
import { OperatorService } from './operator.service';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {
  private transactionsCollection: AngularFirestoreCollection<Transaction>;

  constructor(private afs: AngularFirestore
    ) {
    this.transactionsCollection = this.afs.collection<Transaction>('transactions');
  }


  getAllTransactions(): Promise<Transaction[]> {
    return this.afs.collection('transactions', ref => ref.orderBy('date', 'desc'))
    .get()
    .toPromise()
    .then(snapshot => {
      const transactions: Transaction[] = [];
      snapshot.forEach(doc => {
        const data = doc.data() as Transaction;
        const id = doc.id;
        transactions.push({ id, ...data });
      });
      return transactions;
    });
  }



  getByIdTransaction(transactionId: string): Observable<Transaction | undefined> {
    return this.transactionsCollection.doc<Transaction>(transactionId).valueChanges();
  }
   // Méthode pour récupérer une transaction par son identifiant (ID)
   getTransactionById(transactionId: string): Observable<Transaction> {
    return this.afs.collection('transactions').doc(transactionId).get().pipe(
      map((doc: DocumentSnapshot<Transaction>) => {
        return doc.data();
      })
    );
  }

  CreateTransaction(transaction: Transaction): Promise<any> {
    return this.transactionsCollection.add(transaction);
  }

  editTransaction(transactionId: string, transaction: Transaction): Promise<void> {
    return this.transactionsCollection.doc(transactionId).update(transaction);
  }

  deleteTransaction(transactionId: string): Promise<void> {
    return this.transactionsCollection.doc(transactionId).delete();
  }

  deleteWhereTransaction(fieldPath, opStr, value){

    return new Promise(
      (resolve, reject) => {
        this.afs.collection('transactions', ref => ref.where(fieldPath, opStr, value))
          .get()
          .subscribe(
            (transactions) => {
              transactions.forEach(
                (transaction: any) => {
                  transaction.ref.delete()

                });
              resolve(transactions);
            })
      })
  }






 // Méthode pour calculer le total des dépôts
 getTotalDeposits(): Observable<number> {
  return this.transactionsCollection.valueChanges().pipe(
    map(transactions => transactions
      .filter(transaction => transaction.type === 'depot')
      .reduce((total, transaction) => total + parseFloat(transaction.amount.toString()), 0)
    )
  );
}

// Méthode pour calculer le total des retraits
getTotalWithdrawals(): Observable<number> {
  return this.transactionsCollection.valueChanges().pipe(
    map(transactions => transactions
      .filter(transaction => transaction.type === 'retrait')
      .reduce((total, transaction) => total + parseFloat(transaction.amount.toString()), 0)
    )
  );
}






// Méthode pour calculer le total des dépôts pour un opérateur donné
getTotalDepositsForOperator(operatorId: string): Observable<number> {
  return this.transactionsCollection.valueChanges().pipe(
    map(transactions => transactions
      .filter(transaction => transaction.operatorId === operatorId && transaction.type === 'depot')
      .reduce((total, transaction) => total + parseFloat(transaction.amount.toString()), 0)
    )
  );
}


// Méthode pour calculer le Cash
getCashRevenue(date: Date): Promise<number> {
  const startOfDay = new Date(date);
  startOfDay.setHours(0, 0, 0, 0);
  const endOfDay = new Date(date);
  endOfDay.setHours(23, 59, 59, 999);

  return this.afs.collection('transactions', ref =>
    ref.where('date', '>=', startOfDay)
       .where('date', '<=', endOfDay)
  )
  .get()
  .toPromise()
  .then(snapshot => {
    let cashRevenue = 0;
    snapshot.forEach(doc => {
      const data = doc.data() as Transaction;
      if (data.type === 'depot' && data.status ==='payer'
          || data.type === 'credit' && data.status ==='payer'
          || data.type === 'internet' && data.status ==='payer'
          || data.type === 'sodeci' && data.status ==='payer'
          || data.type === 'cie' && data.status ==='payer'
          || data.type === 'carte UBA' && data.status ==='payer'
          || data.type === 'inscription' && data.status ==='payer'
          || data.type === 'starTimes' && data.status ==='payer'
          || data.type === 'canal' && data.status ==='payer'
          ) {
            cashRevenue += parseFloat(data.amount.toString());
      } else if (data.type === 'retrait'
                  ) {
        cashRevenue -= parseFloat(data.amount.toString());
      } else if(
        data.type === 'retrait' && data.status ==='non payer'
        || data.type === 'depot' && data.status ==='non payer'
        || data.type === 'credit' && data.status ==='non payer'
        || data.type === 'internet' && data.status ==='non payer'
        || data.type === 'sodeci' && data.status ==='non payer'
        || data.type === 'carte UBA' && data.status ==='non payer'
        || data.type === 'inscription' && data.status ==='non payer'
        || data.type === 'starTimes' && data.status ==='non payer'
        || data.type === 'canal' && data.status ==='non payer'
        || data.type === 'cie' && data.status ==='non payer'){

          return cashRevenue;
      }
    });
    return cashRevenue;
  });
}


// Méthode pour calculer le chiffre d'affaires journalier
getDailyRevenue(date: Date): Promise<number> {
  const startOfDay = new Date(date);
  startOfDay.setHours(0, 0, 0, 0);
  const endOfDay = new Date(date);
  endOfDay.setHours(23, 59, 59, 999);

  return this.afs.collection('transactions', ref =>
    ref.where('date', '>=', startOfDay)
       .where('date', '<=', endOfDay)
  )
  .get()
  .toPromise()
  .then(snapshot => {
    let dailyRevenue = 0;
    snapshot.forEach(doc => {
      const data = doc.data() as Transaction;
      if (data.type === 'depot'
          || data.type === 'credit'
          || data.type === 'internet'
          || data.type === 'sodeci'
          || data.type === 'cie'
          || data.type === 'carte UBA'
          || data.type === 'inscription'
          || data.type === 'starTimes'
          || data.type === 'canal'
          ) {
        dailyRevenue += parseFloat(data.amount.toString());
      } else if (data.type === 'retrait') {
        dailyRevenue += parseFloat(data.amount.toString());
      }
    });
    return dailyRevenue;
  });
}


// Méthode pour calculer le chiffre d'affaires hebdomadaire
getWeeklyRevenue(): Promise<number> {
  const today = new Date();
  const startOfWeek = new Date(today);
  startOfWeek.setDate(today.getDate() - today.getDay());
  startOfWeek.setHours(0, 0, 0, 0);

  const endOfWeek = new Date(today);
  endOfWeek.setDate(startOfWeek.getDate() + 6);
  endOfWeek.setHours(23, 59, 59, 999);

  return this.afs.collection('transactions', ref =>
    ref.where('date', '>=', startOfWeek)
       .where('date', '<=', endOfWeek)
  )
  .get()
  .toPromise()
  .then(snapshot => {
    let weeklyRevenue = 0;
    snapshot.forEach(doc => {
      const data = doc.data() as Transaction;
      if (data.type === 'depot'
          || data.type === 'credit'
          || data.type === 'internet'
          || data.type === 'sodeci'
          || data.type === 'cie'
          || data.type === 'carte UBA'
          || data.type === 'inscription'
          || data.type === 'starTimes'
          || data.type === 'canal'
          ) {
        weeklyRevenue += parseFloat(data.amount.toString());
      } else if (data.type === 'retrait') {
        weeklyRevenue += parseFloat(data.amount.toString());
      }
    });
    return weeklyRevenue;
  });
}

// Méthode pour calculer le chiffre d'affaires mensuel
getMonthlyRevenue(): Promise<number> {
  const today = new Date();
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  startOfMonth.setHours(0, 0, 0, 0);

  const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  endOfMonth.setHours(23, 59, 59, 999);

  return this.afs.collection('transactions', ref =>
    ref.where('date', '>=', startOfMonth)
       .where('date', '<=', endOfMonth)
  )
  .get()
  .toPromise()
  .then(snapshot => {
    let monthlyRevenue = 0;
    snapshot.forEach(doc => {
      const data = doc.data() as Transaction;
      if (data.type === 'depot'
          || data.type === 'credit'
          || data.type === 'internet'
          || data.type === 'sodeci'
          || data.type === 'cie'
          || data.type === 'carte UBA'
          || data.type === 'inscription'
          || data.type === 'starTimes'
          || data.type === 'canal'
          ) {
        monthlyRevenue += parseFloat( data.amount.toString());
      } else if (data.type === 'retrait') {
        monthlyRevenue += parseFloat(data.amount.toString());
      }
    });
    return monthlyRevenue;
  });
}

// Méthode pour récupérer les chiffres d'affaires par année
getAnnualRevenue(): Promise<number> {
  const today = new Date();
  const startOfYear = new Date(today.getFullYear(), 0, 1);
  startOfYear.setHours(0, 0, 0, 0);

  const endOfYear = new Date(today.getFullYear(), 11, 31);
  endOfYear.setHours(23, 59, 59, 999);

  return this.afs.collection('transactions', ref =>
    ref.where('date', '>=', startOfYear)
       .where('date', '<=', endOfYear)
  )
  .get()
  .toPromise()
  .then(snapshot => {
    let annualRevenue = 0;
    snapshot.forEach(doc => {
      const data = doc.data() as Transaction;
      if (data.type === 'depot'
          || data.type === 'credit'
          || data.type === 'internet'
          || data.type === 'sodeci'
          || data.type === 'cie'
          || data.type === 'carte UBA'
          || data.type === 'inscription'
          || data.type === 'starTimes'
          || data.type === 'canal'
          ) {
        annualRevenue += parseFloat( data.amount.toString());
      } else if (data.type === 'retrait') {
        annualRevenue += parseFloat(data.amount.toString());
      }
    });
    return annualRevenue;
  });
}


  // Fonction pour formater la date en texte (02 Août 2023)
  private formatDate(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = this.getMonthName(date.getMonth());
    const year = date.getFullYear().toString();
    return `${day} ${month} ${year}`;
  }

  private getMonthName(month: number): string {
    const monthNames = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
    return monthNames[month];
  }





  getDailyRevenueByDay(): Promise<{ date: string, revenue: number }[]> {
    const today = new Date();
    const startOfDay = new Date(today);
    startOfDay.setHours(0, 0, 0, 0);

    const endOfDay = new Date(today);
    endOfDay.setHours(23, 59, 59, 999);

    return this.afs.collection<Transaction>('transactions', ref =>
      ref.where('date', '>=', startOfDay).where('date', '<=', endOfDay)
    )
    .get()
    .toPromise()
    .then(snapshot => {
      const dailyRevenues: { date: string, revenue: number }[] = [];
      const groupedData: { [key: string]: number } = {};

      snapshot.forEach(doc => {
        const data = doc.data();
        const date = (data.date as any).toDate().toLocaleDateString();
        const amount = (data.type === 'depot'
                          || data.type === 'credit'
                          || data.type === 'internet'
                          || data.type === 'sodeci'
                          || data.type === 'cie'
                          || data.type === 'carte UBA'
                          || data.type === 'inscription'
                          || data.type === 'starTimes'
                          || data.type === 'canal'
        ) ? parseFloat(data.amount.toString()) : - parseFloat(data.amount.toString());
        if (groupedData[date]) {
          groupedData[date] += amount;
        } else {
          groupedData[date] = amount;
        }
      });
      Object.keys(groupedData).forEach(date => {
        dailyRevenues.push({ date, revenue: groupedData[date] });
      });

      return dailyRevenues;
    });
  }


  getWeeklyRevenueByWeek(): Promise<{ date: string, revenue: number }[]> {
    const today = new Date();
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - today.getDay()); // Set to the first day of the week (Sunday)

    const endOfWeek = new Date(today);
    endOfWeek.setDate(today.getDate() + (6 - today.getDay())); // Set to the last day of the week (Saturday)
    endOfWeek.setHours(23, 59, 59, 999);

    const startTimestamp = firebase.firestore.Timestamp.fromDate(startOfWeek);
    const endTimestamp = firebase.firestore.Timestamp.fromDate(endOfWeek);

    return this.afs.collection<Transaction>('transactions', ref =>
      ref.where('date', '>=', startTimestamp).where('date', '<=', endTimestamp)
    )
    .get()
    .toPromise()
    .then(snapshot => {
      const weeklyRevenues: { date: string, revenue: number }[] = [];
      const groupedData: { [key: string]: number } = {};
      snapshot.forEach(doc => {
        const data = doc.data();
        const date = (data.date as any).toDate().toLocaleDateString();
        const amount = (data.type === 'depot'
                        || data.type === 'credit'
                        || data.type === 'internet'
                        || data.type === 'sodeci'
                        || data.type === 'cie'
                        || data.type === 'carte UBA'
                        || data.type === 'inscription'
                        || data.type === 'starTimes'
                        || data.type === 'canal'
        ) ? parseFloat(data.amount.toString()) : - parseFloat(data.amount.toString());
        if (groupedData[date]) {
          groupedData[date] += amount;
        } else {
          groupedData[date] = amount;
        }
      });

      Object.keys(groupedData).forEach(date => {
        weeklyRevenues.push({ date, revenue: groupedData[date] });
      });

      return weeklyRevenues;
    });
  }

  getMonthlyRevenueByMonthly(): Promise<{ date: string, revenue: number }[]> {
    const today = new Date();
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    endOfMonth.setHours(23, 59, 59, 999);

    const startTimestamp = firebase.firestore.Timestamp.fromDate(startOfMonth);
    const endTimestamp = firebase.firestore.Timestamp.fromDate(endOfMonth);

    return this.afs.collection<Transaction>('transactions', ref =>
      ref.where('date', '>=', startTimestamp).where('date', '<=', endTimestamp)
    )
    .get()
    .toPromise()
    .then(snapshot => {
      const monthlyRevenues: { date: string, revenue: number }[] = [];
      const groupedData: { [key: string]: number } = {};

      snapshot.forEach(doc => {
        const data = doc.data();
        const date = (data.date as any).toDate().toLocaleDateString();
        const amount = (data.type === 'depot'
                        || data.type === 'credit'
                        || data.type === 'internet'
                        || data.type === 'sodeci'
                        || data.type === 'cie'
                        || data.type === 'carte UBA'
                        || data.type === 'inscription'
                        || data.type === 'starTimes'
                        || data.type === 'canal'
        ) ? parseFloat(data.amount.toString()) : - parseFloat(data.amount.toString());
        if (groupedData[date]) {
          groupedData[date] += amount;
        } else {
          groupedData[date] = amount;
        }
      });
      Object.keys(groupedData).forEach(date => {
        monthlyRevenues.push({ date, revenue: groupedData[date] });
      });

      return monthlyRevenues;
    });
  }

  getAnnualRevenueByAnnual(): Promise<{ date: string, revenue: number }[]> {
    const today = new Date();
    const startOfYear = new Date(today.getFullYear(), 0, 1);

    const endOfYear = new Date(today.getFullYear(), 11, 31);
    endOfYear.setHours(23, 59, 59, 999);

    const startTimestamp = firebase.firestore.Timestamp.fromDate(startOfYear);
    const endTimestamp = firebase.firestore.Timestamp.fromDate(endOfYear);

    return this.afs.collection<Transaction>('transactions', ref =>
      ref.where('date', '>=', startTimestamp).where('date', '<=', endTimestamp)
    )
    .get()
    .toPromise()
    .then(snapshot => {
      const annualRevenues: { date: string, revenue: number }[] = [];
      const groupedData: { [key: string]: number } = {};

      snapshot.forEach(doc => {
        const data = doc.data();
        const date = (data.date as any).toDate().toLocaleDateString();
        const amount = (data.type === 'depot'
                        || data.type === 'credit'
                        || data.type === 'internet'
                        || data.type === 'sodeci'
                        || data.type === 'cie'
                        || data.type === 'carte UBA'
                        || data.type === 'inscription'
                        || data.type === 'starTimes'
                        || data.type === 'canal'
        ) ? parseFloat( data.amount.toString()) : - parseFloat(data.amount.toString());
        if (groupedData[date]) {
          groupedData[date] += amount;
        } else {
          groupedData[date] = amount;
        }
      });
      Object.keys(groupedData).forEach(date => {
        annualRevenues.push({ date, revenue: groupedData[date] });
      });

      return annualRevenues;
    });
  }

  getNumberOfTransactions(): Promise<number> {
    // Code pour récupérer le nombre de versements, par exemple, à partir de la base de données.
    // Vous pouvez utiliser Firebase Firestore ou tout autre mécanisme de stockage de données.
    // Retournez la promesse du nombre de versements.
    // Par exemple :
    return this.afs.collection('transactions').valueChanges().pipe(take(1)).toPromise().then(data => data.length);
  }



}
