import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OperatorService } from 'src/app/services/operator.service';
import { RechargeModalComponent } from '../recharge-modal/recharge-modal.component';
import { Operator } from 'src/app/models/operator';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RetraitModalComponent } from '../retrait-modal/retrait-modal.component';


@Component({
  selector: 'app-operator-detail',
  templateUrl: './operator-detail.component.html',
  styleUrls: ['./operator-detail.component.css']
})
export class OperatorDetailComponent implements OnInit {

  operator: Operator;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private operatorService: OperatorService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
  if (id) {
    this.operatorService.getByIdOperator(id).subscribe(op => {
      this.operator = op;
    });
  }
  }

  async openRechargeModal() {
    const modalRef = this.modalService.open(RechargeModalComponent);
    modalRef.componentInstance.operator = this.operator;
    modalRef.result.then((result) => {
      if (result === 'Recharge successful') {
        this.operatorService.getByIdOperator(this.operator.id).subscribe(updatedOperator => {
          this.operator = updatedOperator;
        });
      }
    }).catch((reason) => {
      console.log('Modal dismissed:', reason);
    });
  }
  async openRetraitModal() {
    const modalRef = this.modalService.open(RetraitModalComponent);
    modalRef.componentInstance.operator = this.operator;
    modalRef.result.then((result) => {
      if (result === 'Recharge successful') {
        this.operatorService.getByIdOperator(this.operator.id).subscribe(updatedOperator => {
          this.operator = updatedOperator;
        });
      }
    }).catch((reason) => {
      console.log('Modal dismissed:', reason);
    });
  }

}
