import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-print-modal',
  templateUrl: './print-modal.component.html',
  styleUrls: ['./print-modal.component.css']
})
export class PrintModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<PrintModalComponent>) { }

  ngOnInit(): void {
  }

}
