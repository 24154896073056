<div class="content-body">
  <div class="container-fluid">
    <div class="authincation h-100">
      <div class="container vh-100">
          <div class="row justify-content-center h-100 align-items-center">
              <div class="col-lg-6 col-md-8">
                  <div class="authincation-content">
                      <div class="row no-gutters">
                          <div class="col-xl-12">
                              <div class="auth-form">
                                  <h4 class="text-center mb-4">Enregistrement d'une dépense</h4>
                                  <form [formGroup]=" expenseEditForm" (ngSubmit)="onSubmitExpenseEditForm()">
                                      <div class="form-group">
                                        <div class="alert alert-danger"
                                        *ngIf="(expenseEditForm.controls.label.dirty
                                        || expenseEditForm.controls.label.touched)
                                        && expenseEditForm.controls.label.invalid
                                        && expenseEditForm.controls.label.errors.required"
                                        > Le libellé est obligatoire.</div>
                                        <label class="mb-1"><strong>Libellé</strong></label>
                                        <input type="text" class="form-control" placeholder="Reparation Téléphone" formControlName="label">
                                    </div>
                                      <div class="form-group">
                                        <div class="alert alert-danger"
                                        *ngIf="(expenseEditForm.controls.amount.dirty
                                        || expenseEditForm.controls.amount.touched)
                                        && expenseEditForm.controls.amount.invalid
                                        && expenseEditForm.controls.amount.errors.required"
                                        > Le montant de la dépense est obligatoire.</div>
                                        <label class="mb-1"><strong>Montant</strong></label>
                                        <input type="text" class="form-control" placeholder="Montant de la dépense" formControlName="amount">
                                    </div>
                                      <div class="text-center mt-4">
                                          <button type="submit" class="btn btn-primary  btn-sm btn-block" [disabled]="expenseEditForm.invalid" >Enregistrer</button>
                                      </div>
                                  </form>
                                  <div class="new-account mt-3">
                                    <p><a class="text-primary" [routerLink]="['/caisse/expenses']">Retour à la liste des dépenses</a></p>
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
  </div>
</div>
