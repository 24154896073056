 <!--**********************************
        Content body start
    ***********************************-->
    <div class="content-body">
      <div class="container-fluid">
          <div class="form-head mb-lg-5 mb-3 d-flex flex-wrap align-items-center">
              <h2 class="font-w600 mb-3 mr-auto ">La liste des chauffeurs </h2> 
              <!-- <a routerLink="/caisse/operators/new" class="btn btn-outline-primary btn-sm mb-2 m-2"><i class="fa fa-plus m-2" aria-hidden="true"></i>Approvisionner</a> -->
              <a routerLink="/caisse/cars/new" class="btn btn-secondary text-white btn-sm mb-2 m-2"><i class="fa fa-plus m-2" aria-hidden="true"></i>Enregistrer un véhicule</a>
              <a routerLink="/caisse/drivers/new" class="btn btn-secondary text-white btn-sm mb-2 m-2"><i class="fa fa-plus m-2" aria-hidden="true"></i>Enregistrer un chauffeur</a>
          </div>
          <div class="row sp-sm-15">
            <!-- <div class="col-xl-2 col-3 m-t35"  *ngFor="let operator of operators">
                <div class="card card-coin">
                    <div class="card-body text-center">
                      <img [src]="operator.photo" width="50" class="mb-sm-3 mb-2 currency-icon"/>
                        <h4 *ngIf="operator.balance >= 0">{{operator.name}}</h4>
                        <h4 *ngIf="operator.balance <0"> <span class="text-danger">{{operator.name}}</span></h4>
                        <h3 class="text-black mb-2 font-w500" *ngIf="operator.balance <0"><span class="text-danger">{{operator.balance | currency: 'FCFA': 'symbol': '1.0-0': 'fr'}}</span> </h3>
                        <h3 class="text-black mb-2 font-w500" *ngIf="operator.balance >= 0"><span >{{operator.balance | currency: 'FCFA': 'symbol': '1.0-0': 'fr'}} </span></h3>
                         <h3 class="text-black mb-2 font-w500" *ngIf="operator.balance == 100000"><span class="text-warning">{{operator.balance | currency: 'FCFA': 'symbol': '1.0-0': 'fr'}} </span></h3>
                        <p class="mb-0 fs-14">
                            <span class="text-success mr-1" *ngIf="operator.balance >= 0"> UVE {{operator.name}}</span>
                            <span class="text-danger mr-1"*ngIf="operator.balance <0"> <marquee>SOLDE INSUFFISANT</marquee> </span>
                            
                        </p>
                    </div>
                </div>
            </div> -->

        </div>
          <div class="row">
              <div class="col-xl-9 col-xxl-8">
                <div class="mb-3">
                  <div class="input-group right d-lg-inline-flex d-none">
                      <input type="text" #query (keyup)="searchDriver(query.value)"  class="form-control" placeholder="Recherche un chauffeur par son Nom, ou son Numéro de téléphone ">
                      <div class="input-group-append">
                          <span class="input-group-text"><i class="flaticon-381-search-2"></i></span>
                      </div>
                  </div>
              </div>
                <div class="table-responsive">
                  <table class="table table-striped">
                    <thead>
                    <tr> 
                    <th scope="col">#</th>
                    <th scope="col">Nom des chauffeurs</th>
                    <th scope="col">Numéro de téléphone </th>
                    <th scope="col">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let driver of searchDrivers | paginate: { itemsPerPage: 30, currentPage: page, totalItems :totalLength } ; index as i">
                    <th scope="row">{{i + 1}}</th>
                    <td>
                      <!-- <img [src]="'https://upload.wikimedia.org/wikipedia/commons/' + country.flag" class="mr-2" style="width: 20px"> -->
                      <!-- <img [src]="operator.photo" class="mr-2" style="width: 20px"/> -->
                      {{ driver.name }}
                    </td>
                    <td>
                      {{ driver.driverPhone }}
                    </td>
                    <td>
                      <a routerLink="/caisse/drivers/edit/{{driver.id}}" class="btn btn-outline-primary btn-xxs  m-1"><i class="fa fa-pencil-square-o m-1" aria-hidden="true"></i>Modifier</a>
                      <a (click)=" onDeleteDriver(driver.id)" class="btn btn-outline-danger btn-xxs  m-1"><i class="fa fa-trash-o m-1" aria-hidden="true"></i>Supprimer</a>
                    </tr>
                    </tbody>
                  </table>
                  <div>
                    <div class="d-flex justify-content-between p-2">
                      <pagination-controls class="mr-3" (pageChange)="page = $event" ></pagination-controls>
                        <!-- <ngb-pagination class="mr-3" [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" (pageChange)="updateTransactionListing()">
                        </ngb-pagination> -->

                        <!-- <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="updateTransactionListing()">
                            <option [ngValue]="10">10 transactions par page</option>
                            <option [ngValue]="20">20 transactions par page</option>
                            <option [ngValue]="30">30 transactions par page</option>
                            <option [ngValue]="50">50 transactions par page</option>
                            <option [ngValue]="100">100 transactions par page</option>
                        </select>  -->
                    </div>
                   </div>
                </div>

                <!-- <div class="d-sm-flex d-block justify-content-between p-2">
                  <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" (pageChange)="refreshCountries()">
                  </ngb-pagination>

                  <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="refreshCountries()">
                    <option [ngValue]="2">2 opérateurs page</option>
                    <option [ngValue]="4">4 opérateurs page</option>
                    <option [ngValue]="6">6 opérateurs page</option>
                  </select>
                </div> -->
                  <!-- <app-graph-market-overview></app-graph-market-overview> -->
              </div>
              <div class="col-xl-3 col-xxl-4">
                  <!-- <app-graph-current-statistic></app-graph-current-statistic> -->
              </div>
          </div>
          <div class="row">
              <div class="col-xl-6 col-xxl-12">
                  <!-- <app-quick-trade></app-quick-trade> -->
              </div>
              <div class="col-xl-6 col-xxl-12">
                  <div class="row">
                      <!-- <div class="col-sm-6" *ngFor="let item of cryptoCards">
                          <div class="card-bx stacked card">
                              <img class="bg-img" src="{{item.image}}" alt="">
                              <div class="card-info">
                                  <p class="mb-1 text-white fs-14">Main Balance</p>
                                  <div class="d-flex justify-content-between align-items-start">
                                      <h2 class="num-text text-white mb-5 font-w600">{{item.price}}</h2>
                                      <img src="{{item.icon_image}}" alt="">
                                  </div>
                                  <div class="d-flex">
                                      <div class="mr-4 text-white">
                                          <p class="fs-12 mb-1 op6">VALID THRU</p>
                                          <span>{{item.valid_thrugh}}</span>
                                      </div>
                                      <div class="text-white">
                                          <p class="fs-12 mb-1 op6">CARD HOLDER</p>
                                          <span>{{item.name}}</span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div> -->
                      <div class="col-xl-12">
                         <!-- <app-recent-trading-activities></app-recent-trading-activities> -->
                      </div>
                      <div class="col-xl-12 mt-2">
                          <!-- <app-quick-transfer-crousal [data]="quickTransfer"></app-quick-transfer-crousal> -->

                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <!--**********************************
      Content body end
  ***********************************-->

