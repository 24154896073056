
<nav class="navbar navbar-expand bd-highlight" >
  <a class="navbar-brand"[routerLink]="['/home']" >Navbar</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item ">
        <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#">Link</a>
      </li>
    </ul>
    <ul class="navbar-nav ml-auto">
      <li class="nav-item ">
        <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item" *ngIf="!isAuth">
        <a class="nav-link" [routerLink]="['/auth/login']"><i class="far fa-user m-1"></i>Se connecter</a>
      </li>
      <li class="nav-item"*ngIf="!isAuth" >
        <a class=" btn btn-outline-success btn-xs" [routerLink]="['/auth/register']"><i class="fas fa-user-plus m-1"></i>Ouvrir un compte</a>
      </li>
    </ul>
  </div>
</nav>

<div class="container-fluid mt-4">
  <ngb-carousel class="default-carousel" *ngIf="images">
    <ng-template ngbSlide>
      <div class="picsum-img-wrapper">
        <img [src]="images[0]" alt="Random first slide">
      </div>
      <div class="carousel-caption">
        <h3>First slide label</h3>
        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="picsum-img-wrapper">
        <img [src]="images[1]" alt="Random second slide">
      </div>
      <div class="carousel-caption">
        <h3>Second slide label</h3>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="picsum-img-wrapper">
        <img [src]="images[2]" alt="Random third slide">
      </div>
      <div class="carousel-caption">
        <h3>Third slide label</h3>
        <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
      </div>
    </ng-template>
  </ngb-carousel>
</div>

<div *ngIf="user">
  {{user.emailVerified}} - {{user.email}}
</div>
