import { Component } from '@angular/core';
import firebase from 'firebase/app';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'managerUsers';
  // constructor(){
  //   const firebaseConfig = {
  //     apiKey: "AIzaSyAWn-VrHCO-ykQ-SS8fc3HtjfSYAauwB9Y",
  //     authDomain: "novacoin-4a02d.firebaseapp.com",
  //     databaseURL: "https://novacoin-4a02d-default-rtdb.europe-west1.firebasedatabase.app",
  //     projectId: "novacoin-4a02d",
  //     storageBucket: "novacoin-4a02d.appspot.com",
  //     messagingSenderId: "832906667597",
  //     appId: "1:832906667597:web:65c2809c839b3b7b289e04"
  //   };
  //   firebase.initializeApp(firebaseConfig);

  // }
}
