<div class="container mt-5">
  <div class="row gutters">
  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">

   <!-- Button trigger modal -->
<!-- <button type="button" class="btn btn-outline-primary btn-sm" data-toggle="modal" data-target="#exampleModal" (click)="onEditUser()">
 Modifier
</button> -->

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <form (ngSubmit)="onSubmitEditUserForm()" [formGroup]="editUserForm">
          <div class="modal-header">
        <div *ngIf="errorMsg" class="alert alert-danger">{{errorMsg}}</div>
        <h5 class="modal-title" id="exampleModalLabel">Modification</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-row">
          <div class="form-group col-md-6">
            <div class="alert alert-danger"
                                              *ngIf="(editUserForm.controls.firstName.dirty || editUserForm.controls.firstName.touched)
                                                      && editUserForm.controls.firstName.invalid
                                                      && editUserForm.controls.firstName.errors.required">
                                              Le prénom est obligatoire.
              </div>
              <label class="mb-1"><strong>Prénom </strong></label>
              <input type="text" class="form-control" placeholder="Votre prénom"  formControlName="firstName">
          </div>
          <div class="form-group col-md-6">
            <div class="alert alert-danger"
                                                *ngIf="(editUserForm.controls.lastName.dirty || editUserForm.controls.lastName.touched)
                                                        && editUserForm.controls.lastName.invalid
                                                        && editUserForm.controls.lastName.errors.required">
                                                Le nom est obligatoire.
            </div>
            <label class="mb-1"><strong>Nom</strong></label>
             <input type="text" class="form-control" placeholder="Votre nom"  formControlName="lastName">
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <div class="alert alert-danger"
                                              *ngIf="(editUserForm.controls.phoneNumber.dirty || editUserForm.controls.phoneNumber.touched)
                                                      && editUserForm.controls.phoneNumber.invalid
                                                      && editUserForm.controls.phoneNumber.errors.required">
                                                      Le numéro de téléphone  est obligatoire.
                </div>
                <label class="mb-1"><strong>Téléphone</strong></label>
                <input type="tel" class="form-control" placeholder="01 02 03 04 05" formControlName="phoneNumber">
          </div>
          <div class="form-group col-md-6">
            <div class="alert alert-danger"
                                              *ngIf="(editUserForm.controls.email.dirty || editUserForm.controls.email.touched)
                                                      && editUserForm.controls.email.invalid
                                                      && editUserForm.controls.email.errors.required">
                                              L'email est obligatoire.
                </div>
                <label class="mb-1"><strong>Email</strong></label>
                <input type="email" class="form-control" placeholder="hello@example.com"  formControlName="email" readonly>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <div class="alert alert-danger"
                                              *ngIf="(editUserForm.controls.oldPassword.dirty || editUserForm.controls.oldPassword.touched)
                                                      && editUserForm.controls.oldPassword.invalid
                                                      && editUserForm.controls.oldPassword.errors.required">
                                                      L'ancien mot de passe est obligatoire.
                 </div>
                 <label class="mb-1"><strong>Ancien mot de passe</strong></label>
                 <input type="password" class="form-control" value="oldPassword"  placeholder="*****" formControlName="oldPassword">
          </div>
          <div class="form-group col-md-6">
            <div class="alert alert-danger"
                                              *ngIf="(editUserForm.controls.newPassword.dirty || editUserForm.controls.newPassword.touched)
                                                      && editUserForm.controls.newPassword.invalid
                                                      && editUserForm.controls.newPassword.errors.required">
                                                      Le nouveau mot de passe est obligatoire.
                 </div>
                 <label class="mb-1"><strong>Nouveau mot de passe</strong></label>
                 <input type="password" class="form-control" value="newPassword"  placeholder="*****" formControlName="newPassword">
          </div>
          <!-- <div class="form-group col-md-6">
            <div class="form-group">
              <label for="ciTy"><strong> Choisissez une photo de profile de dimension : 1080 × 1193</strong> </label>
              <a  class="btn btn-primary light mr-2 px-3" (click)="open(cameraModal)"><i class="fa fa-camera px-5"></i> </a>

              <div class="spinner-grow" role="status" *ngIf="photoUploading">
                <span class="sr-only">Loading...</span>
              </div>
             
              <ng-template #cameraModal let-modal>
                <div class="modal-header">
                  <h5 class="modal-title">Télécharger une image</h5>
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Télécharger une image </span>
                    </div>
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" (change)="onUploadFile($event)" formControlName="photo">
                      <label class="custom-file-label">Choisissez une image </label>
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>

          </div> -->
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-outline-primary btn-xs float-center">Modifier</button>
      </div>
      </form>
    </div>
  </div>
</div>
<!-- EndModal -->

</div>
  <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">

    <div class="card mt-5">
      <div class="card-header border-0">
          <h2 class="mb-0 text-black fs-20">Mon Profile</h2>
          <div class="dropdown custom-dropdown mb-0 tbl-orders-style dropdown-no-icon" ngbDropdown>
              <div class="btn sharp tp-btn" data-toggle="dropdown" aria-expanded="false" role="button" ngbDropdownToggle>
                  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.0049 13C12.5572 13 13.0049 12.5523 13.0049 12C13.0049 11.4477 12.5572 11 12.0049 11C11.4526 11 11.0049 11.4477 11.0049 12C11.0049 12.5523 11.4526 13 12.0049 13Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path d="M12.0049 6C12.5572 6 13.0049 5.55228 13.0049 5C13.0049 4.44772 12.5572 4 12.0049 4C11.4526 4 11.0049 4.44772 11.0049 5C11.0049 5.55228 11.4526 6 12.0049 6Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path d="M12.0049 20C12.5572 20 13.0049 19.5523 13.0049 19C13.0049 18.4477 12.5572 18 12.0049 18C11.4526 18 11.0049 18.4477 11.0049 19C11.0049 19.5523 11.4526 20 12.0049 20Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
              </div>
              <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                  <a class="dropdown-item" data-toggle="modal" data-target="#exampleModal" (click)="onEditUser()">Modifier</a>
                  <!-- <a class="dropdown-item text-danger" href="javascript:void(0);">Cancel</a> -->
              </div>
          </div>
      </div>
      <div class="card-body">
          <div class="text-center">
            <div  *ngIf="user">
              <div class="my-profile" >
                <!-- <img [src]="user.photo" alt="" class="rounded"> -->
                <!-- <a data-toggle="modal" data-target="#exampleModal" (click)="onEditUser()" ><i class="fa fa-pencil" aria-hidden="true"></i></a> -->
                <h4 class="mt-3 font-w600 text-black mb-0 name-text"><strong>Prénom & Nom :</strong>  {{user.firstName}} {{user.lastName}}</h4>
              <span> <strong>ID :</strong>  {{user.uid}}</span>
              <p class="mb-0 mt-2"> <strong>Email : </strong>{{user.email}}</p>
              <p class="mb-0 mt-2"><strong>Téléphone :</strong>  {{user.phoneNumber}}</p>
            </div>
            </div>
              <div  *ngIf="userService.userData as user">
              <p class="mb-0 mt-2"><strong>Dernière connexion :</strong> {{user.metadata.lastSignInTime | date: 'dd MMM yyy HH:mm':'fr'}}</p>
              <p class="mb-0 mt-2"><strong>Inscription le </strong> {{user.metadata.creationTime | date: 'dd MMM yyy HH:mm':'fr'}}</p>
              </div>
          </div>
          <ul class="portofolio-social">
              <li><a href="javascript:void(0);"><i class="fa fa-phone"></i></a></li>
              <li><a href="javascript:void(0);"><i class="fa fa-envelope-o"></i></a></li>
              <li><a href="javascript:void(0);"><i class="fa fa-facebook"></i></a></li>
          </ul>
          <button type="button" class="btn btn-outline-primary btn-xs float-right" data-toggle="modal" data-target="#exampleModal" (click)="onEditUser()">
            Modifier mon profile
           </button>
      </div>
  </div>
  </div>
