import { Injectable } from '@angular/core';
import { Driver } from '../models/driver';
import { AngularFirestore, AngularFirestoreCollection, DocumentReference } from '@angular/fire/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Car } from '../models/car';

@Injectable({
  providedIn: 'root'
})
export class DriverService {
  private driversSubject = new BehaviorSubject<Driver[]>([]);
  drivers$: Observable<Driver[]> = this.driversSubject.asObservable();

  private driversCollection: AngularFirestoreCollection<Driver>;

  constructor(private afs: AngularFirestore) {
    this.driversCollection = this.afs.collection<Driver>('drivers');
  }

  private initCars(): void {
    this.afs.collection<Car>('cars').valueChanges().subscribe((cars) => {
    });
  }

  // Obtenir les chauffeurs disponibles pour un nouveau véhicule
  getAvailableDriversForNewCar(carDrivers: string[]): Observable<Driver[]> {
    return this.drivers$.pipe(
      map((drivers) => {
        // Filtrer les chauffeurs disponibles en excluant ceux déjà associés à un véhicule
        return drivers.filter((driver) => !carDrivers.includes(driver.id));
      })
    );
  }

  getDrivers(): Observable<Driver[]> {
    return this.driversCollection.snapshotChanges().pipe(
      map(drives =>
        drives.map(a => {
          const data = a.payload.doc.data() as Driver;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      )
    );
  }

  getAllDrivers(): Promise<Driver[]> {
    return this.afs.collection('drivers', ref => ref.orderBy('date', 'desc'))
    .get()
    .toPromise()
    .then(snapshot => {
      const drivers: Driver[] = [];
      snapshot.forEach(doc => {
        const data = doc.data() as Driver;
        const id = doc.id;
        drivers.push({ id, ...data });
      });
      return drivers;
    });
  }

  getByIdDriver(driverId: string): Observable<Driver | undefined> {
    return this.driversCollection.doc<Driver>(driverId).valueChanges();
  }

  addDriver(driver: Driver): Promise<DocumentReference> {
    return this.driversCollection.add(driver);
  }

  // Mettez à jour les informations d'un chauffeur
  updateDriver(id: string, driver: Driver): Promise<void> {
    return this.driversCollection.doc(id).update(driver);
  }
  // Supprimez un chauffeur
  deleteDriver(id: string): Promise<void> {
    return this.driversCollection.doc(id).delete();
  }
}
