import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DailyPayment } from 'src/app/models/daily-payment';
import { Debt } from 'src/app/models/debt';
import { Recharge } from 'src/app/models/recharge';
import { DailyPaymentService } from 'src/app/services/daily-payment.service';

@Component({
  selector: 'app-modal-dette',
  templateUrl: './modal-dette.component.html',
  styleUrls: ['./modal-dette.component.css']
})
export class ModalDetteComponent implements OnInit {

  dailyPayment: DailyPayment;
  debtForm : FormGroup;
  recharges: Recharge[];

  constructor( private formBuilder : FormBuilder,
    private dailyPaymentService: DailyPaymentService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.initRechargeForm();
  }

  initRechargeForm(){
    this.debtForm = this.formBuilder.group({
      debtAmount: ['', [Validators.required,Validators.pattern(/^[0-9]*$/)]]

    });
  }
  calculateDriverDebt(paymentAmount: number): number {
    const dailyDebt = Math.max(0, 26000 - paymentAmount);
    return dailyDebt;
  }


  payDebtForm(): void {
    if (this.debtForm.valid) {
      const debtData: DailyPayment = this.debtForm.value;
      debtData.debtAmount = this.debtForm.value.debtAmount;
      const newBalance =   - debtData.debtAmount  ;
      console.log(newBalance);
      
      this.dailyPaymentService.retraitOperator(this.dailyPayment.id, newBalance, debtData.debtAmount);
      this.activeModal.close('Recharge successful');
      this.toastr.success('Le retrait à été éffectué avec succèss !!!');
    }
    }


}
