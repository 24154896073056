import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Car } from 'src/app/models/car';
import { Driver } from 'src/app/models/driver';
import { CarService } from 'src/app/services/car.service';
import { DriverService } from 'src/app/services/driver.service';
import { OperatorModalComponent } from '../../operators/operator-modal/operator-modal.component';
import { DailyPaymentService } from 'src/app/services/daily-payment.service';
import { DailyPayment } from 'src/app/models/daily-payment';
import { MatDialog } from '@angular/material/dialog';
import { ModalDetteComponent } from '../modal-dette/modal-dette.component';
import { startOfDay, isAfter, addHours, subHours } from 'date-fns';

@Component({
  selector: 'app-daily-versement-list',
  templateUrl: './daily-versement-list.component.html',
  styleUrls: ['./daily-versement-list.component.css']
})
export class DailyVersementListComponent implements OnInit {

  cars: Car[] = [];
  availableDrivers: Driver[] = [];
  availableCars: Car[] = [];
  searchDailyPayments :DailyPayment[]= [];
  dailyPayments: DailyPayment[] = [];
  dailyPayment: DailyPayment;
  driverDebtTotal: { [driverId: number]: number } = {};
  selectedDate: Date = new Date();

  dailyRevenue: number = 0;
  monthlyRevenue: number = 0;
  weeklyRevenue: number = 0;
  annualRevenue: number = 0;
  totalRevenue: number =0;
  numberOfPayments: number;
  resetHour: number = 11;
  commission:number=0;


  totalLength: number;
  page : number = 1;
  test:string;
  today: Date;

  constructor(private carService: CarService,
     private driverService : DriverService,
     private dailyPaymentService: DailyPaymentService,
     private modalService: NgbModal,
     private dialog: MatDialog
     ) { }

  ngOnInit(): void {
    this.loadDailypayment();
    this.loadAvailableDrivers();
    this.loadAvailableCars();
    this.calculateTotalRevenue();
    this.today = new Date();
    this.calculateDailyversRevenue();
    this.calculateWeeklyRevenue();
    this.calculateMontlyhRevenue();
    this.calculateAnnualRevenue();
    this.getNumberVersement();


  }




  calculateTotalRevenue() {
    this.totalRevenue = this.dailyPayments.reduce((total, payment) => total + parseFloat(payment.amount.toString()), 0);
  }





  loadDailypayment(): void {
    this.dailyPaymentService.getAlldailyPayments().then(data => {
     this.searchDailyPayments = this.searchDailyPayments = this.dailyPayments = data;
       this.calculateDriverDebtTotal();
       this.calculateTotalRevenue();

    });
  }
  loadAvailableCars() {
    this.carService.getCars().subscribe((data) => {
      this.availableCars = data;
      console.log(data);


    });
  }
  loadAvailableDrivers(){
    this.driverService.getDrivers().subscribe((data)=>{
      this.availableDrivers = data;
    })
  }


  getCarBrand(carId: string): string{

    const car = this.availableCars.find((car) => car.id === carId);
    return car ? car.brand : 'Inconnu';
}
  getCarIndexCar(carId: string): string{

    const car = this.availableCars.find((car) => car.id === carId);
    return car ? car.indexCar : 'Inconnu';
}
  getCarRegisterNumber(carId: string): string{

    const car = this.availableCars.find((car) => car.id === carId);
    return car ? car.registerNumber : 'Inconnu';
}
  getDriverName(driverId: string): string{

    const driver = this.availableDrivers.find((d) => d.id === driverId);
    return driver ? driver.name : 'Inconnu';
}

    searchDailyPayment(query: string){
      this.searchDailyPayments = (query) ? this.dailyPayments.filter(dailPayment => dailPayment.carId.toLowerCase().includes(query.toLowerCase())
      || dailPayment.driverId.toLowerCase().includes(query.toLowerCase())
      ): this.dailyPayments;
    }
    calculateDriverDebt(paymentAmount: number): number {
      const dailyDebt = Math.max(0, 26000 - paymentAmount);
      return dailyDebt;
    }

    calculateDriverDebtTotal() {
      this.driverDebtTotal = {};

      for (const payment of this.dailyPayments) {
        const driverId = payment.driverId;
        const dailyEarnings = payment.amount;
        const dailyDebt = Math.max(0, 26000 - dailyEarnings);

        if (!this.driverDebtTotal[driverId]) {
          this.driverDebtTotal[driverId] = 0;
        }

        this.driverDebtTotal[driverId] += dailyDebt;
      }
    }
    calculateDriverTotalDebt(driverId: string): number {
      let totalDebt = 0;

      for (const payment of this.dailyPayments) {
        if (payment.driverId === driverId) {
          const dailyEarnings = payment.amount;
          const dailyDebt = Math.max(0, 26000 - dailyEarnings);
          totalDebt += dailyDebt;
        }
      }

      return totalDebt;
    }
    driverDebtTotalKeys(): number[] {
      return Object.keys(this.driverDebtTotal).map(Number);
    }
    calculateDriverTotalCommission(driverId: string){
      let totalCommission = 0;

      for (const payment of this.dailyPayments) {
        if (payment.driverId === driverId) {
          totalCommission += this.calculateCommission(payment.amount);
        }
      }

      return totalCommission;
    }


    calculateCommission(paymentAmount: any): number {
      const roundedAmount = Number(paymentAmount); // Convertir en nombre
      if (roundedAmount >= 25000 && roundedAmount <= 25999) {
        return 1000;
      } else if (roundedAmount === 26000) {
        return 2000;
      } else {
        return 0;
      }
    }
    //  la méthode pour calculer le chiffre d'affaires journalier des retraits par un opérateur
calculateDailyRetraitRevenueForOperator(driverId: string): number {
  const today = new Date();
  const operatorDepositTransactions = this.dailyPayments.filter(
    dailyPayment =>
    dailyPayment.driverId === driverId &&
    dailyPayment.commission && // Assurez-vous que le type est "retait"
      ((dailyPayment.date) as any).toDate().toDateString() === today.toDateString()
  );

  return operatorDepositTransactions.reduce(
    (total, dailyPayment) => total + parseFloat(dailyPayment.commission.toString()),
    0
  );
}
    calculateTotalPaymentsByMonth() {
      const totalPaymentsByMonth = new Map<string, number>();

      for (const payment of this.dailyPayments) {
        const paymentDate = new Date(payment.date);
        const monthYear = paymentDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long' });

        if (totalPaymentsByMonth.has(monthYear)) {
          totalPaymentsByMonth.set(monthYear, totalPaymentsByMonth.get(monthYear) + payment.amount);
        } else {
          totalPaymentsByMonth.set(monthYear, payment.amount);
        }
      }

      return totalPaymentsByMonth;
    }

    calculateTotalPaymentsByDriver() {
      const totalPaymentsByDriver = new Map<string, number>();

      for (const payment of this.dailyPayments) {
        const driverId = payment.driverId;
        const paymentAmount = payment.amount;

        if (totalPaymentsByDriver.has(driverId)) {
          totalPaymentsByDriver.set(driverId, totalPaymentsByDriver.get(driverId) + paymentAmount);
        } else {
          totalPaymentsByDriver.set(driverId, paymentAmount);
        }
      }

      return totalPaymentsByDriver;
    }


    calculateTotalPayments() {

        return this.dailyPayments.reduce((total, dailyPayment) => {
          return total + parseFloat(dailyPayment.amount.toString());
        },0)

    }
    calculateTotalCommission() {

        return this.dailyPayments.reduce((total, dailyPayment) => {
          return total + parseFloat(dailyPayment.commission.toString());
        },0)

    }
    calculateTotalDebt() {

        return this.dailyPayments.reduce((total, dailyPayment) => {
          return total + parseFloat(dailyPayment.debtAmount.toString());
        },0)

    }



    calculateTotalCommissions(): number {
      let totalCommissions = 0;

      for (const payment of this.dailyPayments) {
        totalCommissions += this.calculateCommission(payment.amount);
      }

      return totalCommissions;
    }
    calculateTotalDebts(): number {
      let totalDebts = 0;

      for (const payment of this.dailyPayments) {
        const commission = this.calculateCommission(payment.amount);
        const debt = Math.max(0, 26000 - payment.amount);
        totalDebts += debt;
      }

      return totalDebts;
    }

    openRepaymentModal(dailPayment: DailyPayment) {
      const dialogRef = this.dialog.open(ModalDetteComponent, {
        data: { dailPayment }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result === 'repaid') {
          // Gérer le cas où la dette a été remboursée
          // Vous pouvez mettre à jour l'affichage des dettes ici
        }
      });
    }

// Obtenez le nombre de versements en attendant que la promesse se résolve
     getNumberVersement(){
      // Obtenez le nombre de versements
    this.dailyPaymentService.getNumberOfPayments().then(count => {
      this.numberOfPayments = count;
    });
     }

    calculateDailyversRevenue():any {
      this.dailyPaymentService.getDailyRevenue(this.today)
        .then((dailyRevenue) =>{
          this.dailyRevenue = dailyRevenue;
         this.commission= this.calculateCommissionTest(dailyRevenue);


        });
    }

    calculateCommissionTest(dailyRevenue: number): number {
      // Déterminez la commission en fonction du chiffre d'affaires journalier
      if (dailyRevenue >= 2000 && dailyRevenue <= 5000) {
        return 51;
      } else if (dailyRevenue > 5000 && dailyRevenue <= 10000) {
        return 53;
      } else if (dailyRevenue > 10000 && dailyRevenue <= 20000) {
        return 100;
      } else if (dailyRevenue > 20000 && dailyRevenue <= 80000) {
        return dailyRevenue * 0.1; // 10% de commission
      } else {
        return 0; // Aucune commission pour d'autres plages
      }
    }
    calculateWeeklyRevenue():any {
      this.dailyPaymentService.getMonthlyRevenue()
        .then((weeklyRevenue) =>{
          this.weeklyRevenue = weeklyRevenue;

        });
    }
    calculateMontlyhRevenue():any {
      this.dailyPaymentService.getMonthlyRevenue()
        .then((monthlyRevenue) =>{
          this.monthlyRevenue = monthlyRevenue;

        });
    }
    calculateAnnualRevenue():any {
      this.dailyPaymentService.getAnnualRevenue()
        .then((annualRevenue) =>{
          this.annualRevenue = annualRevenue;

        });
    }






    onDeleteDailyPayment(versementId){
      const  confirmModal = this.modalService.open(OperatorModalComponent).result;
      confirmModal.then(()=>{
        this.dailyPaymentService.deleteDailyPayment(versementId)
      .then(
        () =>{

          console.log('Supprimé');

        }
      )
      }).catch(
        (error)=>{

          console.log(error);

        }
      )
    }




}
