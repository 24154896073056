import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Operator } from 'src/app/models/operator';
import { Transaction } from 'src/app/models/transaction';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { OperatorService } from 'src/app/services/operator.service';
import { TransactionService } from 'src/app/services/transaction.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {

  user: User;
  users : User[] = [];
  usersSubscription: Subscription;
  editMode= false;
  indexToUpdate;
  searchUsers: User[];
  page = 1;
  pageSize = 2;
  collectionSize = this.users.length;
  total: number = 0;
  rateSubscription: Subscription;



  objectKeys = Object.keys;
  cryptos: any;

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private transactionService : TransactionService,
    private operatorService: OperatorService,
    private route: ActivatedRoute,
  ) {
    this.refreshCountries();
  }

  ngOnInit(): void {
    this.usersSubscription = this.userService.getAllUsers().subscribe(
      data => {
       this.searchUsers = this.users = data;

      }
    );
    const id = this.route.snapshot.params.id;
    this.userService.getUserById(id).subscribe(
      (user:any) =>{
        this.user = user
        console.log(user);
        console.log(id);


      }
    )


  }


  onSubmitdepositForm(){
  }

  search(query: string){
    this.searchUsers = (query) ? this.users.filter(user => user.firstName.toLowerCase().includes(query.toLowerCase())
    || user.lastName.toLowerCase().includes(query.toLowerCase())
    || user.email.toLowerCase().includes(query.toLowerCase())
    || user.phoneNumber.toLowerCase().includes(query.toLowerCase())
    ): this.users;
  }
  // getTotal(){
  //   return this.users.reduce((total, user) => {
  //     return total + parseFloat(user.balance.toString());
  //   },0)
  // }

  refreshCountries() {
    this.users = this.users
      .map((user, i) => ({id: i + 1, ...user}))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  toggleUserBlock(userId: string, isBlocked: boolean) {
    if (isBlocked) {
      this.userService.unblockUser(userId);
    } else {
      this.userService.blockUser(userId);
    }
  }

}
