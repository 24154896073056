import { Component, OnInit } from '@angular/core';
import {  Subscription } from 'rxjs';
import { Operator } from 'src/app/models/operator';
import { Transaction } from 'src/app/models/transaction';
import { OperatorService } from 'src/app/services/operator.service';
import { TransactionService } from 'src/app/services/transaction.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  transactions: Transaction[] = [] ;
  transactions$: Transaction[] = [] ;

  weeklyRevenues: { date: string, revenue: number }[];
  dailyRevenues: { date: string, revenue: number }[];
  monthlyRevenues: { date: string, revenue: number }[];
  annualRevenues: { date: string, revenue: number }[];

  operators: Operator[] = [];
  operatorsSub: Subscription;
  alert:any;

  today: Date;
  totalWithdrawals: number;
  dailyRevenue: number;
  weeklyRevenue: number;
  monthlyRevenue: number;
  annualRevenue: number;
  totalDeposits: any;
  totalDepositOperator: any;

  constructor( private transactionService: TransactionService,
      private operatorService: OperatorService
    ) { }

  ngOnInit(): void {
    this.getAllOperators();
    this.getAllTransaction();
    this.getLasteTransaction();
    this.calculateRevenue(this.totalDepositOperator);
    this.today = new Date();
    this.calculateDailyRevenue();
    this.calculateWeeklyRevenue();
    this.calculateMonthlyRevenue();
    this.calculateAnnualRevenue();
    this.calculateDailyRevenue();
    this.calculateDailyRevenueByDay();
    this.calculateWeeklyRevenueByWeek();
    this.calculateMonthlyRevenueByMonthly();
    this.calculateAnnualRevenueByAnnual();
  }

  getLasteTransaction(){
    this.transactionService.getAllTransactions().then(
      (transactions)=>{
        this.transactions$ = transactions.slice(0,5)
      });
  }
  getAllOperators(){
    this.operatorsSub = this.operatorService.getAllOperator().subscribe(
      (operators) => {
        this.operators = operators
      }
    );
  }
  getAllTransaction(){
    this.transactionService.getAllTransactions().then(
      (transactions) => {
         this.transactions = transactions;
       });
  }

   calculateRevenue(operatorId) {
    this.transactionService.getTotalDeposits().subscribe(totalDeposits => {
      this.totalDeposits = totalDeposits;
    });

    this.transactionService.getTotalWithdrawals().subscribe(totalWithdrawals => {
      this.totalWithdrawals = totalWithdrawals;
    });

    this.transactionService.getTotalDepositsForOperator(operatorId).subscribe( totalDepositOperator =>{
      this.totalDepositOperator = totalDepositOperator;
      console.log(totalDepositOperator);

    });
  }


  calculateDailyRevenue() {
    this.transactionService.getDailyRevenue(this.today)
      .then(dailyRevenue => this.dailyRevenue = dailyRevenue);
  }

  calculateWeeklyRevenue() {
    this.transactionService.getWeeklyRevenue()
      .then(weeklyRevenue => this.weeklyRevenue = weeklyRevenue);
  }

   calculateMonthlyRevenue() {
    this.transactionService.getMonthlyRevenue()
      .then(monthlyRevenue => this.monthlyRevenue = monthlyRevenue);
  }

  calculateAnnualRevenue() {
    this.transactionService.getAnnualRevenue()
      .then(annualRevenue => this.annualRevenue = annualRevenue);
  }

  calculateDailyRevenueByDay() {
    this.transactionService.getDailyRevenueByDay()
      .then(dailyRevenues => this.dailyRevenues = dailyRevenues);
  }

  calculateWeeklyRevenueByWeek() {
    this.transactionService.getWeeklyRevenueByWeek()
      .then(weeklyRevenues => this.weeklyRevenues = weeklyRevenues);
  }
  calculateMonthlyRevenueByMonthly() {
    this.transactionService.getMonthlyRevenueByMonthly()
      .then(monthlyRevenues => this.monthlyRevenues = monthlyRevenues);
      // this.prepareChartData();
  }

  calculateAnnualRevenueByAnnual() {
    this.transactionService.getAnnualRevenueByAnnual()
      .then(annualRevenues => this.annualRevenues = annualRevenues);
  }


  getoperatorName(operatorId){
    if (this.operators) {
      return this.operators.find(operator => operator.id === operatorId).name;
    }

  }

  calculateTotalDepositsForOperator(operatorId: string): number {
    const operatorTransactions = this.transactions.filter(transaction => transaction.operatorId === operatorId &&
      (transaction.type === 'depot'
      ||transaction.type === 'credit'
      || transaction.type === 'internet'
      || transaction.type === 'sodeci'
      || transaction.type === 'cie'));
    return operatorTransactions.reduce((total, transaction) => total + parseFloat(transaction.amount.toString()), 0);
  }
  calculateTotalRetraitsForOperator(operatorId: string): number {
    const operatorTransactions = this.transactions.filter(transaction => transaction.operatorId === operatorId && transaction.type === 'retrait');
    return operatorTransactions.reduce((total, transaction) => total + parseFloat(transaction.amount.toString()), 0);
  }



// La méthode pour calculer le total des dépôts
calculateTotalDeposits(): number {
  const totalDeposits = this.transactions
    .filter(transaction => transaction.type === 'depot')
    .reduce((total, transaction) => total + parseFloat(transaction.amount.toString()), 0);

  return totalDeposits;
}

// la méthode pour calculer le total des retraits
calculateTotalWithdrawals(): number {
  const totalWithdrawals = this.transactions
    .filter(transaction => transaction.type === 'retrait')
    .reduce((total, transaction) => total + parseFloat(transaction.amount.toString()), 0);

  return totalWithdrawals;
}

//  la méthode pour calculer les chiffres d'affaires annuels pour chaque année
calculateAnnualTotalRevenueByYear(): { year: number; revenue: number }[] {
  const startYear = 2022; // L'année de début des transactions
  const endYear = new Date().getFullYear(); // L'année en cours

  const annualRevenueByYear: { year: number; revenue: number }[] = [];

  for (let year = startYear; year <= endYear; year++) {
    const startOfYear = new Date(year, 0, 1);
    const endOfYear = new Date(year + 1, 0, 1);

    const yearTransactions = this.transactions.filter(
      transaction =>
        ((transaction.date)as any).toDate() >= startOfYear && ((transaction.date)as any).toDate() < endOfYear
    );

    const yearRevenue = yearTransactions.reduce(
      (total, transaction) => total + parseFloat(transaction.amount.toString()),
      0
    );

    annualRevenueByYear.push({ year, revenue: yearRevenue });
  }

  return annualRevenueByYear;
}

 // la méthode pour calculer le chiffre d'affaires journalier par operateur
 calculateDailyRevenueForOperator(operatorId: string): number {
  const today = new Date();
  const operatorTransactions = this.transactions.filter(
    transaction =>
      transaction.operatorId === operatorId &&
      this.isSameDate(((transaction.date) as any).toDate(), today)
  );

  return operatorTransactions.reduce(
    (total, transaction) => total + parseFloat(transaction.amount.toString()),
    0

  );


}

isSameDate(date1: Date, date2: Date): boolean {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}
//  calculateDailyRevenueForOperator(operatorId: string): number {
//   const today = new Date();
//   const operatorTransactions = this.transactions.filter(
//     transaction =>
//       transaction.operatorId === operatorId &&
//       this.isSameDate(((transaction.date) as any).toDate(), today)
//   );
//   const commissionRanges = [
//     { minAmount: 500, maxAmount: 2000, commission: 27 },
//     { minAmount: 2001, maxAmount: 4000, commission: 51 },
//     { minAmount: 4001, maxAmount: 5000, commission: 51 },
//     { minAmount: 5001, maxAmount: 9000, commission: 157 },
//     { minAmount: 9001, maxAmount: 10000, commission: 157 },
//     { minAmount: 10001, maxAmount: 25000, commission: 181 },
//     { minAmount: 25001, maxAmount: 50000, commission: 333 },
//     { minAmount: 50001, maxAmount: 100000, commission: 400 }
//     // Ajoutez d'autres plages et commissions si nécessaire
//   ];

//   const totalCommission = operatorTransactions.reduce(
//     (total, transaction) => {
//       const transactionAmount = parseFloat(transaction.amount.toString());

//       // Trouver la commission en fonction de la plage de montants
//       const commissionRange = commissionRanges.find(range =>
//         transactionAmount >= range.minAmount && transactionAmount <= range.maxAmount
//       );

//       if (commissionRange) {
//         console.log(total);


//         return total + commissionRange.commission;



//       } else {


//         return total;
//       }
//     },
//     0
//   );

//   return totalCommission;

// }

// isSameDate(date1: Date, date2: Date): boolean {
//   return (
//     date1.getFullYear() === date2.getFullYear() &&
//     date1.getMonth() === date2.getMonth() &&
//     date1.getDate() === date2.getDate()
//   );
// }

//  la méthode pour calculer le chiffre d'affaires hebdomadaire  par opérateur
calculateWeeklyRevenueForOperator(operatorId: string): number {
  const today = new Date();
  const startOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());

  const operatorTransactions = this.transactions.filter(
    transaction =>
      transaction.operatorId === operatorId &&
      this.isSameWeek(((transaction.date)as any).toDate(), startOfWeek)
  );

  return operatorTransactions.reduce(
    (total, transaction) => total + parseFloat(transaction.amount.toString()),
    0
  );
}

isSameWeek(date1: Date, startOfWeek: Date): boolean {
  const diff = Math.floor((date1.getTime() - startOfWeek.getTime()) / (1000 * 60 * 60 * 24));
  return diff >= 0 && diff < 7;
}

//  la méthode pour calculer le chiffre d'affaires mensuel par un opérateur
calculateMonthlyRevenueForOperator(operatorId: string): number {
  const today = new Date();
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

  const operatorTransactions = this.transactions.filter(
    transaction =>
      transaction.operatorId === operatorId &&
      this.isSameMonth(((transaction.date)as any).toDate(), startOfMonth)
  );

  return operatorTransactions.reduce(
    (total, transaction) => total + parseFloat(transaction.amount.toString()),
    0
  );
}

isSameMonth(date1: Date, startOfMonth: Date): boolean {
  return (
    date1.getFullYear() === startOfMonth.getFullYear() &&
    date1.getMonth() === startOfMonth.getMonth()
  );
}


}
