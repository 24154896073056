import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  email:string;
  resetPawsswordForm : FormGroup;
  errorMsg: string;

  constructor(
    private authService : AuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService
    ) { }

  ngOnInit(): void {
    this.initresetPForm();
  }
  initresetPForm() {
    this.resetPawsswordForm = this.formBuilder.group({
      email: ['', [Validators.email,Validators.required]]
    });
  }

  onSubmitResetpassword(){
    const email = this.resetPawsswordForm.get('email').value;
    this.authService.resetPassword(email).then(
      () => {
        console.log('code envoyer');

      }
    ).catch(
      (errMsg) => this.errorMsg = errMsg
    );
  }

}
