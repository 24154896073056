import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Operator } from 'src/app/models/operator';
import { Recharge } from 'src/app/models/recharge';
import { OperatorService } from 'src/app/services/operator.service';

@Component({
  selector: 'app-recharge-modal',
  templateUrl: './recharge-modal.component.html',
  styleUrls: ['./recharge-modal.component.css']
})
export class RechargeModalComponent implements OnInit {

  operator: Operator;
  rechargeForm : FormGroup;
  recharges: Recharge[];

  constructor( private formBuilder : FormBuilder,
    private operatorService: OperatorService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.initRechargeForm();
  }

  initRechargeForm(){
    this.rechargeForm = this.formBuilder.group({
      amount: ['', [Validators.required,Validators.pattern(/^[0-9]*$/)]]
    });
  }

    rechargeBalance(): void {
      if (this.rechargeForm.valid) {
        const amount = this.rechargeForm.value.amount;
        const newBalance = parseFloat(this.operator.balance.toString()) + amount;
        console.log(newBalance);

        this.operatorService.rechargeOperator(this.operator.id, newBalance, amount);
        this.activeModal.close('Recharge successful');
        this.toastr.success('Le recharger à été éffectué avec succèss !!!');
      }
    }


}
