import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { User } from '../models/user';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user: User[];
  currentUser:any;
  isAdmin: BehaviorSubject<boolean>;
  token: BehaviorSubject<string>;

  constructor(
    private userService: UserService,
    private toastr: ToastrService,
    private router: Router,
    private afa: AngularFireAuth,
    private afs: AngularFirestore
    ) {
      this.token = new BehaviorSubject<string>(null);
      this.isAdmin = new BehaviorSubject<boolean>(false);
     }

  // Creation d'un compte utilisateur
  signUpUser(newUser:User){
    return new Promise (
      (resolve,reject)=> {
        firebase.auth().createUserWithEmailAndPassword(newUser.email,newUser.password)
        .then(
          (currentUser) => {
            newUser.uid = currentUser.user.uid;
            newUser.lastSignInTime = currentUser.user.metadata.lastSignInTime;
            newUser.creationTime = currentUser.user.metadata.creationTime;
            // this.userService.saveUsers();
            // firebase.database().ref('/users' ).set(newUser)
            // firebase.database().ref('users/' + currentUser.user.uid).set(newUser);
            this.afs.collection('users').doc(currentUser.user.uid).set(newUser).then(
              () => {

                console.log('Compte créer avec succès !!!');
                console.log(newUser);
                this.router.navigate(['/caisse/users']);
              }
            )


          }
        ).catch(
          (error) =>{
            if (error.code === 'auth/email-already-in-use') {
              reject("L'adresse email est déjà utilisée.");
          }

          if (error.code === 'auth/invalid-email') {
            reject("L'adresse email est invalide.");
          }

          if (error.code === 'auth/operation-not-allowed') {
            reject("Une erreur est survenue.");
          }

          if (error.code === 'auth/weak-password') {
            reject("Le mot de passe n'est pas sécurisé.");
          }

          reject("Une erreur est survenue.");
          }
        )
      }
    )
  }
// Connexion utilisateur
  signInUser(email, password){
    return new Promise (
      (resolve,reject)=> {
        firebase.auth().signInWithEmailAndPassword(email,password)
        .then(
          (currentUser) => {
            this.token.next(currentUser.user.refreshToken);
            this.isCurrentUserAdmin();
            console.log('Connecté !!!');
            this.router.navigate(['/caisse/operations']);
            resolve(currentUser);
          }
        ).catch(
          (err) => {
            if (err.code === 'auth/invalid-email') {
              reject("L'adresse email est invalide.");
          }

          if (err.code === 'auth/user-disabled') {
            reject("L'utilisateur a été banni.");
          }

          if (err.code === 'auth/user-not-found') {
            reject("L'utilisateur n'existe pas.");
          }

          if (err.code === 'auth/wrong-password') {
            reject("Le mot de passe est incorrect.");
          }

          reject("Une erreur est survenue.");
          }
        )
      }
    )
  }
  // Se connecter avec Google
  signInwithGoogle(){
   return firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider());
  }
// Déconnexion utilisateur
signOutUser(){
  firebase.auth().signOut().then(
    () => {
      this.token.next(null);
      this.currentUser = null;
      this.isAdmin.next(false);
      this.toastr.info('Vous êtes bien déconnecté !');
      this.router.navigate(['/auth/login']);
    }
  ).catch(
    (error) => {
      console.error(error);
    }
  );
}

// réinitialisation de mot de passe
resetPassword(email: string){
  return new Promise(
    (resolve, reject) => {
      firebase.auth().sendPasswordResetEmail(email).then(
        ()=> {
          this.toastr.info('Un lien de reinitialisation vous a été envoyé par le mail indiqué !');
          this.router.navigate(['/auth/login']);


        }
      ).catch(
        (error) =>{
          if (error.code === 'auth/user-not-found') {
            reject("L'utilisateur n'existe pas.");
            this.toastr.info('Utilisateur non trouvé');
        }
        }
      )
    }
  )
}
// Obtenir le token utilisateur
getToken(){
  if (!this.token.getValue()) {
    this.afa.authState.subscribe(
      (user) => {
        if (user && user.uid) {
          this.token.next(user.refreshToken);
          return this.token;
        }
      }
    )
  }
  return this.token;
}

// checker si l'utilisateur est Admin
// Fonction privée
isCurrentUserAdmin(){

  if (this.currentUser) {
    ['admin@bouba.com','direction@iklassgestion.com','kady@iklassgestion.com','bureau1@iklassgestion.com'].includes(this.currentUser.email) ? this.isAdmin.next(true) : this.isAdmin.next(false);
  } else {
    this.afa.user.subscribe(
      (user) => {
        if (user) {
          this.currentUser = user;
          ['admin@bouba.com','direction@iklassgestion.com','kady@iklassgestion.com','bureau1@iklassgestion.com'].includes(user.email) ? this.isAdmin.next(true) : this.isAdmin.next(false);
        }else{
          this.isAdmin.next(false);
        }

      }
    );
  }

}

}
