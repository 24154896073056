<div class="content-body">
  <div class="container-fluid">
    <div class="form-head mb-sm-5 mb-3 d-flex align-items-center flex-wrap">
      <h2 class="font-w600 mb-0 mr-auto mb-2 text-black">Mon portefeuille</h2>
      <!-- <a href="javascript:void(0);" class="btn btn-outline-secondary mr-3 mb-2"><i class="las la-calendar scale5 mr-2"></i>Filter Periode</a> -->
      <a href="javascript:void(0);" class="btn btn-secondary btn-xs mb-2 m-2" data-toggle="modal" data-target="#exampleModal" ><i class="fas fa-money-check-alt m-1"></i>Depôt de fonds</a>
      <a href="javascript:void(0);" class="btn btn-secondary btn-xs mb-2 m-2" ><i class="far fa-money-bill-alt m-1"></i> Retrait des fonds</a>
    </div>
      <div class="row">
          <div class="col-xl-9 col-xl-9" *ngIf="user">
            <div class="col-sm-4" *ngFor="let item of cryptoCards">
              <div class="card-bx stacked card">
                  <img class="bg-img" src="{{item.image}}" alt="">
                  <div class="card-info">
                      <p class="mb-1 text-white fs-14">Solde actuel</p>
                      <div class="d-flex justify-content-between align-items-start">
                          <h2 class="num-text text-white mb-5 font-w600">{{user.balance}} FCFA </h2>
                          <img src="{{item.icon_image}}" alt="">
                      </div>
                      <div class="d-flex">
                          <div class="mr-4 text-white">
                              <p class="fs-12 mb-1 op6">VALID THRU</p>
                              <span>{{item.valid_thrugh}}</span>
                          </div>
                          <div class="text-white">
                              <p class="fs-12 mb-1 op6">CARD HOLDER</p>
                              <span>{{user.firstName}} {{user.lastName}}</span>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          </div>
          <div class="col-xl-3 col-xl-3">
              <div class="row">
                  <div class="col-xl-12">
                      <div class="d-block d-sm-flex mb-4">
                          <h4 class="mb-0 text-black fs-20 mr-auto">Card Details</h4>
                          <div class="d-flex mt-sm-0">
                              <a href="javascript:void(0);" class="btn-link mr-3 underline">Generate Number</a>
                              <a href="javascript:void(0);" class="btn-link underline">Edit</a>
                          </div>
                      </div>
                  </div>
                  <div class="col-xl-12">
                      <!-- <app-card-details></app-card-details> -->
                  </div>
                  <div class="col-xl-12">
                      <!-- <app-graph-coin-chart></app-graph-coin-chart> -->
                  </div>
              </div>
          </div>
          <div class="col-xl-6  mt-4">
              <div class="row">
                  <div class="col-xl-12">
                      <!-- <app-walle-activity></app-walle-activity> -->
                  </div>
              </div>
          </div>
          <div class="col-xl-6 mt-4">
              <div class="row">
                  <div class="col-xl-12">
                      <!-- <app-quick-transfer-crousal [data]="quickTransfer"></app-quick-transfer-crousal> -->
                  </div>
              </div>
          </div>
      </div>

      <!-- Modal de Dépôt -->

      <!-- Button trigger modal -->
<button type="button" class="btn btn-primary btn-xs" data-toggle="modal" data-target="#exampleModal" >
  Launch demo modal
</button>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <form >
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Dépôt</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">FCFA 100</span>
            </div>
            <input type="number" class="form-control" placeholder="le montant à dépôser" >
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger btn-xs" data-dismiss="modal">Annuler</button>
          <button type="submit" class="btn btn-primary btn-xs">Faire le dépôt</button>
        </div>
      </form>
    </div>
  </div>
</div>
</div>
</div>
