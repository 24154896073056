import { Injectable } from '@angular/core';
import { Expense } from '../models/expense';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CaisseService } from './caisse.service';

@Injectable({
  providedIn: 'root'
})
export class ExpenseService {

  private expensesCollection: AngularFirestoreCollection<Expense>;

  constructor(private afs: AngularFirestore,
    private caisseService:CaisseService
    ) {
    this.expensesCollection = this.afs.collection<Expense>('expenses');
  }

  getAllExpense(): Observable<Expense[]> {
    return this.expensesCollection.snapshotChanges().pipe(
      map(expenses =>
        expenses.map(a => {
          const data = a.payload.doc.data() as Expense;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      )
    );
  }
  getAllExpenses(): Promise<Expense[]> {
    return this.afs.collection('expenses', ref => ref.orderBy('date', 'desc'))
    .get()
    .toPromise()
    .then(snapshot => {
      const expenses: Expense[] = [];
      snapshot.forEach(doc => {
        const data = doc.data() as Expense;
        const id = doc.id;
        expenses.push({ id, ...data });
      });
      return expenses;
    });
  }


  getByIdExpense(expenseId: string): Observable<Expense | undefined> {
    return this.expensesCollection.doc<Expense>(expenseId).valueChanges();
  }

  CreateExpense(expense: Expense): Promise<any> {
    return this.expensesCollection.add(expense);
  }

  editExpense(expenseId: string, expense: Expense): Promise<void> {
    return this.expensesCollection.doc(expenseId).update(expense);
  }
  // Met à jour le montant du fond de caisse dans Firestore
  // updateCashFundAmount(caisseId: string,newAmount: number): Promise<void> {
  //   return this.expensesCollection.doc(caisseId).update({ balance: newAmount });
  // }

  deleteExpense(expenseId: string): Promise<void> {
    return this.expensesCollection.doc(expenseId).delete();
  }

  getDailyExpenseRevenue(date: Date): Promise<number> {
    const startOfDay = new Date(date);
     startOfDay.setHours(0, 0, 0, 0);
    const endOfDay = new Date(date);
     endOfDay.setHours(23, 59, 59, 999);

    return this.afs.collection('expenses', ref =>
      ref.where('date', '>=', startOfDay)
         .where('date', '<=', endOfDay)
    )
    .get()
    .toPromise()
    .then(snapshot => {
      let dailyExpenseRevenue = 0;
      snapshot.forEach(doc => {
        const data = doc.data() as Expense;
        dailyExpenseRevenue += parseFloat(data.amount.toString());
      });
      return dailyExpenseRevenue;
    });
  }


  getMonthlyRevenueExpense(): Promise<number> {
    const today = new Date();
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    startOfMonth.setHours(0, 0, 0, 0);

    const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    endOfMonth.setHours(23, 59, 59, 999);


    return this.afs.collection('expenses', ref =>
      ref.where('date', '>=', startOfMonth)
         .where('date', '<=', endOfMonth)
    )
    .get()
    .toPromise()
    .then(snapshot => {
      let monthlyRevenueExpense = 0;
      snapshot.forEach(doc => {
        const data = doc.data() as Expense;
        monthlyRevenueExpense += parseFloat(data.amount.toString());
      });
      return monthlyRevenueExpense;
    });
  }

}
