import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import firebase from 'firebase/app';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';
import * as $ from 'jquery';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  editUserForm : FormGroup;
  errorMsg: string;
  user: User;
  isAuth = false;
  photoUploading = false;
  photoUploaded = false;
  photoUrl: string;

  constructor(
    private formBuilder : FormBuilder,
    public userService: UserService,
    private authService : AuthService,
    private router: Router,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.initEditUsers();
    firebase.auth().onAuthStateChanged(
      (user) => {
        if (user) {
          this.isAuth = true;
          console.log(this.isAuth);
          console.log(this.userService.getUserById(user.uid));
          this.userService.getUserById(user.uid).subscribe(
            (user: User) => {
              this.user = user;
              console.log(user);
              
            });

        } else {
          this.isAuth = false;
          console.log(this.isAuth);

        }
      }
    );

  }

  initEditUsers(){
    this.editUserForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.email,Validators.required]],
      oldPassword: ['', [Validators.required, Validators.pattern(/[0-9a-zA-Z]{6,}/)]],
      newPassword: ['', [Validators.required, Validators.pattern(/[0-9a-zA-Z]{6,}/)]],
      phoneNumber: ['', [Validators.required]],
    });
  }
  onEditUser(){
    this.editUserForm.get('firstName').setValue(this.user.firstName);
    this.editUserForm.get('lastName').setValue(this.user.lastName);
    this.editUserForm.get('email').setValue(this.user.email);
    // this.editUserForm.get('password').setValue(this.user.password);
    this.editUserForm.get('phoneNumber').setValue(this.user.phoneNumber);
    // this.photoUrl = this.user.photo ? this.user.photo: '';
  }
  onUploadFile(event){
    this.photoUploading = true;

    this.userService.uploadFile(event.target.files[0]).then(
      (url: string) => {
        if (this.photoUrl && this.photoUrl !== '') {
          this.userService.removeFile(this.photoUrl);
        }
        this.photoUrl =  url;
        this.photoUploading = false;
        this.photoUploaded = true;
        setTimeout(
          () => {
            this.photoUploaded = true;
          }, 5000
        );
      }
    )
    console.log(event);

  }

  onSubmitEditUserForm(){
    const user = firebase.auth().currentUser;
    const editUser: User = this.editUserForm.value;
    // editUser.photo = this.photoUrl ? this.photoUrl : '';
    this.authService.signInUser(this.user.email, this.editUserForm.value.oldPassword).then(
      ()=> {
        user.updatePassword(this.editUserForm.value.newPassword);
      }
    ).catch(
      (errMsg) => this.errorMsg = errMsg
    );

    this.userService.editUser(user.uid,editUser);
    this.router.navigate(['/caisse/profile']);

    console.log(editUser);
    $('#exampleModal').modal('hide');

  }
  open(modelId:any) {
		this.modalService.open(modelId);
	}
  resetForm(){
    this.editUserForm.reset();
    this.photoUrl = '';
  }

}
