import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Car } from 'src/app/models/car';
import { Driver } from 'src/app/models/driver';
import { CarService } from 'src/app/services/car.service';
import { DriverService } from 'src/app/services/driver.service';

@Component({
  selector: 'app-car-edit',
  templateUrl: './car-edit.component.html',
  styleUrls: ['./car-edit.component.css']
})
export class CarEditComponent implements OnInit {

  carEditForm: FormGroup;
  cars: Car[] = [];
  car: Car;
  availableDrivers: Driver[] = []; // Tableau des chauffeurs disponibles
  errorMsg: string;
  carId: string;

  constructor(
    private fb: FormBuilder,
    private carService: CarService,
    private driverService: DriverService,
    private router: Router,
    private toastr: ToastrService,
    private route : ActivatedRoute
  ) {

  }

  ngOnInit(): void {
    this.initCarEditForm();
    this.getDrivers();
    this.loadCars();
    this.loadAvailableDrivers();
    this.carId = this.route.snapshot.params.id;
    this.carService.getByIdCar(this.carId).subscribe(
      (car)=>{
        this.car = car
        this.carEditForm.patchValue(car);
        console.log(car);
        

      });


  }

  getDrivers(){
    this.driverService.getDrivers().subscribe((data) => {
      this.availableDrivers = data;
    });
  }
  loadCars() {
    this.carService.getCars().subscribe((data) => {
      this.cars = data;
      this.loadAvailableDrivers();
    });
  }



  initCarEditForm(){
    this.carEditForm = this.fb.group({
      brand: ['', Validators.required],
      registerNumber: ['', Validators.required],
      indexCar: ['', Validators.required],
      driver1: [''],
      driver2: [''],
    });
  }
  loadAvailableDrivers() {
    this.driverService.getDrivers().subscribe((data) => {
      this.availableDrivers = data.filter((driver) => !this.isDriverAssociated(driver.id));
    });
  }

  isDriverAssociated(driverId: string): boolean {
    // Vérifie si le chauffeur est déjà associé à un véhicule
    for (const car of this.cars) {
      if (car.driverIds.includes(driverId)) {
        return true;
      }
    }
    return false;
  }



  onSubmitCarEditForm() {
    if (this.carEditForm.valid) {
      const carEditData = this.carEditForm.value;
      const driverIds: string[] = [carEditData.driver1, carEditData.driver2].filter(Boolean);
      carEditData.driverIds = driverIds;
      this.carService.updateCar(this.carId,carEditData).then(() => {

        this.toastr.success('Le véhicule à été modifier avec succès.');
        this.router.navigate(['caisse/cars']);
        this.loadCars();
        this.loadAvailableDrivers();
        
      }).catch(
        (errMsg) => {
          this.errorMsg = errMsg
        });
    }
  }


}
