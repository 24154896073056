import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Car } from 'src/app/models/car';
import { Driver } from 'src/app/models/driver';
import { CarService } from 'src/app/services/car.service';
import { DriverService } from 'src/app/services/driver.service';

@Component({
  selector: 'app-drive-form',
  templateUrl: './drive-form.component.html',
  styleUrls: ['./drive-form.component.css']
})
export class DriveFormComponent implements OnInit {

  driverForm: FormGroup;
  errorMsg: string;
  // availableVehicles: string[] = []; // Liste des véhicules disponibles

  constructor(
    private fb: FormBuilder,
    private driverService: DriverService,
    private carService: CarService,
    private router: Router,
    private toastr: ToastrService,
  ) {

  }
  ngOnInit(): void {
    this.initDriveForm();

  }



  initDriveForm(){
    this.driverForm = this.fb.group({
      name: ['', [Validators.required]],
      driverPhone: ['', [Validators.required]],
      date: ['']
    });
  }


  onSubmitDriverForm(): void {
    if (this.driverForm.valid) {
      const driverData = this.driverForm.value;
      driverData.date = new Date();
      this.driverService.addDriver(driverData).then(() => {
        console.log('Enregistrer !!!!!!!!');
        this.toastr.success('Le nom du chauffeur à été enregistrer avec succès.');
        this.router.navigate(['caisse/drivers']);
      }).catch(
        (errMsg) => {
          this.errorMsg = errMsg
        });;
    }
  }

}
