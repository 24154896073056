import { Injectable } from '@angular/core';
import { Caisse } from '../models/caisse';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class CaisseService {

  private caissesCollection: AngularFirestoreCollection<Caisse>;

  constructor(private afs: AngularFirestore) {
    this.caissesCollection = this.afs.collection<Caisse>('caisses');
  }

  getAllCaisse(): Observable<Caisse[]> {
    return this.caissesCollection.snapshotChanges().pipe(
      map(caisses =>
        caisses.map(a => {
          const data = a.payload.doc.data() as Caisse;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      )
    );
  }

  getByIdCaisse(caisseId: string): Observable<Caisse | undefined> {
    return this.caissesCollection.doc<Caisse>(caisseId).valueChanges();
  }

  CreateCaisse(caisse: Caisse): Promise<any> {
    return this.caissesCollection.add(caisse);
  }

  editCaisse(caisseId: string, caisse: Caisse): Promise<void> {
    return this.caissesCollection.doc(caisseId).update(caisse);
  }
  

  deleteCaisse(caisseId: string): Promise<void> {
    return this.caissesCollection.doc(caisseId).delete();
  }



  updateFundBalance(fundId: string, amount: number, transactionType: string): Promise<void> {
    const fundDocRef = this.afs.collection('caisses').doc(fundId).ref;

  return this.afs.firestore.runTransaction(async transaction => {
    const fundDoc = await transaction.get(fundDocRef);
    const currentBalance = fundDoc.get('balance');

    let newBalance = currentBalance;

    if (
    transactionType === 'depot' ||
    transactionType === 'credit' ||
    transactionType === 'internet' ||
    transactionType === 'sodeci' ||
    transactionType === 'cie' ||
    transactionType === 'carte UBA' ||
    transactionType === 'inscription' ||
    transactionType === 'starTimes' ||
    transactionType === 'canal') {

      newBalance += parseFloat(amount.toString());
    } else if (transactionType === 'retrait') {
      newBalance -= parseFloat(amount.toString());
    }

    // Utiliser set() au lieu de update() pour créer un document s'il n'existe pas
    transaction.set(fundDocRef, { balance: newBalance });

    return newBalance;
  });
  }
  updateExpenseBalance(fundId: string, amount: number): Promise<void> {
    const fundDocRef = this.afs.collection('caisses').doc(fundId).ref;

  return this.afs.firestore.runTransaction(async transaction => {
    const fundDoc = await transaction.get(fundDocRef);
    const currentBalance = fundDoc.get('balance');

    let newBalance = currentBalance;

    if (newBalance) {

      newBalance -= parseFloat(amount.toString());
    }

    // Utiliser set() au lieu de update() pour créer un document s'il n'existe pas
    transaction.update(fundDocRef, { balance: newBalance });

    return newBalance;
  });
  }


 

   

 


  }




