import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Expense } from 'src/app/models/expense';
import { CaisseService } from 'src/app/services/caisse.service';
import { ExpenseService } from 'src/app/services/expense.service';

@Component({
  selector: 'app-depenses-edit',
  templateUrl: './depenses-edit.component.html',
  styleUrls: ['./depenses-edit.component.css']
})
export class DepensesEditComponent implements OnInit {

  expenseEditForm : FormGroup;
  errorMsg: string;
  expenseId:string;
  expense: Expense;

  constructor(private formBuilder : FormBuilder,
              private caisseService:CaisseService,
              private expenseService: ExpenseService,
              private router: Router,
              private route: ActivatedRoute,
              private toastr: ToastrService,
              ) { }

  ngOnInit(): void {
    this.initExpenseEditForm();
    this.expenseId = this.route.snapshot.params.id;
    this.expenseService.getByIdExpense(this.expenseId).subscribe(
      (expense)=> {
        this.expense = expense;
        this.expenseEditForm.patchValue(expense);
      }
    )
  }

  initExpenseEditForm(){
    this.expenseEditForm = this.formBuilder.group({
      label: ['', [Validators.required]],
      amount: ['', [Validators.required,Validators.pattern(/^[0-9]*$/)]]
    });
  }

  onSubmitExpenseEditForm(){
    this.errorMsg = null;
    const expenseEditData: Expense = this.expenseEditForm.value;
        // caisseData.amount = this.caisseForm.value.amount.replace(/\s/g, '');
        expenseEditData.date = new Date();
    this.expenseService.editExpense(this.expenseId,expenseEditData)
                      .then(
                        () => {
                          this.router.navigate(['caisse/expenses']);
                          console.log('Dépense Modifier avec succès');
                          this.toastr.success('Dépense modifier avec succès.');

                        })
                      .catch(
                        (errMsg) => {
                          this.errorMsg = errMsg
                        })

  }


}
