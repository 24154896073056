import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import localeFr from '@angular/common/locales/fr'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { HomeComponent } from './home/home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NavComponent } from './nav/nav.component';
import { UsersListeComponent } from './users-liste/users-liste.component';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HttpClientModule } from '@angular/common/http';
import { NgApexchartsModule } from 'ng-apexcharts';
import { LightboxModule } from 'ngx-lightbox';
import { FullCalendarModule } from '@fullcalendar/angular';
import { MetismenuAngularModule } from "@metismenu/angular";
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ToastrModule } from 'ngx-toastr';
// import { SwiperModule } from 'swiper/angular';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule} from '@angular/fire/firestore';
import { AngularFireAuthModule} from '@angular/fire/auth';
import { AngularFireAuthGuard} from '@angular/fire/auth-guard';

import { MatListModule } from '@angular/material/list';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSliderModule } from '@angular/material/slider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRippleModule } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { EmailVerifiedComponent } from './mail/email-verified/email-verified.component';
import { MyWalletComponent } from './client/my-wallet/my-wallet.component';
import { AccountNavigateComponent } from './client/account-navigate/account-navigate.component';
import { AccountHeaderComponent } from './client/account-header/account-header.component';
import { AccountNavComponent } from './client/account-nav/account-nav.component';
import { AccountUrlComponent } from './client/account-url/account-url.component';
import { DashboardComponent } from './client/dashboard/dashboard.component';
import { TransactionsComponent } from './client/transactions/transactions.component';
import { ProfileComponent } from './client/profile/profile.component';
import { environment } from 'src/environments/environment';
import firebase from 'firebase/app';
import { AuthService } from './services/auth.service';
import { UsersEditComponent } from './client/users-edit/users-edit.component';
import { OperatorListComponent } from './client/operators/operator-list/operator-list.component';
import { OperatorFormComponent } from './client/operators/operator-form/operator-form.component';
import { OperationFormComponent } from './client/operations/operation-form/operation-form.component';
import { OperationListComponent } from './client/operations/operation-list/operation-list.component';
import { OperatorEditComponent } from './client/operators/operator-edit/operator-edit.component';
import { OperatorModalComponent } from './client/operators/operator-modal/operator-modal.component';
import { OperationEditComponent } from './client/operations/operation-edit/operation-edit.component';
import { registerLocaleData } from '@angular/common';
import { UserFormComponent } from './client/users/user-form/user-form.component';
import { UserListComponent } from './client/users/user-list/user-list.component';
import { RechargeModalComponent } from './client/operators/recharge-modal/recharge-modal.component';
import { OperatorDetailComponent } from './client/operators/operator-detail/operator-detail.component';
import { RetraitModalComponent } from './client/operators/retrait-modal/retrait-modal.component';
import { CaisseComponent } from './client/caisses/caisse/caisse.component';
import { CaisseListComponent } from './client/caisses/caisse-list/caisse-list.component';
import { CaisseEditComponent } from './client/caisses/caisse-edit/caisse-edit.component';
import { FormatNumberDirective } from './directives/format-number.directive';

// ngx-print
import {NgxPrintModule} from 'ngx-print';
// ngx-pagination
import {NgxPaginationModule} from 'ngx-pagination';

import { DepensesFormComponent } from './client/depenses/depenses-form/depenses-form.component';
import { DepensesListComponent } from './client/depenses/depenses-list/depenses-list.component';
import { DepensesEditComponent } from './client/depenses/depenses-edit/depenses-edit.component';
import { PrintModalComponent } from './client/operations/print-modal/print-modal.component';
import { CarFormComponent } from './client/cars/car-form/car-form.component';
import { CarListComponent } from './client/cars/car-list/car-list.component';
import { CarEditComponent } from './client/cars/car-edit/car-edit.component';
import { DailyVersementComponent } from './client/cars/daily-versement/daily-versement.component';
import { DriveFormComponent } from './client/drivers/drive-form/drive-form.component';
import { DriveListComponent } from './client/drivers/drive-list/drive-list.component';
import { DriveEditComponent } from './client/drivers/drive-edit/drive-edit.component';
import { DailyVersementListComponent } from './client/cars/daily-versement-list/daily-versement-list.component';
import { DailyVersementEditComponent } from './client/cars/daily-versement-edit/daily-versement-edit.component';
import { DailyPointageComponent } from './client/cars/daily-pointage/daily-pointage.component';
import { ModalDetteComponent } from './client/cars/modal-dette/modal-dette.component';
import { DailyPaymentDetailComponent } from './client/cars/daily-payment-detail/daily-payment-detail.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CancelDebtComponent } from './client/cars/cancel-debt/cancel-debt.component';

firebase.initializeApp(environment.firebaseConfig);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    HomeComponent,
    NavComponent,
    UsersListeComponent,
    ResetPasswordComponent,
    EmailVerifiedComponent,
    MyWalletComponent,
    AccountNavigateComponent,
    AccountHeaderComponent,
    AccountNavComponent,
    AccountUrlComponent,
    DashboardComponent,
    TransactionsComponent,
    ProfileComponent,
    UsersEditComponent,
    OperatorListComponent,
    OperatorFormComponent,
    OperationFormComponent,
    OperationListComponent,
    OperatorEditComponent,
    OperatorModalComponent,
    OperationEditComponent,
    UserFormComponent,
    UserListComponent,
    RechargeModalComponent,
    OperatorDetailComponent,
    RetraitModalComponent,
    CaisseComponent,
    CaisseListComponent,
    CaisseEditComponent,
    FormatNumberDirective,
    DepensesFormComponent,
    DepensesListComponent,
    DepensesEditComponent,
    PrintModalComponent,
    CarFormComponent,
    CarListComponent,
    CarEditComponent,
    DailyVersementComponent,
    DriveFormComponent,
    DriveListComponent,
    DriveEditComponent,
    DailyVersementListComponent,
    DailyVersementEditComponent,
    DailyPointageComponent,
    ModalDetteComponent,
    DailyPaymentDetailComponent,
    CancelDebtComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    BrowserAnimationsModule,
     NgxPrintModule,
     NgxPaginationModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    ToastrModule . forRoot ( {timeOut: 10000}) ,
    NgxSpinnerModule,
    HttpClientModule,
    NgApexchartsModule,
    LightboxModule,
    FullCalendarModule,
    MetismenuAngularModule,
    PerfectScrollbarModule,
    NgxDropzoneModule,
    CarouselModule,
    // SwiperModule,



    MatListModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    MatIconModule,
    MatTabsModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatRadioModule,
    DragDropModule,
    MatChipsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatToolbarModule,
    MatMenuModule,
    MatDividerModule,
    MatExpansionModule,
    MatSelectModule,
    MatGridListModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatSliderModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTooltipModule,
    MatTreeModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    AuthService,
    NgbActiveModal,
    AngularFireAuthGuard,
    {provide: LOCALE_ID,
    useValue:'fr'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(){
    registerLocaleData(localeFr);
  }
 }
