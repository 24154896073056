import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { User } from '../models/user';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-users-liste',
  templateUrl: './users-liste.component.html',
  styleUrls: ['./users-liste.component.css']
})
export class UsersListeComponent implements OnInit {

  user: User;
  users : User[] = [];
  usersSubscription: Subscription;
  editMode= false;
  indexToUpdate;
  searchUsers: User[];
  page = 1;
  pageSize = 2;
  collectionSize = this.users.length;
  total: number = 0;
  rateSubscription: Subscription;

  objectKeys = Object.keys;
  cryptos: any;

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private route: ActivatedRoute,
  ) {
    this.refreshCountries();
  }

  ngOnInit(): void {
    this.usersSubscription = this.userService.getAllUsers().subscribe(
      data => {
       this.searchUsers = this.users = data;

      }
    );
    const id = this.route.snapshot.params.id;
    this.userService.getUserById(id).subscribe(
      (user:any) =>{
        this.user = user
        console.log(user);
        console.log(id);


      }
    )


  }



  onSubmitdepositForm(){
  }

  search(query: string){
    this.searchUsers = (query) ? this.users.filter(user => user.firstName.toLowerCase().includes(query.toLowerCase())
    || user.lastName.toLowerCase().includes(query.toLowerCase())
    || user.email.toLowerCase().includes(query.toLowerCase())
    // || user.address.toLowerCase().includes(query.toLowerCase())
    || user.uid.toLowerCase().includes(query.toLowerCase())
    ): this.users;
  }
  // getTotal(){
  //   return this.users.reduce((total, user) => {
  //     return total + parseFloat(user.balance.toString());
  //   },0)
  // }

  refreshCountries() {
    this.users = this.users
      .map((user, i) => ({id: i + 1, ...user}))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

}
