import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Operator } from 'src/app/models/operator';
import { Transaction } from 'src/app/models/transaction';
import { CaisseService } from 'src/app/services/caisse.service';
import { OperatorService } from 'src/app/services/operator.service';
import { TransactionService } from 'src/app/services/transaction.service';

@Component({
  selector: 'app-operation-edit',
  templateUrl: './operation-edit.component.html',
  styleUrls: ['./operation-edit.component.css']
})
export class OperationEditComponent implements OnInit {

  transactionEditForm:FormGroup;
  errorMsg: string;
  transactionId: string;
  transaction: Transaction | undefined;
  operators: Array<Operator>;
  operatorsSub: Subscription;
  fundId: string;

  constructor(private formBuilder: FormBuilder,
    private transactionService: TransactionService,
    private router: Router,
    private operatorService: OperatorService,
    private caisseService: CaisseService,
    private toastr: ToastrService,
    private route: ActivatedRoute
) {
}

ngOnInit(): void {

   this.transactionId = this.route.snapshot.params.id;
    this.transactionService.getByIdTransaction(this.transactionId).subscribe(
      (transaction)=> {
        this.transaction = transaction
        this.transactionEditForm.patchValue(transaction);
        console.log(transaction);


      }
    );
    this.caisseService.getAllCaisse().subscribe(funds => {
      if (funds && funds.length > 0) {
        this.fundId = funds[0].id;
      }
    });
   // Récupérer l'identifiant (ID) de la transaction depuis l'URL
  //  const transactionId = this.route.snapshot.paramMap.get('id');
  //  if (transactionId) {
  //    this.transactionService.getTransactionById(transactionId).subscribe((transaction) => {
  //      this.transaction = transaction;
  //      this.transactionEditForm.patchValue(transaction);
  //      console.log(transaction);

  //    });
  //  }

      this.initTransactionForm();
      this.initSub()
      this.operatorService.getAllOperator();
      // this.updateOperatorBalanceOnChange(this.transaction);

}





initSub(){
    this.operatorsSub = this.operatorService.getAllOperator().subscribe(
    (operators) => {
    this.operators = operators
    }
    );
}

initTransactionForm(){
  // function asyncValidator(control: FormControl): Promise<{ [key: string]: any }> {
  //   return new Promise(resolve => {
  //     // Simulate an asynchronous validation
  //     setTimeout(() => {
  //       if (control.value === 'something') {
  //         resolve({ asyncError: true });
  //       } else {
  //         resolve(null);
  //       }
  //     }, 1000);
  //   });
  // }

    this.transactionEditForm = this.formBuilder.group({
    numberPhone: ['',[Validators.required]],
    type: ['',[Validators.required]],
    operatorId: ['',[Validators.required]],
    status: ['',[Validators.required]],
    amount: ['',[ Validators.min(100),Validators.pattern(/^[0-9]*$/)]],
    date: ['']
})
}

onSubmitTransactionEditForm(){
this.errorMsg = null;
const transactionEditData: Transaction = this.transactionEditForm.value;
const newAmount = transactionEditData.amount;
  // transactionEditData.amount = this.transactionEditForm.value.amount.replace(/\s/g, '');
  const difference = newAmount - transactionEditData.amount;
  console.log(difference);
  
  this.caisseService.updateFundBalance(this.fundId, difference, transactionEditData.type);
this.transactionService.editTransaction(this.transactionId,transactionEditData)
            .then(
              () => {

                this.router.navigate(['caisse/operations']);
                console.log('Creation avec Success');
                this.toastr.success('La transaction à été modifier avec succès.');
                this.updateOperatorBalanceOnChange(transactionEditData);

              })
            .catch(
              (errMsg) => {
                this.errorMsg = errMsg
              })

}

 // Mettre à jour le solde de l'opérateur lorsque le montant de la transaction est modifié
//  updateOperatorBalanceOnChange(): void {
//   this.transactionEditForm.get('operatorId')?.valueChanges.subscribe((operatorId) => {
//     const operator = this.operators.find((op) => op.id === operatorId);

//     if (operator) {
//       this.transactionEditForm.get('amount')?.valueChanges.subscribe((amount) => {
//         const initialAmount = this.transactionEditForm.value.amount;
//         const balanceDifference = amount - initialAmount;
//         operator.balance += balanceDifference;
//         this.operatorService.editOperator(operatorId, operator);
//       });
//     }
//   });
// }

 // Mettre à jour le solde de l'opérateur lorsque le montant de la transaction est modifié
 updateOperatorBalanceOnChange(transaction : Transaction): void {
  const initialAmount = this.transactionEditForm.value.amount;
  let initialOperatorId = this.transactionEditForm.value.operatorId;

  this.transactionEditForm.get('operatorId')?.valueChanges.subscribe((operatorId) => {
    initialOperatorId = operatorId;
  });

  this.transactionEditForm.get('amount')?.valueChanges.subscribe((amount) => {
    const operator = this.operators.find((op) => op.id === initialOperatorId);

    if (operator) {
      const balanceDifference = amount - initialAmount;
      operator.balance += balanceDifference;
      this.operatorService.editOperator(initialOperatorId, operator);
    }
  });
}

}
