import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Transaction } from 'src/app/models/transaction';
import { OperatorService } from 'src/app/services/operator.service';
import { TransactionService } from 'src/app/services/transaction.service';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.css']
})
export class TransactionsComponent implements OnInit {
  operators: any[];
  newOperatorName: string;
  newOperatorBalance: number;
  editingOperator: any;
  transactions: any[];
  newTransactionType: string;
  newTransactionOperator: any;
  newTransactionAmount: number;
  dailyRevenue: number;
  weeklyRevenue: number;
  monthlyRevenue: number;

  constructor() {}

  ngOnInit() {
  }



}
