import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import { Router } from '@angular/router';
import {ToastrService} from "ngx-toastr";
import { Caisse } from 'src/app/models/caisse';
import { CaisseService } from 'src/app/services/caisse.service';

@Component({
  selector: 'app-caisse',
  templateUrl: './caisse.component.html',
  styleUrls: ['./caisse.component.css']
})
export class CaisseComponent implements OnInit {

  caisseForm : FormGroup;
  errorMsg: string;
  caisse: Caisse;

  constructor(private formBuilder : FormBuilder,
              private caisseService:CaisseService,
              private router: Router,
              private toastr: ToastrService,
              ) { }

  ngOnInit(): void {
    this.initCaisseForm();
  }

  initCaisseForm(){
    this.caisseForm = this.formBuilder.group({
      balance: ['', [Validators.required,Validators.pattern(/^[0-9]*$/)]]
    });
  }
  onSubmitcaisseBalanceForm(){
    this.errorMsg = null;
    const caisseData: Caisse = this.caisseForm.value;
        // caisseData.amount = this.caisseForm.value.amount.replace(/\s/g, '');
    this.caisseService.CreateCaisse(caisseData)
                      .then(
                        () => {
                          this.router.navigate(['caisse/cash']);
                          console.log('Fond de caisse enregistrée avec succès');
                          this.toastr.success('Fond de caisse enregistrée avec succès.');

                        })
                      .catch(
                        (errMsg) => {
                          this.errorMsg = errMsg
                        })

  }

  }


