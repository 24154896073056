import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Caisse } from 'src/app/models/caisse';
import { CaisseService } from 'src/app/services/caisse.service';
import { OperatorModalComponent } from '../../operators/operator-modal/operator-modal.component';
import { ToastrService } from 'ngx-toastr';
import { Transaction } from 'src/app/models/transaction';
import { TransactionService } from 'src/app/services/transaction.service';
import { OperatorService } from 'src/app/services/operator.service';
import { Operator } from 'src/app/models/operator';
import { Subscription } from 'rxjs';
import { ExpenseService } from 'src/app/services/expense.service';

@Component({
  selector: 'app-caisse-list',
  templateUrl: './caisse-list.component.html',
  styleUrls: ['./caisse-list.component.css']
})
export class CaisseListComponent implements OnInit {

  caisses : Caisse[] = [];

  transactions: Transaction[] = [] ;
  operators: Operator[] = [];
  operatorsSub: Subscription;

  cashRevenue: number;
  dailyExpenseRevenue: number=0;
  today: Date;

  totalLength: number;
  page : number = 1;

  constructor( private caisseService: CaisseService,
    private transactionService: TransactionService,
    private operatorSerivce: OperatorService,
    private expenseService : ExpenseService,
    private modalService: NgbModal,
    private toastr: ToastrService
    ) { }

  ngOnInit(): void {
    this.getAllTransaction();
    this.caisseService.getAllCaisse().subscribe(
      (caisses)=>{
        this.caisses = caisses;

      });
    this.today = new Date();
    this.calculateCashRevenue();
    this.calculateDailExpenseRevenue();
  }

  onClickDeleteCaisse(caisseId){
    const  confirmModal = this.modalService.open(OperatorModalComponent).result;
    confirmModal.then(()=>{
      this.caisseService.deleteCaisse(caisseId)
    .then(
      () =>{

        console.log('Supprimé');
        this.toastr.success('La transaction à été supprimée avec succès.');

      }
    )
    }).catch(
      (error)=>{

        console.log(error);

      }
    )
  }
  calculateCashRevenue():any {
    this.transactionService.getCashRevenue(this.today)
      .then((cashRevenue) =>{
        this.cashRevenue = cashRevenue;

      });
  }
  calculateDailExpenseRevenue():any {
    this.expenseService.getDailyExpenseRevenue(this.today)
      .then((dailyExpenseRevenue) =>{
        this.dailyExpenseRevenue = dailyExpenseRevenue;
        this.cashRevenue -= this.dailyExpenseRevenue;

      });
  }

  getTotalDette(): any {
    return this.transactions.reduce((total, transaction) => {
      return (transaction.type === 'retrait' && transaction.status ==='non payer'
      || transaction.type === 'depot' && transaction.status ==='non payer'
      || transaction.type === 'credit' && transaction.status ==='non payer'
      || transaction.type === 'internet' && transaction.status ==='non payer'
      || transaction.type === 'sodeci' && transaction.status ==='non payer'
      || transaction.type === 'cie' && transaction.status ==='non payer') ? total + parseFloat(transaction.amount.toString()) : total;

    }, 0);
  }

  getAllTransaction(){
    this.transactionService.getAllTransactions().then(
      (transactions) => {this.transactions = transactions;
       });
  }
  calculateDailyTotalRevenueWithoutWithdrawalss(): { day: string; revenue: number }[] {
    const today = new Date();

    const dailyRevenueByDay: { day: string; revenue: number }[] = [];

    for (let day = 16; day <= today.getDate(); day++) {
      const currentDate = new Date(today.getFullYear(), today.getMonth(), day);

      const dayTransactions = this.transactions.filter(
        transaction =>
          ((transaction.date)as any).toDate().toDateString() === currentDate.toDateString() &&
          transaction.type !== 'retrait'
      );

      const dayRevenue = dayTransactions.reduce(
        (total, transaction) => total + parseFloat(transaction.amount.toString()),
        0
      );

      const formattedDay =
        day === today.getDate()
          ? `Aujourd'hui`
          : day === today.getDate() - 1
          ? `Hier`
          : day === today.getDate() - 2
          ? `Avant-hier`
          : `${this.getDayName(currentDate)} ${currentDate.getDate()} ${this.getMonthName(currentDate)} ${currentDate.getFullYear()}`;

      dailyRevenueByDay.push({
        day: formattedDay,
        revenue: dayRevenue
      });
    }

    return dailyRevenueByDay;
  }

  getDayName(date: Date): string {
    const days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
    return days[date.getDay()];
  }

  getMonthName(date: Date): string {
    const months = [
      'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
      'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
    ];
    return months[date.getMonth()];
  }
  getMonthNames(date: Date): string {
    const months = [
      'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
      'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
    ];
    return months[date.getMonth()];
  }



}
